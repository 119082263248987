import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { debugDisplay, isCroonBonusMode } from '../../../utils';
import ViewContainer from '../../components/container';
import {
  REELS_AMOUNT,
  REELS_FRAME_ADJUSTMENT,
  SLOTS_BASE_GAME_ADJUST,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  Z_INDEX_REELS_FRAME,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private baseFrameTex = PIXI.Texture.from(ResourceTypes.reelframeBase);

  private shadowTex = PIXI.Texture.from(ResourceTypes.reelBaseShadow);

  private frame: PIXI.Sprite;

  private shadow: PIXI.Sprite[] = [];

  constructor() {
    super();
    this.frame = this.initFrame();

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.shadow[i] = this.initShadow(i);
      this.addChild(this.shadow[i]!);
    }

    this.addChild(this.frame);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    this.zIndex = Z_INDEX_REELS_FRAME;
  }

  private onModeChange(settings: { mode: GameMode }): void {
    this.frame.texture = this.baseFrameTex;
    this.frame.y = SLOTS_CONTAINER_HEIGHT + SLOTS_BASE_GAME_ADJUST;

    if (isCroonBonusMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private initFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(this.baseFrameTex);
    frame.anchor.set(0.5, 1);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT);
    frame.zIndex = 10;
    this.setPosition(frame);

    debugDisplay(' --------------- ', SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT);
    frame.y = SLOTS_CONTAINER_HEIGHT + SLOTS_BASE_GAME_ADJUST;
    debugDisplay('frame.y', frame.y, SLOTS_CONTAINER_HEIGHT, SLOTS_BASE_GAME_ADJUST);
    return frame;
  }

  private initShadow(i: number): PIXI.Sprite {
    const shadow = new PIXI.Sprite(this.shadowTex);
    shadow.blendMode = 2;
    shadow.anchor.set(0.5, 1);
    shadow.zIndex = 10;
    this.setPosition(shadow);

    shadow.x = 190 + 410 * i;
    shadow.y = 480;
    return shadow;
  }

  private setPosition(frame: PIXI.Sprite): void {
    frame.anchor.set(0.5, 1);
    frame.position.set(
      SLOTS_CONTAINER_WIDTH / 2, //
      SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT,
    );

    frame.zIndex = 10;
  }
}

export default ReelsFrame;
