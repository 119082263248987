import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { SlotId } from './config';
import type { ReelSetType } from './gql/query';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState } from './slotMachine/config';
import type { IWinLine, Icon, LineSet } from './slotMachine/d';

declare global {
  interface Window {
    PIXI: unknown;
  }
}

export interface IBalance {
  amount: number;
  currency: string;
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: IBalance;
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    // slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    // userBonusId: string;
    userBonus: {
      betId: string;
      //   id: string;
      //   bonusId: string;
      //   lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    // data: {
    //   isBonus: boolean;
    //   bonuses: UserBonus[];
    //   features: {
    //     isFreeSpins: boolean;
    //   };
    // };
    // createdAt: string;
    // updatedAt: string;
  };
  paylines: IWinLine[];
  balance: {
    placed: IBalance;
    settled: IBalance;
  };
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BonusType = 'FREE_SPIN' | 'SPECIAL_ROUND' | 'PRE_LOADED' | 'GAME_MODE' | '';
export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type BonusRoundData = {
  remainingRounds: number;
  landingDiamonds: { position: number; score: number }[];
};
export type BonusData = {
  legend: BonusRoundData[];
  multipliers: {
    rows: [number, number, number];
    columns: [number, number, number, number, number];
  };
  rewardAmount: number;
};
export type UserBonus = {
  betId: string;
  bonusId: string;
  id: string;
  isActive: boolean;
  rounds: number;
  reelSetId: string;
  bonus: Bonus;
  coinAmount: number;
  totalWinAmount: number;
  status: BonusStatus;
  coinValue: number;
  data: UserBonusData;
};
export type Bonus = {
  coinAmount: number;
  id: string;
  reelSetId: string;
  type: BonusType;
  slotId: string;
  title?: string;
  description?: string;
  lineSetId: string | null;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};

export type UserBonusData = {
  pachiCroonRounds: PachiCroonRounds[]; // CroonRoundData[];
  stage: Stage[];
  count: number;
  creditMultiplier: number;
  preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
};

export enum FeatureState {
  BB,
  HBB,
}

export enum CroonRoundMode {
  SP1 = 'SP1',
  SP3 = 'SP3',
  SP5 = 'SP5',
  NM3 = 'NM3',
  NM5 = 'NM5',
  NM10 = 'NM10',
}

export enum HoleType {
  END = 'END',
  NEXT = 'NEXT',
  COLLECT = 'COLLECT',
  EXTRA = 'EXTRA',
}

export type PachiCroonRounds = {
  coinReward: number;
  round: number;
  position: number;
};

export type Stage = {
  0: string;
  1: string;
  2: string;
};

export enum GameMode {
  REGULAR,
  CROON_BONUS,
}
export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '294c8235-ffec-4cae-9d3c-294169980063',
  [GameMode.CROON_BONUS]: '5b84b15a-e07a-45be-bcee-90b8e71344e4',
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.CROON_BONUS]: 'e9d83bdf-62ab-4d03-8bf6-cdc058e6a10f',
};

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: 'b4b614cb-7c27-4244-9775-6de369b0d7d0',
  [GameMode.CROON_BONUS]: 'b4b614cb-7c27-4244-9775-6de369b0d7d0',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
}

export type BattleBnsTitleProps = {
  text: string;
  currentSet: number;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: (() => void) | undefined;
  onInitCallback?: () => void;
};

export type MessageBattleBonusBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
  vsText: string;
  youText: string;
  rivalText: string;
};

export type MessageCroonBonusBannerProps = BaseMessageBannerProps & {
  title: string;
  description1: string;
  description2: string;
  btnText: string;
  round: number;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  JINGLE_START = 'jingleStart', // TODO
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  // TODO HIDE_WIN_LINES = 'hideWinLines',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  CROON_STAGE_SPIN = 'croonStageSpin',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  CROON_DROP = 'croonDrop',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_BACK_GROUND = 'ResizeBackGround',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  CROON_SET_STAGE = 'CroonSetStage',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  SHOW_WIN_LINES = 'showWinLines',
  // TODO SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint', // TODO
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREE_SPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_CROON_STAGE_MOVE = 'setIsCroonStageMode',
  SET_IS_COUNT_UP = 'setIsCountUp',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_UPDATE_BTN = 'openBuyFeatureUpdateBtn',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SET_STATE = 'setState',
  SLOT_STOP_DISPLAY_HIDE = 'SlotStopDisplayHide',
  SET_ENDING_DISPLAY = 'SetEndingDisplay',
  SET_SLOT_BUSY_DISABLE = 'SetSlotBusyDisable',
  PHOENIX_START = 'PhoenixStart',
  UI_VISIBLE = 'UiVisible',
  CROON_BONUS_END = 'CroonBonusEnd',
  MINI_MAP_VISIBILITY = 'MiniMapVisibility',
  MINI_MAP_SET_CURRENT = 'MiniMapCurrent',
  SET_CROON_STATE = 'SetCroonState',
  SET_BACKGROUND_PIVOT_Y = 'SetBackGroundPivotY',
  CROON_STATE_START_SCROLL = 'CroonStateStartScroll',
  CROON_TOP_VISIBLE = 'CroonTopVisible',
  CROON_START = 'CroonStart',
  MINIMAP_CURRENT_SET = 'MiniMapCurrentSet',
  MINIMAP_EXTRA_STAGE = 'MiniMapExtraStage',
  CROON_STATE_NEXT = 'CroonStateNext',
  CROON_EXTRA_CHALLENGE = 'CroonExtraChallenge',
  HANDLE_SKIP_CLEARED_MESSAGE = 'handleSkipClearedMessage',
  CREATE_CLEARED_MESSAGE = 'CreateClearedMessage',
  CREATE_END_MESSAGE = 'CreateEndMessage',
  CROON_END = 'CroonEnd',
  CROON_IN = 'CroonIn',
  CROON_MOVE = 'CroonMove',
  CROON_EXTRA_OPEN = 'CroonExtraOpen',
  CROON_EXTRA_OPENED = 'CroonExtraOpened',
  CROON_EXTRA_OPENED_FADE = 'CroonExtraOpenedFade',
  SPIN_END = 'SpinEnd',
  BONUS_END = 'BonusEnd',
  BONUS_WIN = 'BonusWin',
  BONUS_TENPAI = 'BonusTenpai',
  TOGGLE_UI_LEFT = 'ToggleUiLeft',
  TOGGLE_UI_RIGHT = 'ToggleUiRight',
  TOGGLE_UI_MENU = 'ToggleUiMenu',
  FIRST_ROTATING_WATCHDOG = 'FirstRotatingWatchDog',
  SECOND_ROTATING_WATCHDOG = 'SecondRotatingWatchDog',
  WINDOW_NOT_ACTIVE = 'WindowNotActive',
  CONFETTI_START = 'ConfettiStart',
  CONFETTI_STOP = 'ConfettiStop',
  CROON_RESULT = 'CroonResult',
  FREE_SPIN = 'FreeSpin',
  SET_IDLE_CLEARED = 'SET_IDLE_CLEARED',
  PLACE_BET_COMPLETED = 'PlaceBetCompleted',
  SET_REPLAY_BET_ID = 'setReplayBetId',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: () => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageCroonBonusBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  // TODO [EventTypes.HIDE_WIN_LINES]: (line: IWinLine[]) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.CROON_STAGE_SPIN]: () => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.CROON_DROP]: () => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_BACK_GROUND]: (scale: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.CROON_SET_STAGE]: (stage: number) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (
    nextResult: ISettledBet | number, // winCoinAmount: number,
    isTurboSpin: boolean,
  ) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  // TODO [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], constructorFlg: boolean, reelId?: number) => void;
  [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: number[],
    scatterNo: number[],
    anticipationStartReelId: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.SET_IS_LEFT_HAND_MODE]: (isLeftHandMode: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREE_SPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_CROON_STAGE_MOVE]: (isCroonStageMove: boolean) => void;
  [EventTypes.SET_IS_COUNT_UP]: (isCountUp: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: (isError: boolean) => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_UPDATE_BTN]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (isBattleBonus: boolean, totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.SLOT_STOP_DISPLAY_HIDE]: (slots: number) => void;
  [EventTypes.SET_ENDING_DISPLAY]: (reelPositions: number[], reelSetId: string) => void;
  [EventTypes.SET_SLOT_BUSY_DISABLE]: () => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.UI_VISIBLE]: () => void;
  [EventTypes.CROON_BONUS_END]: () => void;
  [EventTypes.MINI_MAP_VISIBILITY]: (croonRound: CroonRoundMode, visible: boolean) => void;
  [EventTypes.MINI_MAP_SET_CURRENT]: (currentPos: number) => void;
  [EventTypes.SET_CROON_STATE]: (croonState: number) => void;
  [EventTypes.SET_BACKGROUND_PIVOT_Y]: (pivotY: number) => void;
  [EventTypes.CROON_STATE_START_SCROLL]: () => void;
  [EventTypes.CROON_TOP_VISIBLE]: (visible: boolean) => void;
  [EventTypes.CROON_START]: (stage: number) => void;
  [EventTypes.MINIMAP_CURRENT_SET]: (stage: number) => void;
  [EventTypes.MINIMAP_EXTRA_STAGE]: () => void;
  [EventTypes.CROON_STATE_NEXT]: (stage: number, totalStage: number) => void;
  [EventTypes.CROON_EXTRA_CHALLENGE]: () => void;
  [EventTypes.HANDLE_SKIP_CLEARED_MESSAGE]: () => void;
  [EventTypes.CREATE_CLEARED_MESSAGE]: (stage: number) => void;
  [EventTypes.CREATE_END_MESSAGE]: () => void;
  [EventTypes.CROON_END]: () => void;
  [EventTypes.CROON_IN]: (id: number) => void;
  [EventTypes.CROON_MOVE]: (id: number) => void;
  [EventTypes.CROON_EXTRA_OPEN]: () => void;
  [EventTypes.CROON_EXTRA_OPENED]: () => void;
  [EventTypes.CROON_EXTRA_OPENED_FADE]: () => void;
  [EventTypes.SPIN_END]: () => void;
  [EventTypes.BONUS_END]: () => void;
  [EventTypes.BONUS_WIN]: () => void;
  [EventTypes.BONUS_TENPAI]: () => void;
  [EventTypes.TOGGLE_UI_LEFT]: (isUpper?: boolean) => void;
  [EventTypes.TOGGLE_UI_RIGHT]: (isUpper?: boolean) => void;
  [EventTypes.TOGGLE_UI_MENU]: () => void;
  [EventTypes.FIRST_ROTATING_WATCHDOG]: (stage: number) => void;
  [EventTypes.SECOND_ROTATING_WATCHDOG]: (anim: CroonAnimTypes) => void;
  [EventTypes.WINDOW_NOT_ACTIVE]: () => void;
  [EventTypes.CONFETTI_START]: () => void;
  [EventTypes.CONFETTI_STOP]: () => void;
  [EventTypes.CROON_RESULT]: (id: number) => void;
  [EventTypes.FREE_SPIN]: (isTurboSpin: boolean | undefined) => void;
  [EventTypes.SET_IDLE_CLEARED]: () => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  NONE = null,
}

export enum BgmSoundTypes {
  BASE = 'regular',
  BB_Start_Before = 'bbStartBefore',
  BB1 = 'bb1',
  BB2 = 'bb2',
  BB3 = 'bb3',
}

export enum ReelAnticipation {
  NON = 'NON',
  BONUS = 'BONUS',
}

export enum BattleBonusUpperColor {
  RED = 'RED',
  PURPLE = 'PURPLE',
  RAINBOW = 'RAINBOW',
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export type StageData = {
  win: number;
  inHoleType: HoleType;
};
