import { ISongs } from '../config';
import { StateType } from '../slotMachine/avatarMotion/config';
import { CroonAnimTypes } from '../slotMachine/croonState/config';

// 予兆振り分け
export const baseGamePhoenix = [
  [50, 100], // SP1
  [50, 100], // SP3
  [50, 100], // SP5
  [100, 100], // NM3
  [100, 100], // NM5
  [98, 2], // NM100
];

// Animation selection table
export enum AnimationType {
  NON = 'No anticipation',
  PHOENIX = 'phoenix',
}

export type RntISongs = ISongs | undefined;

///////////////////////////////////////////////////////////////////////////////////////
// [5.Ball]
// export const Hole1DropPattern = [15, 30, 3, 10, 20, 2, 8, 12];
export const Hole1DropPattern = [15, 45, 48, 58, 78, 80, 88, 100];
// export const Hole2DropPattern = [17, 33, 7, 13, 10, 20];
export const Hole2DropPattern = [17, 50, 57, 70, 80, 100];
// export const Hole31DropPattern = [15, 30, 10, 20, 7, 13, 5];
export const Hole3DropPattern = [15, 45, 55, 75, 82, 95, 100];

///////////////////////////////////////////////////////////////////////////////////////
// [7.AvatarMotion]

// 視線移動Delay               10  15  20  25ms
export const EyeMoveDelay = [[25, 50, 75, 100]];

// 7.2.1 起動時・および再読み込み時の初期値[低、中、高]
export const StartUpTension = [[0, 50, 100]];

// 待機モーション時の視線制御抽せん[正面、リール、ランダム]
export const EyeControlPlay = [[10, 90, 100]];
export const EyeControlIdle = [[40, 60, 100]];

// A. ベースゲーム中の待機ループ [待機1～待機5]
export const ABaseGameWaitLoop1 = [[40, 80, 80, 80, 100]];
export const ABaseGameWaitLoop2 = [[0, 50, 100, 100]];
export const ABaseGameWaitLoop3 = [[0, 20, 60, 100]];

// B. ボーナステンパイハズレ時 残念リアクション[リアクション1～リアクション7]
export const BBonusTenpaiHazure = [[0, 50, 100, 100, 100, 100, 100]];

// C. ボーナステンパイハズレ時 残念後の待機1回再生[待機1～待機4]
export const CBonusTenpaiHazureWait1 = [[50, 100, 100, 100]];

// D. ボーナス当選喜びリアクション[リアクション1～リアクション7]
export const DBonusWinReaction = [[0, 0, 0, 40, 70, 100, 100]];

// E. 喜び後 待機[待機1～待機4]
export const EAfterBonusWinWait = [[0, 0, 50, 100]];

// F. 回転前半 見守り[見守り1～見守り7]
export const FFirstHalfRotation1 = [[0, 0, 0, 50, 100, 100, 100]];
export const FFirstHalfRotation2 = [[0, 0, 20, 50, 80, 100, 100]];
export const FFirstHalfRotation3 = [[0, 0, 30, 30, 60, 100, 100]];

// G. 回転後半 見守り[見守り1～見守り7]
export const FSecondHalfRotation1 = [[0, 0, 30, 50, 70, 100, 100]];
export const FSecondHalfRotation2 = [[0, 30, 50, 50, 50, 70, 100]];
export const FSecondHalfRotation3 = [[0, 50, 50, 50, 50, 50, 100]];

// I. ボール入賞
// NEXT or 最高配当に入ったとき
// 序盤 Next[リアクション1～リアクション7]
export const INextOrHighPayBegin = [[0, 0, 0, 50, 100, 100, 100]];
export const INextOrHighPayMiddle = [[0, 0, 0, 40, 80, 100, 100]];
export const INextOrHighPayEnd = [[0, 0, 0, 0, 50, 100, 100]];
export const IFinalStageHighest = [[0, 0, 0, 0, 0, 100, 100]];

// ハズレ穴（最高配当以外の配当穴 or Collect穴）に入った時
export const IPayBegin = [[0, 50, 100, 100, 100, 100, 100]];
export const IPayMiddle = [[30, 70, 100, 100, 100, 100, 100]];
export const IhPayEnd = [[50, 100, 100, 100, 100, 100, 100]];
export const IFinalStage = [[100, 100, 100, 100, 100, 100, 100]];

type KnockLot = Record<StateType, { state: number[][] }>;

type KnockTypeList = Record<CroonAnimTypes, { knockLot: KnockLot }>;

export const KnockList: KnockTypeList = {
  '6_1s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '7_1c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '8_1t3t2t1s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[40, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '9_2t1s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '10_2t1c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '11_2t1t3t1s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[40, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '12_3t2t1s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[10, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '13_3t2t1c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[10, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '14_2s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '15_2c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '16_1t3t2s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[40, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '17_1t3t2c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[40, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '18_3t2s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '19_3t2c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '20_3s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '21_3c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '22_1t3s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '23_1t3c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[0, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '24_2t1t3s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[40, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '25_2t1t3c': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[40, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
  '26_3t2t1t3s': {
    knockLot: {
      Begin: {
        state: [[0, 100]],
      },
      Middle: {
        state: [[0, 100]],
      },
      End: {
        state: [[40, 100]],
      },
      Non: {
        state: [[0, 100]],
      },
    },
  },
};

// I.ボール入賞;
export const IHitBegin = [[0, 0, 0, 50, 100, 100, 100]];
export const IHitMiddle = [[0, 0, 0, 40, 80, 100, 100]];
export const IHitEnd = [[0, 0, 0, 0, 50, 100, 100]];
export const IHitHighPay = [[0, 0, 0, 0, 0, 100, 100]];

export const IMissBegin = [[0, 50, 100, 100, 100, 100, 100]];
export const IMissMiddle = [[30, 70, 100, 100, 100, 100, 100]];
export const IMissEnd = [[50, 100, 100, 100, 100, 100, 100]];
export const IMissHighPay = [[100, 100, 100, 100, 100, 100, 100]];

// L. EXTRA演出中見守り
export const IExtraActLot = [[0, 0, 0, 0, 50, 100, 100]];
