import { CroonRoundMode } from '../../global';
import { StateType } from '../avatarMotion/config';

export enum CroonAnimTypes {
  CROON_6_1S = '6_1s',
  CROON_7_1C = '7_1c',
  CROON_8_1T3T2T1S = '8_1t3t2t1s',
  CROON_9_2T1S = '9_2t1s',
  CROON_10_2T1C = '10_2t1c',
  CROON_11_2T1T3T1S = '11_2t1t3t1s',
  CROON_12_3T2T1S = '12_3t2t1s',
  CROON_13_3T2T1C = '13_3t2t1c',
  CROON_14_2S = '14_2s',
  CROON_15_2C = '15_2c',
  CROON_16_1T3T2S = '16_1t3t2s',
  CROON_17_1T3T2C = '17_1t3t2c',
  CROON_18_3T2S = '18_3t2s',
  CROON_19_3T2C = '19_3t2c',
  CROON_20_3S = '20_3s',
  CROON_21_3C = '21_3c',
  CROON_22_1T3S = '22_1t3s',
  CROON_23_1T3C = '23_1t3c',
  CROON_24_2T1T3S = '24_2t1t3s',
  CROON_25_2T1T3C = '25_2t1t3c',
  CROON_26_3T2T1T3S = '26_3t2t1t3s',
}

export enum HoleType {
  HOLE1 = 'Hole1',
  HOLE2 = 'Hole2',
  HOLE3 = 'Hole3',
}

type CroonAnimationIdleType = Record<number, { animationName: string }>;

export const CroonAnimationIdleData: CroonAnimationIdleType = {
  0: { animationName: 'idle_elephant+' },
  1: { animationName: 'idle_clown+' },
  2: { animationName: 'idle_elephant+' },
  3: { animationName: 'idle_clown+' },
  4: { animationName: 'idle_elephant+' },
  5: { animationName: 'idle_clown+' },
  6: { animationName: 'idle_elephant+' },
  7: { animationName: 'idle_clown+' },
  8: { animationName: 'idle_elephant+' },
  9: { animationName: 'idle_clown+' },
};

export const CroonAnimationActiveData: CroonAnimationIdleType = {
  0: { animationName: 'active_elephant+' },
  1: { animationName: 'active_clown+' },
  2: { animationName: 'active_elephant+' },
  3: { animationName: 'active_clown+' },
  4: { animationName: 'active_elephant+' },
  5: { animationName: 'active_clown+' },
  6: { animationName: 'active_elephant+' },
  7: { animationName: 'active_clown+' },
  8: { animationName: 'active_elephant+' },
  9: { animationName: 'active_clown+' },
};

export const CroonAnimationClearData: CroonAnimationIdleType = {
  0: { animationName: 'active_elephant+_clear' },
  1: { animationName: 'active_clown+_clear' },
  2: { animationName: 'active_elephant+_clear' },
  3: { animationName: 'active_clown+_clear' },
  4: { animationName: 'active_elephant+_clear' },
  5: { animationName: 'active_clown+_clear' },
  6: { animationName: 'active_elephant+_clear' },
  7: { animationName: 'active_clown+_clear' },
  8: { animationName: 'active_elephant+_clear' },
  9: { animationName: 'active_clown+_clear' },
};

export const CroonAnimationClearedData: CroonAnimationIdleType = {
  0: { animationName: 'active_elephant+_cleared' },
  1: { animationName: 'active_clown+_cleared' },
  2: { animationName: 'active_elephant+_cleared' },
  3: { animationName: 'active_clown+_cleared' },
  4: { animationName: 'active_elephant+_cleared' },
  5: { animationName: 'active_clown+_cleared' },
  6: { animationName: 'active_elephant+_cleared' },
  7: { animationName: 'active_clown+_cleared' },
  8: { animationName: 'active_elephant+_cleared' },
  9: { animationName: 'active_clown+_cleared' },
};

type KnockType = Record<StateType, { isKnock: number[][] }>;

type CroonAnimationType = Record<CroonAnimTypes, { nextStage: boolean; Hole: HoleType; knock: KnockType }>;

// H. 盤面叩き
export const CroonAnimationData: CroonAnimationType = {
  '6_1s': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '7_1c': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '8_1t3t2t1s': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[40, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '9_2t1s': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '10_2t1c': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '11_2t1t3t1s': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[40, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '12_3t2t1s': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[10, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '13_3t2t1c': {
    nextStage: true,
    Hole: HoleType.HOLE1,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[10, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '14_2s': {
    nextStage: false,
    Hole: HoleType.HOLE2,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '15_2c': {
    nextStage: false,
    Hole: HoleType.HOLE2,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '16_1t3t2s': {
    nextStage: false,
    Hole: HoleType.HOLE2,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[40, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '17_1t3t2c': {
    nextStage: false,
    Hole: HoleType.HOLE2,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[40, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '18_3t2s': {
    nextStage: false,
    Hole: HoleType.HOLE2,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '19_3t2c': {
    nextStage: false,
    Hole: HoleType.HOLE2,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '20_3s': {
    nextStage: false,
    Hole: HoleType.HOLE3,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '21_3c': {
    nextStage: false,
    Hole: HoleType.HOLE3,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '22_1t3s': {
    nextStage: false,
    Hole: HoleType.HOLE3,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '23_1t3c': {
    nextStage: false,
    Hole: HoleType.HOLE3,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[0, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '24_2t1t3s': {
    nextStage: false,
    Hole: HoleType.HOLE3,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[40, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '25_2t1t3c': {
    nextStage: false,
    Hole: HoleType.HOLE3,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[40, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
  '26_3t2t1t3s': {
    nextStage: false,
    Hole: HoleType.HOLE3,
    knock: {
      Begin: { isKnock: [[0, 100]] },
      Middle: { isKnock: [[0, 100]] },
      End: { isKnock: [[40, 100]] },
      Non: { isKnock: [[0, 100]] },
    },
  },
};

interface CroonAnimType {
  animType: CroonAnimTypes;
  nextStage: boolean;
}

export const CroonAnimData: CroonAnimType[] = [
  {
    animType: CroonAnimTypes.CROON_6_1S,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_7_1C,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_8_1T3T2T1S,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_9_2T1S,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_10_2T1C,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_11_2T1T3T1S,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_12_3T2T1S,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_13_3T2T1C,
    nextStage: true,
  },
  {
    animType: CroonAnimTypes.CROON_14_2S,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_15_2C,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_16_1T3T2S,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_17_1T3T2C,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_18_3T2S,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_19_3T2C,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_20_3S,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_21_3C,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_22_1T3S,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_23_1T3C,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_24_2T1T3S,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_25_2T1T3C,
    nextStage: false,
  },
  {
    animType: CroonAnimTypes.CROON_26_3T2T1T3S,
    nextStage: false,
  },
];

type CroonStageOddsType = Record<CroonRoundMode, { oddsList: string[][] }>;

export const CroonStageOdds: CroonStageOddsType = {
  SP1: {
    oddsList: [['x10000_win.png', 'x1.png', 'x1.png']],
  },
  SP3: {
    oddsList: [
      ['NEXT.png', 'x1.png', 'x1.png'],
      ['NEXT.png', 'x2.png', 'x5.png'],
      ['x10000_win.png', 'x5.png', 'x10.png'],
    ],
  },
  SP5: {
    oddsList: [
      ['NEXT.png', 'x1.png', 'x1.png'],
      ['NEXT.png', 'x2.png', 'x5.png'],
      ['NEXT.png', 'x5.png', 'x10.png'],
      ['NEXT.png', 'x10.png', 'x20.png'],
      ['x10000_win.png', 'x20.png', 'x100.png'],
    ],
  },
  NM10: {
    oddsList: [
      ['NEXT.png', 'x2.png', 'x5.png'],
      ['NEXT.png', 'x10.png', 'x15.png'],
      ['NEXT.png', 'x15.png', 'x20.png'],
      ['NEXT.png', 'x30.png', 'x50.png'],
      ['NEXT.png', 'x50.png', 'x100.png'],
      ['NEXT.png', 'x100.png', 'x200.png'],
      ['NEXT.png', 'x200.png', 'x500.png'],
      ['NEXT.png', 'x500.png', 'x1000.png'],
      ['NEXT.png', 'x1000.png', 'x2000.png'],
      ['x10000_win.png', 'x2000.png', 'x5000.png'],
    ],
  },
  NM3: {
    oddsList: [
      ['NEXT.png', 'x2.png', 'x5.png'],
      ['NEXT.png', 'x5.png', 'x10.png'],
      ['x100_win.png', 'x10.png', 'x20.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['x10000_win.png', 'Collect.png', 'Collect.png'],
    ],
  },
  NM5: {
    oddsList: [
      ['NEXT.png', 'x1.png', 'x2.png'],
      ['NEXT.png', 'x2.png', 'x10.png'],
      ['NEXT.png', 'x10.png', 'x20.png'],
      ['NEXT.png', 'x20.png', 'x50.png'],
      ['x1000_win.png', 'x50.png', 'x100.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['NEXT.png', 'Collect.png', 'Collect.png'],
      ['x10000_win.png', 'Collect.png', 'Collect.png'],
    ],
  },
};

export enum CroonOddsNumber {
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_5 = 5,
  NUMBER_10 = 10,
  NEXT = 0,
}

export enum CroonOddsText {
  MULTIPLIER_1 = 'MULTIPLIER_1',
  MULTIPLIER_2 = 'MULTIPLIER_2',
  MULTIPLIER_5 = 'MULTIPLIER_5',
  MULTIPLIER_10 = 'MULTIPLIER_10',
  NEXT = 'NEXT',
}

type CroonStageOddsTypeBe = Record<CroonOddsNumber, { oddsText: CroonOddsText }>;

export const CroonStageOddsBe: CroonStageOddsTypeBe = {
  1: {
    oddsText: CroonOddsText.MULTIPLIER_1,
  },
  2: {
    oddsText: CroonOddsText.MULTIPLIER_2,
  },
  5: {
    oddsText: CroonOddsText.MULTIPLIER_5,
  },
  10: {
    oddsText: CroonOddsText.MULTIPLIER_10,
  },
  0: {
    oddsText: CroonOddsText.NEXT,
  },
};
