import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useMemo } from 'react';

import { EventTypes } from '../../global.d';
import {
  SetIsCountUp,
  SetIsCroonStageMove, // setIsBattleStartAnim,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql, getAutoSpinsGql, getBetAmountGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';

const EventListener: React.FC = () => {
  const { data: configData } = useQuery<IConfig>(configGql);
  const { isLeftHandMode, isTurboSpin, isSoundOn, isSpinInProgress, isSlotBusy, isSlotStopped } = configData!;

  const { data: betData } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { betAmount } = betData!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_AUTO_SPINS, isAutoSpins);
  }, [isAutoSpins]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_AUTO_SPINS_LEFT, autoSpinsLeft);
  }, [autoSpinsLeft]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_LEFT_HAND_MODE, isLeftHandMode);
  }, [isLeftHandMode]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_TURBO_SPIN, isTurboSpin);
  }, [isTurboSpin]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_SOUND, isSoundOn);
  }, [isSoundOn]);

  useMemo(() => {
    eventManager.emit(EventTypes.UPDATE_BET, betAmount);
  }, [betAmount]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SPIN_IN_PROGRESS, isSpinInProgress);
  }, [isSpinInProgress]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOT_IS_BUSY, isSlotBusy);
  }, [isSlotBusy]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOTS_STOPPED, isSlotStopped);
  }, [isSlotStopped]);

  const isFreeSpinsWin = useReactiveVar(setIsFreeSpinsWin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_FREE_SPINS_WIN, isFreeSpinsWin);
  }, [isFreeSpinsWin]);

  const isOpenedMessageBanner = useReactiveVar(setIsOpenedMessageBanner);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_OPENED_MESSAGE_BANNER, isOpenedMessageBanner);
  }, [isOpenedMessageBanner]);

  const isInTransition = useReactiveVar(setIsInTransition);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_IN_TRANSITION, isInTransition);
  }, [isInTransition]);

  const isCroonStageMove = useReactiveVar(SetIsCroonStageMove);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_CROON_STAGE_MOVE, isCroonStageMove);
  }, [isCroonStageMove]);

  const isCountUp = useReactiveVar(SetIsCountUp);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_COUNT_UP, isCountUp);
  }, [isCountUp]);

  return null;
};

export default EventListener;
