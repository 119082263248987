import { Bone } from '@pixi-spine/runtime-3.8';
import { IAnimationStateListener, Spine } from 'pixi-spine';
import { Container, Loader, Ticker } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import {
  ABaseGameWaitLoop1,
  ABaseGameWaitLoop2,
  ABaseGameWaitLoop3,
  BBonusTenpaiHazure,
  CBonusTenpaiHazureWait1,
  DBonusWinReaction,
  EAfterBonusWinWait,
  EyeControlIdle,
  EyeControlPlay,
  EyeMoveDelay,
  FFirstHalfRotation1,
  FFirstHalfRotation2,
  FFirstHalfRotation3,
  FSecondHalfRotation1,
  FSecondHalfRotation2,
  FSecondHalfRotation3,
  IExtraActLot,
  IHitBegin,
  IHitEnd,
  IHitHighPay,
  IHitMiddle,
  IMissBegin,
  IMissEnd,
  IMissHighPay,
  IMissMiddle,
  KnockList,
} from '../../anticipation/table';
import { getResultFromTable } from '../../anticipation/util';
import { EyeControl, ISongs, mappedAudioSprites, red } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import {
  SetAvatarStatusControl,
  SetAvatarTension,
  SetCroonMode,
  SetEyeControl,
  setCurrentBonus,
  setCurrentStage,
  setGameMode,
} from '../../gql/cache';
import { debugDisplay, getWinCoin, isBaseGameMode, repeatAvoidance } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import {
  EyePosition,
  EyePositionInfo,
  MotionMimamori,
  MotionName,
  MotionReaction,
  MotionTaiki,
  PositionInfo,
  StateType,
  croonFinalStage,
  croonState,
  gazeMoveDelay,
  motionList,
} from '../avatarMotion/config';
import { StatusControlFlg, VoiceStartParameter, talkList } from '../avatarTalk/config';
import {
  AVATAR_EYE_RANDOM_TIMER,
  AVATAR_EYE_UI_CONTROL_TIMER,
  AVATAR_LANDSCAPE_POS_X,
  AVATAR_LANDSCAPE_POS_Y,
  AVATAR_PORTRAIT_POS_X,
  AVATAR_PORTRAIT_POS_Y,
  Z_INDEX_AVATAR,
  eventManager,
} from '../config';
import { CroonAnimTypes } from '../croonState/config';

enum BaseGameMotionType {
  Normal,
  Regret,
  RegretWait1Play,
  BonusWin,
  AfterBonusWin1,
  AfterBonusWin2,
  CroonFirstRotating,
  CroonSecondRotating,
  BallDrop,
  CountUp,
  CountUpBig,
  CountUpMega,
  CountUpGreat,
  CountUpEpic,
  TotalWin,
  ExtraOpen,
  Non,
}

function calEase(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}

class VAvatar extends Spine {
  public jackpot!: Container;

  private motionType: BaseGameMotionType;

  private motionTypeNext: BaseGameMotionType;

  private gaze1: PositionInfo;

  private eyePosX: number;

  private eyePosY: number;

  private MotionWait: Animation | undefined;

  private voiceDelay: Animation | undefined;

  private delay: Animation | undefined;

  private uiGazeTimer: Animation | undefined;

  private gazeMoveDelay: Animation | undefined;

  private mouseTimer: Animation | undefined;

  private mouseTimerYawn: Animation | undefined;

  private gaveMoveCounter: number;

  private bUiGaze: boolean;

  private voiceData: ISongs | undefined;

  private chkKnockFlg: boolean;

  private isEysMotion: boolean;

  private timeId: NodeJS.Timeout[] = [];

  private isVoice: boolean;

  private isIdleMotion: boolean;

  private motion: number;

  private voiceComplete = true;

  constructor() {
    super(Loader.shared.resources['avatar_hinako']!.spineData!);

    this.eyePosX = EyePositionInfo[EyePosition.Center]!.pos[0]!.x;
    this.eyePosY = EyePositionInfo[EyePosition.Center]!.pos[0]!.y;

    this.voiceData = undefined;
    this.chkKnockFlg = false;
    this.gaveMoveCounter = 0;
    this.mouseTimer = undefined;
    this.mouseTimerYawn = undefined;
    this.isEysMotion = true;
    this.isVoice = true;
    this.isIdleMotion = false;
    this.motion = 0;

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    eventManager.on(EventTypes.BONUS_WIN, () => {
      this.cancelTalk();
      this.motionType = BaseGameMotionType.BonusWin;
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.BONUS_TENPAI, () => {
      this.cancelTalk();
      this.motionType = BaseGameMotionType.Regret;
      this.avatarMotionMain(0);
    });
    eventManager.on(EventTypes.TOGGLE_UI_LEFT, (isUpper = false) => {
      this.bUiGaze = false;
      if (this.uiGazeTimer != undefined) {
        this.uiGazeTimer.skip();
        this.uiGazeTimer = undefined;
      }
      if (isUpper) {
        this.toggleUiBtn(EyePosition.UiLeftUpper);
      } else {
        this.toggleUiBtn(EyePosition.UiLeft);
      }
    });
    eventManager.on(EventTypes.TOGGLE_UI_RIGHT, (isUpper = false) => {
      this.bUiGaze = false;
      if (this.uiGazeTimer != undefined) {
        this.uiGazeTimer.skip();
        this.uiGazeTimer = undefined;
      }
      if (isUpper) {
        this.toggleUiBtn(EyePosition.UiRightUpper);
      } else {
        this.toggleUiBtn(EyePosition.UiRight);
      }
    });

    eventManager.on(EventTypes.TOGGLE_UI_MENU, () => {
      debugDisplay('TOGGLE_UI_MENU');
      this.bUiGaze = false;
      if (this.uiGazeTimer != undefined) {
        this.uiGazeTimer.skip();
        this.uiGazeTimer = undefined;
      }
      this.toggleUiBtn(EyePosition.Reel);
    });

    eventManager.on(EventTypes.FIRST_ROTATING_WATCHDOG, (stage: number) => {
      if (stage === 0) {
        this.motionType = BaseGameMotionType.CroonFirstRotating;
        this.cancelTalk();
        this.avatarMotionMain(0);
      } else {
        if (this.motionType === BaseGameMotionType.BallDrop) {
          this.motionTypeNext = BaseGameMotionType.CroonFirstRotating;
        } else {
          this.motionType = BaseGameMotionType.CroonFirstRotating;
        }
      }
    });

    eventManager.on(EventTypes.CROON_EXTRA_OPEN, () => {
      this.motionType = BaseGameMotionType.ExtraOpen;
      this.cancelTalk();
      this.avatarMotionMain(0);
    });

    eventManager.on(EventTypes.CREATE_WIN_MESSAGE_BANNER, () => {
      if (this.motionType === BaseGameMotionType.BallDrop) {
        this.motionTypeNext = BaseGameMotionType.TotalWin;
      } else {
        this.motionType = BaseGameMotionType.TotalWin;
        this.avatarMotionMain(0);
      }
    });

    eventManager.on(EventTypes.SECOND_ROTATING_WATCHDOG, (croonAnimType: CroonAnimTypes) => {
      this.bUiGaze = false;
      this.chkKnockFlg = true;
      this.motionType = BaseGameMotionType.CroonSecondRotating;
      this.avatarMotionMain(0, croonAnimType);
    });

    eventManager.on(EventTypes.CROON_IN, () => {
      this.cancelTalk();
      this.motionType = BaseGameMotionType.BallDrop;
      this.avatarMotionMain(0);
    });

    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.startWinAnimation.bind(this));

    eventManager.addListener(EventTypes.SET_BIG_WIN_VISIBILITY, this.setBigWinVisibility.bind(this));

    eventManager.addListener(EventTypes.SET_MEGA_WIN_VISIBILITY, this.setMegaWinVisibility.bind(this));

    eventManager.addListener(EventTypes.SET_GREAT_WIN_VISIBILITY, this.setGreatWinVisibility.bind(this));

    eventManager.addListener(EventTypes.SET_EPIC_WIN_VISIBILITY, this.setEpicWinVisibility.bind(this));

    this.motionType = BaseGameMotionType.Normal;
    this.motionTypeNext = BaseGameMotionType.Non;
    this.bUiGaze = false;

    this.avatarMotionMain(0, CroonAnimTypes.CROON_6_1S, false);

    this.state.setAnimation(0, 'idle_01_a', false);
    this.state.setAnimation(1, 'mouth_001_default_1', true);
    this.position.x = AVATAR_LANDSCAPE_POS_X;
    this.position.y = AVATAR_LANDSCAPE_POS_Y;

    // 口パクるーぷ
    this.stateData.defaultMix = 0;
    this.zIndex = Z_INDEX_AVATAR;

    this.gaze1 = this.skeleton.findBone('gaze') as Bone;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  private avatarSetAnimationStart(motion: string): Animation {
    const animation = new Animation({});
    animation.duration = this.skeleton.data.findAnimation(motion)!.duration * 1000;

    debugDisplay('== animation.duration', animation.duration, 'motion', motion);

    const listener: IAnimationStateListener = {
      complete: () => {
        Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };
    animation.addOnStart(() => {
      this.state.removeListener(listener);
      this.state.setAnimation(0, motion, false);
      this.state.addListener(listener);
    });
    animation.addOnSkip(() => {
      this.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.state.removeListener(listener);
      // debugDisplay('== animation.addOnComplete');
    });

    animation.start();

    return animation;
  }

  private avatarSetAnimation(motion: number): void {
    this.motion = motion;
    this.timeId.forEach((timer) => {
      clearTimeout(timer);
    });

    let timer = 0;
    for (let i = 0; i < motionList[motion]!.motion.length; i++) {
      this.timeId[this.timeId.length] = setTimeout(() => {
        const date = new Date();
        debugDisplay('=========== date', date.toLocaleString());
        this.avatarSetAnimationStart(motionList[motion]!.motion[i]!);
      }, timer);
      timer += this.skeleton.data.findAnimation(motionList[motion]!.motion[i]!)!.duration * 1000;
    }

    this.stateData.defaultMix = 0;
    this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);
    this.timeId[this.timeId.length] = setTimeout(() => {
      this.avatarMotionMain(0);
    }, timer);
  }

  private startWinAnimation(nextResult: ISettledBet | number): void {
    let winCoinAmount = 0;
    if (this.isNumber(nextResult)) {
      winCoinAmount = getWinCoin();
    } else {
      winCoinAmount = nextResult.bet.result.winCoinAmount;
    }

    if (
      winCoinAmount > 0 &&
      this.motionType != BaseGameMotionType.CountUpBig &&
      this.motionType != BaseGameMotionType.CountUpMega &&
      this.motionType != BaseGameMotionType.CountUpGreat &&
      this.motionType != BaseGameMotionType.CountUpEpic
    ) {
      // this.cancelTalk();

      if (this.motionType === BaseGameMotionType.BallDrop) {
        if (this.motionTypeNext === BaseGameMotionType.Non) {
          this.motionTypeNext = BaseGameMotionType.CountUp;
        }
      } else {
        this.motionType = BaseGameMotionType.CountUp;
      }
      // this.avatarMotionMain(0);
    }
  }

  private setBigWinVisibility(visible: boolean): void {
    if (visible) {
      if (this.motionType === BaseGameMotionType.BallDrop) {
        this.motionTypeNext = BaseGameMotionType.CountUpBig;
      } else {
        this.motionType = BaseGameMotionType.CountUpBig;
      }
    }
  }

  private setMegaWinVisibility(visible: boolean): void {
    if (visible) {
      if (this.motionType === BaseGameMotionType.BallDrop) {
        this.motionTypeNext = BaseGameMotionType.CountUpMega;
      } else {
        this.motionType = BaseGameMotionType.CountUpMega;
      }
    }
  }

  private setGreatWinVisibility(visible: boolean): void {
    if (visible) {
      if (this.motionType === BaseGameMotionType.BallDrop) {
        this.motionTypeNext = BaseGameMotionType.CountUpGreat;
      } else {
        this.motionType = BaseGameMotionType.CountUpGreat;
      }
    }
  }

  private setEpicWinVisibility(visible: boolean): void {
    if (visible) {
      if (this.motionType === BaseGameMotionType.BallDrop) {
        this.motionTypeNext = BaseGameMotionType.CountUpEpic;
      } else {
        this.motionType = BaseGameMotionType.CountUpEpic;
      }
    }
  }

  private cancelTalk(): void {
    if (this.mouseTimer != undefined) {
      AudioApi.stop({ type: this.voiceData! });
      this.mouseTimer.skip();
      this.mouseTimer = undefined;
      this.stateData.defaultMix = 0;
      const mouthMotion1 = this.state.setAnimation(1, talkList[this.motion]!.mouthDefault, true);
      mouthMotion1.timeScale = 0.6;
    }
  }

  // TODO　ベースゲームダミー　モーション
  private avatarMotionMain(delay: number, croonAnimType = CroonAnimTypes.CROON_6_1S, isVoice = true): void {
    debugDisplay('=============avatarMotionMain==========');

    this.isVoice = true;

    if (this.MotionWait != undefined) {
      this.MotionWait.skip();
      this.MotionWait = undefined;

      if (delay === 0 && this.voiceDelay != undefined) {
        debugDisplay('Voiceキャンセル');
        this.mouseTimer?.skip();
        this.voiceDelay.skip();
        this.voiceDelay = undefined;
      }
    }

    debugDisplay(red + 'タイマー前', 'delay', delay);
    this.MotionWait = Tween.createDelayAnimation(delay);
    this.MotionWait.addOnComplete(() => {
      debugDisplay(red + 'タイマー発動');
      if (this.voiceDelay != undefined) {
        this.mouseTimer?.skip();
        this.voiceDelay.skip();
        this.voiceDelay = undefined;
      }

      if (!isBaseGameMode(setGameMode())) {
        this.avatarMotionCroonGameMain(croonAnimType);
        return;
      }

      const motion = this.baseGameMotionSelection();

      if (this.motionType === BaseGameMotionType.Non) {
        this.avatarMotionMain(0);
        return;
      }

      if (this.isEysMotion) {
        this.lotEyeControl(motion);
      } else {
        if (this.delay != undefined) {
          this.delay?.skip();
          this.delay = undefined;
        }
      }

      const voiceArray = talkList[motion]!.talkInfo[SetAvatarStatusControl()]!;

      if (this.isVoice && this.motionType != BaseGameMotionType.AfterBonusWin2) {
        debugDisplay('voiceArray', voiceArray, 'motion', motion, 'SetAvatarStatusControl()', SetAvatarStatusControl());

        let voice = repeatAvoidance(voiceArray.talkList);

        if (voice === undefined) {
          voice = 101000;
        }

        if (!isVoice) {
          voice = 101000;
        }

        debugDisplay('voice', voice);

        const voiceDelay = Math.floor(Math.random() * VoiceStartParameter[voice]!.delayMax);

        debugDisplay(
          '==== モーションID1',
          motion,
          ',状況',
          SetAvatarStatusControl(),
          ',セリフDelay',
          voiceDelay,
          '秒',
          ',セリフ',
          VoiceStartParameter[voice]!.voiceData,
        );

        this.voiceDelay = Tween.createDelayAnimation(voiceDelay * 1000);
        this.voiceDelay.addOnComplete(() => {
          this.voiceData = VoiceStartParameter[voice]!.voiceData;
          this.lipSync(motion);
        });
        this.voiceDelay.addOnSkip(() => {
          debugDisplay('==== 口パク addOnSkip ', this.voiceData);
          this.mouseTimer?.onSkip();
        });
        this.voiceDelay.start();
      } else {
        debugDisplay('==== モーションID2', motion, ',状況', SetAvatarStatusControl());
      }

      SetAvatarStatusControl(0);

      this.avatarSetAnimation(motion);
    });
    this.MotionWait.start();
  }

  // 7.6.1 ベースゲーム中のモーション選択フロー
  // TODO モーション再生終了イベントを拾ったらここへ来るように
  private baseGameMotionSelection(): number {
    const date = new Date();
    debugDisplay('baseGameMotionSelection', this.motionType, 'date', date.toLocaleString());
    this.isEysMotion = true;

    let motion: number;
    motion = 0;
    if (this.motionType === BaseGameMotionType.RegretWait1Play) {
      // C.残念後待機1回再生
      this.setDefaultMix(true);
      motion = this.lotBaseGameRegretWait1Play();
      this.motionType = BaseGameMotionType.Normal;
      this.isVoice = false;
      this.isIdleMotion = true;
    } else if (
      this.motionType === BaseGameMotionType.AfterBonusWin1 ||
      this.motionType === BaseGameMotionType.AfterBonusWin2
    ) {
      // E.喜び後待機
      this.setDefaultMix(false);
      motion = this.lotBaseGameAfterBonusWin();
      this.motionType = BaseGameMotionType.AfterBonusWin1;
      this.isVoice = false;
      this.isIdleMotion = false;
    }

    // ボーナスシンボルテンパイハズレ？
    else if (this.motionType === BaseGameMotionType.Regret) {
      // B. 残念リアクション
      this.setDefaultMix(false);
      motion = this.lotBaseGameRegret();
      this.motionType = BaseGameMotionType.RegretWait1Play;
      this.isIdleMotion = false;
    }

    // ボーナス当選？
    else if (this.motionType === BaseGameMotionType.BonusWin) {
      // D. 当選喜びリアクション
      this.setDefaultMix(false);
      motion = this.lotBaseGameBonusWin();
      this.motionType = BaseGameMotionType.AfterBonusWin1;
      this.isIdleMotion = false;
    }

    // それ以外？
    else {
      // A. 待機ループ
      this.setDefaultMix(true);
      motion = this.lotBaseGameWaitLoop();
      this.motionType = BaseGameMotionType.Normal;
      this.isIdleMotion = true;
    }

    return motion;
  }

  // A. ベースゲーム中の待機ループ
  private lotBaseGameWaitLoop(): number {
    let lotTable = ABaseGameWaitLoop1;
    switch (SetAvatarTension()) {
      case 1:
        lotTable = ABaseGameWaitLoop1;
        break;
      case 2:
        lotTable = ABaseGameWaitLoop2;
        break;
      case 3:
        lotTable = ABaseGameWaitLoop3;
        break;
      default:
        console.error('lotBaseGameWaitLoop default');
        break;
    }

    // A.待機ループ抽選
    const rand = Math.floor(Math.random() * 100);

    const lot = getResultFromTable(lotTable[0]!, rand);
    debugDisplay(red + 'A.待機ループ', 'アバターテンション', SetAvatarTension(), '待機', MotionTaiki[lot]);
    return MotionTaiki[lot]!;
  }

  // B. ボーナステンパイハズレ時 残念リアクション
  private lotBaseGameRegret(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(BBonusTenpaiHazure[0]!, rand);
    debugDisplay(
      red + 'B. 残念リアクション ',
      'アバターテンション',
      SetAvatarTension(),
      'リアクション',
      MotionReaction[lot],
    );
    return MotionReaction[lot]!;
  }

  // C. ボーナステンパイハズレ時 残念後の待機1回再生
  private lotBaseGameRegretWait1Play(): number {
    this.isVoice = false;
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(CBonusTenpaiHazureWait1[0]!, rand);
    debugDisplay(
      red + 'C. ボーナステンパイハズレ時 残念後の待機1回再生',
      'アバターテンション',
      SetAvatarTension(),
      '待機',
      MotionTaiki[lot],
    );
    return MotionTaiki[lot]!;
  }

  // D. ボーナス当選喜びリアクション
  private lotBaseGameBonusWin(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(DBonusWinReaction[0]!, rand);
    debugDisplay(
      red + 'D. ボーナス当選喜びリアクション',
      'アバターテンション',
      SetAvatarTension(),
      'リアクション',
      MotionReaction[lot],
    );
    return MotionReaction[lot]!;
  }

  // E. 喜び後 待機
  private lotBaseGameAfterBonusWin(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(EAfterBonusWinWait[0]!, rand);
    debugDisplay(red + 'E. 喜び後 待機', MotionTaiki[lot]);
    return MotionTaiki[lot]!;
  }

  // 待機モーション時の視線制御抽せん
  private lotEyeControl(_motion: number): void {
    if (this.delay != undefined) {
      debugDisplay('ランダムタイマー解除');
      this.delay.skip();
      this.delay = undefined;
    }

    let eyeLotTable;
    if (SetEyeControl() === EyeControl.IDLE) {
      debugDisplay('待機モーション時の視線制御抽せん : IDLE');
      eyeLotTable = EyeControlIdle;
    } else {
      debugDisplay('待機モーション時の視線制御抽せん : PLAY');
      eyeLotTable = EyeControlPlay;
    }
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(eyeLotTable[0]!, rand);

    if (lot === 0) {
      this.eyePosX = EyePositionInfo[EyePosition.Center]!.pos[0]!.x;
      this.eyePosY = EyePositionInfo[EyePosition.Center]!.pos[0]!.y;
      debugDisplay('視線制御: 正面 x:', this.eyePosX, ',y:', this.eyePosY);
      this.eyeControl(this.eyePosX, this.eyePosY);
    } else if (lot === 1) {
      this.eyePosX = EyePositionInfo[EyePosition.Reel]!.pos[0]!.x;
      this.eyePosY = EyePositionInfo[EyePosition.Reel]!.pos[0]!.y;
      debugDisplay('視線制御: リール部 x:', this.eyePosX, ',y:', this.eyePosY);
      this.eyeControl(this.eyePosX, this.eyePosY);
    } else {
      this.EyeRandomPosition();
    }
  }

  private EyeRandomPosition(): void {
    this.delay = Tween.createDelayAnimation(AVATAR_EYE_RANDOM_TIMER);
    this.delay.addOnStart(() => {
      const rand = Math.floor(Math.random() * EyePositionInfo[EyePosition.Random]!.pos.length);
      this.eyePosX = EyePositionInfo[EyePosition.Random]!.pos[rand]!.x;
      this.eyePosY = EyePositionInfo[EyePosition.Random]!.pos[rand]!.y;
      debugDisplay('視線制御: ランダム', rand, 'x:', this.eyePosX, ',y:', this.eyePosY);
      this.eyeControl(this.eyePosX, this.eyePosY);
    });
    this.delay.addOnComplete(() => {
      // debugDisplay('視線制御: ランダム');
      this.EyeRandomPosition();
    });
    this.delay.addOnSkip(() => {
      debugDisplay('視線制御: ランダム skip');
    });
    this.delay.start();
  }

  private toggleUiBtn(eye: EyePosition): void {
    debugDisplay('toggleUiBtn', EyePositionInfo[eye]!.pos[0]!.x, EyePositionInfo[eye]!.pos[0]!.y);

    this.eyeControl(EyePositionInfo[eye]!.pos[0]!.x, EyePositionInfo[eye]!.pos[0]!.y);

    this.bUiGaze = true;
    // const gaze = this.skeleton.findBone('gaze') as Bone;

    if (this.uiGazeTimer != undefined) {
      debugDisplay('視線制御: UI制御　タイマー解除 eye', eye);
      this.uiGazeTimer.skip();
      this.uiGazeTimer = undefined;
    }

    this.uiGazeTimer = Tween.createDelayAnimation(AVATAR_EYE_UI_CONTROL_TIMER);
    this.uiGazeTimer.addOnComplete(() => {
      debugDisplay('視線制御: UI制御 制御終了　モーションに対応した視線に戻り　x:', this.eyePosX, ',y:', this.eyePosY);
      this.bUiGaze = false;
      this.uiGazeTimer = undefined;
      this.eyeControl(this.eyePosX, this.eyePosY);
    });
    this.uiGazeTimer.addOnSkip(() => {
      debugDisplay('視線制御: UI制御 addOnSkip');
    });
    this.uiGazeTimer.start();
  }

  private eyeControlReel(): void {
    debugDisplay('視線制御　見守り固定: リール部');
    this.eyePosX = EyePositionInfo[EyePosition.Reel]!.pos[0]!.x;
    this.eyePosY = EyePositionInfo[EyePosition.Reel]!.pos[0]!.y;
    this.eyeControl(this.eyePosX, this.eyePosY);
  }

  private eyeControl(eyePosX: number, eyePosY: number): void {
    if (this.uiGazeTimer != undefined) {
      debugDisplay('UI 制御中のため視線制御キャンセル');
      return;
    }

    if (this.gazeMoveDelay != undefined) {
      this.gazeMoveDelay.skip();
      this.gazeMoveDelay = undefined;
      this.gaveMoveCounter = 10;
    }

    if (!this.bUiGaze) {
      const x = eyePosX - this.gaze1.x;
      const y = eyePosY - this.gaze1.y;
      const rand = Math.floor(Math.random() * 100);
      const lot = getResultFromTable(EyeMoveDelay[0]!, rand);
      const delay = gazeMoveDelay[lot]!;
      this.gaveMoveCounter = 0;
      this.gazeMove(this.gaze1.x, this.gaze1.y, x, y, delay);
    }
  }

  private gazeMove(crX: number, crY: number, x: number, y: number, gazeDelay: number): void {
    this.gazeMoveDelay = Tween.createDelayAnimation(gazeDelay);
    this.gazeMoveDelay.addOnComplete(() => {
      this.gaze1.x = crX + x * calEase(this.gaveMoveCounter * 0.1);
      this.gaze1.y = crY + y * calEase(this.gaveMoveCounter * 0.1);

      this.gaveMoveCounter += 1;

      if (this.gaveMoveCounter <= 10) {
        this.gazeMove(crX, crY, x, y, gazeDelay);
      }
    });
    this.gazeMoveDelay.addOnSkip(() => {});
    this.gazeMoveDelay.start();
  }

  private avatarMotionCroonGameMain(croonAnimType: CroonAnimTypes): void {
    debugDisplay(red + 'クルーンゲーム中のアバター処理メイン');

    let motion = 101;
    let isVoice = false;

    this.isEysMotion = false;

    if (
      this.motionType === BaseGameMotionType.AfterBonusWin1 ||
      this.motionType === BaseGameMotionType.AfterBonusWin2
    ) {
      // D.喜び後待機
      motion = this.lotBaseGameAfterBonusWin();
      isVoice = false;
    } else if (this.motionType === BaseGameMotionType.CroonFirstRotating) {
      // F. 回転前半 見守り ボール回転開始～最内周回開始まで
      this.setDefaultMix(false);
      motion = this.lotCroonFirstWatchOver();
      isVoice = true;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.CroonSecondRotating) {
      // H. 盤面叩き
      this.setDefaultMix(false);
      if (this.chkKnockFlg && this.chkKnock(croonAnimType)) {
        motion = MotionName.REACTION7;
        isVoice = true;
        this.isIdleMotion = false;
      } else {
        // G. 回転後半 見守り 最内周回～穴に落ちるまで
        motion = this.lotCroonSecondWatchOver();
        isVoice = true;
        this.isIdleMotion = false;
      }
    } else if (this.motionType === BaseGameMotionType.BallDrop) {
      // I. ボール入賞
      this.setDefaultMix(false);
      motion = this.lotWinningPrizeBall();
      isVoice = true;
      this.isIdleMotion = false;
      this.motionType = BaseGameMotionType.BallDrop;
    } else if (
      this.motionType === BaseGameMotionType.CountUp ||
      this.motionType === BaseGameMotionType.CountUpBig ||
      this.motionType === BaseGameMotionType.CountUpMega ||
      this.motionType === BaseGameMotionType.CountUpGreat ||
      this.motionType === BaseGameMotionType.CountUpEpic
    ) {
      // J. Winカウントアップ見守り
      this.setDefaultMix(false);
      motion = this.lotWinCountUp();
      SetAvatarStatusControl(StatusControlFlg.GET);
      isVoice = true;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.TotalWin) {
      // K. Total Win看板
      this.setDefaultMix(false);
      motion = this.lotTotalWin();
      SetAvatarStatusControl(StatusControlFlg.GET);
      isVoice = true;
      this.isIdleMotion = false;
    } else if (this.motionType === BaseGameMotionType.ExtraOpen) {
      // L. EXTRA演出中見守り
      this.setDefaultMix(false);
      motion = this.lotExtraOpen();
      isVoice = false;
      this.isIdleMotion = false;
    }

    debugDisplay('======= motion ======', motion);
    if (this.isEysMotion) {
      this.lotEyeControl(motion);
    } else {
      if (this.delay != undefined) {
        this.delay?.skip();
        this.delay = undefined;
      }
    }

    if (isVoice) {
      debugDisplay('motion', motion, 'SetAvatarStatusControl()', SetAvatarStatusControl());
      const voiceArray = talkList[motion]!.talkInfo[SetAvatarStatusControl()]!;
      let voice = repeatAvoidance(voiceArray.talkList);

      if (voice === undefined) {
        voice = 101000;
      }
      debugDisplay('voice1', voice, 'voiceArray', voiceArray);

      const voiceDelay = Math.floor(Math.random() * VoiceStartParameter[voice]!.delayMax);

      debugDisplay(
        '==== モーションID3',
        motion,
        ',状況',
        SetAvatarStatusControl(),
        ',セリフDelay',
        voiceDelay,
        '秒',
        ',セリフ',
        VoiceStartParameter[voice]!.voiceData,
        mappedAudioSprites[VoiceStartParameter[voice]!.voiceData].duration,
      );

      if (this.motionType === BaseGameMotionType.BallDrop) {
        this.voiceComplete = false;
        const delay = Tween.createDelayAnimation(mappedAudioSprites[VoiceStartParameter[voice]!.voiceData].duration);
        delay.addOnComplete(() => {
          this.motionType = this.motionTypeNext;

          if (this.motionTypeNext != BaseGameMotionType.CountUp) {
            this.motionTypeNext = BaseGameMotionType.Non;
          }

          this.avatarMotionMain(0);
        });
        delay.start();
      }

      SetAvatarStatusControl(0);
      this.voiceDelay = Tween.createDelayAnimation(voiceDelay * 1000);
      this.voiceDelay.addOnComplete(() => {
        this.voiceData = VoiceStartParameter[voice]!.voiceData;
        debugDisplay('==== 口パクスタート 2', this.voiceData);

        this.lipSync(motion);
      });
      this.voiceDelay.addOnSkip(() => {
        debugDisplay('==== 口パク addOnSkip ', this.voiceData);
        this.mouseTimer?.onSkip();
      });
      this.voiceDelay.start();
    } else {
      this.stateData.defaultMix = 0;
      this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);
    }

    this.avatarSetAnimation(motion);

    if (this.motionTypeNext != BaseGameMotionType.Non) {
      if (this.voiceComplete) {
        this.motionType = this.motionTypeNext;
        this.motionTypeNext = BaseGameMotionType.Non;
      }
    }
  }

  //F. 回転前半 見守り ボール回転開始～最内周回開始まで　　// TODO 視線はリール
  private lotCroonFirstWatchOver(): number {
    /// 序盤　中盤　終盤取得
    const stage = croonState[SetCroonMode()]!.state[setCurrentStage()];
    debugDisplay(red + 'ステージ状態', stage, 'モード', SetCroonMode(), 'ステージ', setCurrentStage());

    let lotTable = FFirstHalfRotation1;
    switch (stage) {
      case StateType.BEGIN:
        lotTable = FFirstHalfRotation1;
        break;
      case StateType.MIDDLE:
        lotTable = FFirstHalfRotation2;
        break;
      case StateType.END:
        lotTable = FFirstHalfRotation3;
        break;
      default:
        console.error('FFirstHalfRotation1 default');
        break;
    }
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(lotTable[0]!, rand);
    debugDisplay(red + 'F. 回転前半 見守り', 'ステージ状態', stage, '見守り', MotionMimamori[lot]);

    this.eyeControlReel();
    return MotionMimamori[lot]!;
  }

  // H. 盤面叩き
  private chkKnock(croonAnimType: CroonAnimTypes): boolean {
    let rtn = false;
    this.chkKnockFlg = false;
    const knockLot = KnockList[croonAnimType]!.knockLot[croonState[SetCroonMode()]!.state[setCurrentStage()]!]!.state;
    const knockRand = Math.floor(Math.random() * 100);
    const knockLotResult = getResultFromTable(knockLot[0]!, knockRand);

    if (knockLotResult === 0) {
      rtn = true;
    }

    debugDisplay(
      red + '叩き抽選',
      KnockList[croonAnimType]!.knockLot[croonState[SetCroonMode()]!.state[setCurrentStage()]!]!,
      'State',
      knockLot,
      'id',
      croonAnimType,
      'knockLotResult',
      knockLotResult,
      rtn ? '叩きあり' : '叩きなし',
    );
    return rtn;
  }

  //G. 回転後半 見守り 最内周回～穴に落ちるまで   // TODO  視線はリール
  private lotCroonSecondWatchOver(): number {
    const stage = croonState[SetCroonMode()]!.state[setCurrentStage()];
    debugDisplay(red + 'ステージ状態', stage, 'モード', SetCroonMode(), 'ステージ', setCurrentStage());

    let lotTable = FSecondHalfRotation1;
    switch (stage) {
      case StateType.BEGIN:
        lotTable = FSecondHalfRotation1;
        break;
      case StateType.MIDDLE:
        lotTable = FSecondHalfRotation2;
        break;
      case StateType.END:
        lotTable = FSecondHalfRotation3;
        break;
      default:
        console.error('FSecondHalfRotation1 default');
        break;
    }
    const rand = Math.floor(Math.random() * 100);

    const lot = getResultFromTable(lotTable[0]!, rand);
    debugDisplay(red + 'G. 回転後半 見守り', 'ステージ状態', stage, '見守り', MotionMimamori[lot]);

    this.eyeControlReel();
    return MotionMimamori[lot]!;
  }

  // I. ボール入賞
  private lotWinningPrizeBall(): number {
    debugDisplay(
      'ボール入賞 序盤or中盤or終盤判定　',
      croonState[SetCroonMode()]!.state[setCurrentStage()],
      setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position === 0 ? '当たり' : 'ハズレ',

      SetCroonMode(),
      setCurrentStage(),
      'ファイナルステージ判定',
      croonFinalStage[SetCroonMode()]!.finalStage === setCurrentStage() ? 'ファイナルステージ' : 'ファイナル以外',
    );
    let ballDropLot: number[][];
    if (setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position === 0) {
      if (croonFinalStage[SetCroonMode()]!.finalStage === setCurrentStage()) {
        ballDropLot = IHitHighPay;
      } else {
        switch (croonState[SetCroonMode()]!.state[setCurrentStage()]) {
          case StateType.BEGIN:
            ballDropLot = IHitBegin;
            break;
          case StateType.MIDDLE:
            ballDropLot = IHitMiddle;
            break;
          case StateType.END:
            ballDropLot = IHitEnd;
            break;
        }
      }
    } else {
      if (croonFinalStage[SetCroonMode()]!.finalStage === setCurrentStage()) {
        ballDropLot = IMissHighPay;
      } else {
        switch (croonState[SetCroonMode()]!.state[setCurrentStage()]) {
          case StateType.BEGIN:
            ballDropLot = IMissBegin;
            break;
          case StateType.MIDDLE:
            ballDropLot = IMissMiddle;
            break;
          case StateType.END:
            ballDropLot = IMissEnd;
            break;
        }
      }
    }

    const rand = Math.floor(Math.random() * 100);

    debugDisplay('ballDropLot', ballDropLot![0]);

    const lot = getResultFromTable(ballDropLot![0]!, rand);
    debugDisplay(red + 'I. ボール入賞', 'リアクション', MotionReaction[lot]);
    return MotionReaction[lot]!;
  }

  // J. Winカウントアップ見守り
  private lotWinCountUp(): number {
    let lotTable = MotionMimamori[0]!;

    const stage = croonState[SetCroonMode()]!.state[setCurrentStage()];

    // 終盤ステージで最高配当以外
    if (stage === StateType.END && setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position != 0) {
      lotTable = MotionMimamori[0]!;
    }
    // それ以外・Big Win未満
    else if (this.motionType === BaseGameMotionType.CountUp) {
      lotTable = MotionMimamori[3]!;
    }
    // それ以外・Big Win
    else if (this.motionType === BaseGameMotionType.CountUpBig) {
      lotTable = MotionMimamori[4]!;
    }
    // それ以外・Mega Win
    else if (this.motionType === BaseGameMotionType.CountUpMega) {
      lotTable = MotionMimamori[4]!;
    }
    // それ以外・Great Win
    else if (this.motionType === BaseGameMotionType.CountUpGreat) {
      lotTable = MotionMimamori[5]!;
    }
    // それ以外・Epic Win
    else if (this.motionType === BaseGameMotionType.CountUpEpic) {
      lotTable = MotionMimamori[6]!;
    }

    debugDisplay(red + 'J. Winカウントアップ見守り', '見守り', lotTable);
    this.eyeControlReel();
    return lotTable;
  }

  // K. Total Win看板表示中のモーション
  private lotTotalWin(): number {
    let lotTable = MotionMimamori[0]!;

    const win = setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.coinReward;

    const stage = croonState[SetCroonMode()]!.state[setCurrentStage()]!;

    // 最高配当を獲得した
    if (win >= 10000) {
      lotTable = MotionMimamori[6]!;
    }
    // 終盤ステージで最高配当以外
    else if (stage === StateType.END && setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position != 0) {
      lotTable = MotionMimamori[0]!;
    }
    // Betの10倍以上獲得
    else if (win >= 10 || win === 0) {
      lotTable = MotionMimamori[4]!;
    }
    // ステージ1で終了
    else if (setCurrentBonus().data.pachiCroonRounds[0]!.position != 0) {
      lotTable = MotionMimamori[2]!;
    }
    // その他
    else {
      lotTable = MotionMimamori[3]!;
    }

    debugDisplay(red + 'K. Total Win看板表示中のモーション', lotTable);
    this.eyeControlReel();
    return lotTable;
  }

  // L. EXTRA演出中見守り  // TODO 視線はリール
  private lotExtraOpen(): number {
    const rand = Math.floor(Math.random() * 100);
    const lot = getResultFromTable(IExtraActLot[0]!, rand);
    debugDisplay(red + 'L. EXTRA演出中見守り', MotionMimamori[lot]);
    return MotionMimamori[lot]!;
  }

  private lipSync(motion: number): void {
    if (this.voiceData === undefined) {
      console.error('voiceData undefined');
      return;
    }
    this.mouseTimer?.skip();

    const date = new Date();
    debugDisplay('voice:', this.voiceData, 'date', date.toLocaleString());
    AudioApi.play({ type: this.voiceData });

    if (this.voiceData === ISongs.vo10000) {
      this.stateData.defaultMix = 0;
      this.state.setAnimation(1, talkList[motion]!.mouthDefault, true);
    } else {
      const mouthVoice = talkList[motion]!.mouthVoice;

      this.stateData.defaultMix = 0;
      const mouthMotion = this.state.setAnimation(1, mouthVoice, true);
      mouthMotion.timeScale = 0.6;
      const date = new Date();
      debugDisplay(
        '口パク開始　口のモーション mouthVoice',
        this.motion,
        talkList[this.motion]!.mouthVoice,
        'this.voiceData',
        this.voiceData,
        'duration',
        mappedAudioSprites[this.voiceData!]!.duration,
        'date',
        date.toLocaleString(),
      );

      this.mouseTimer = Tween.createDelayAnimation(mappedAudioSprites[this.voiceData!]!.duration);
      this.mouseTimer.addOnComplete(() => {
        const date = new Date();
        debugDisplay(
          'addOnComplete 口パク終了　口のモーション mouthVoice',
          this.motion,
          talkList[this.motion]!.mouthVoice,
          'date',
          date.toLocaleString(),
        );
        AudioApi.stop({ type: this.voiceData! });
        this.stateData.defaultMix = 0;
        const mouthMotion1 = this.state.setAnimation(1, talkList[this.motion]!.mouthDefault, false);
        mouthMotion1.timeScale = 0.6;
      });
      this.mouseTimer.addOnSkip(() => {
        const date = new Date();
        debugDisplay(
          'addOnSkip 口パクSkip　口のモーション mouthVoice',
          this.motion,
          talkList[this.motion]!.mouthVoice,
          'date',
          date.toLocaleString(),
        );
        AudioApi.stop({ type: this.voiceData! });
        this.stateData.defaultMix = 0;
        const mouthMotion1 = this.state.setAnimation(1, talkList[this.motion]!.mouthDefault, false);
        mouthMotion1.timeScale = 0.6;
      });
      this.mouseTimer.start();
    }
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      this.position.x = AVATAR_LANDSCAPE_POS_X;
      this.position.y = AVATAR_LANDSCAPE_POS_Y;
    } else {
      this.position.x = AVATAR_PORTRAIT_POS_X;
      this.position.y = AVATAR_PORTRAIT_POS_Y;
    }
  }

  private setDefaultMix(isIdle: boolean): void {
    let mix = 0;
    if (isIdle && this.isIdleMotion) {
      mix = 0.1;
    }
    this.stateData.defaultMix = mix;
  }
}

export default VAvatar;
