import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils/utils';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  CLEARED_MESSAGE_DELAY_DURATION,
  CLEARED_MESSAGE_FADEOUT_DURATION,
  CLEARED_MESSAGE_POSITION1_Y,
  CLEARED_MESSAGE_POSITION2_Y,
  CLEARED_MESSAGE_POSITION3_Y,
  CLEARED_MESSAGE_POSITION_X,
  clearedMessageStyle,
} from './config';

class ExtraChallengeMessage extends ViewContainer {
  private text1: PIXI.Text;

  private text2: PIXI.Text;

  private text3: PIXI.Text;

  private animation?: AnimationChain;

  constructor() {
    super();

    this.text1 = new PIXI.Text(i18n.t<string>('extraMessage'), clearedMessageStyle);
    this.text2 = new PIXI.Text(i18n.t<string>('challenge'), clearedMessageStyle);
    this.text3 = new PIXI.Text(i18n.t<string>('start'), clearedMessageStyle);
    this.text1.resolution = 1;
    this.text2.resolution = 2;
    this.text3.resolution = 3;
    this.text1.anchor.set(0.5);
    this.text2.anchor.set(0.5);
    this.text3.anchor.set(0.5);
    this.text1.x = CLEARED_MESSAGE_POSITION_X;
    this.text1.y = CLEARED_MESSAGE_POSITION1_Y;
    this.text2.x = CLEARED_MESSAGE_POSITION_X;
    this.text2.y = CLEARED_MESSAGE_POSITION2_Y;
    this.text3.x = CLEARED_MESSAGE_POSITION_X;
    this.text3.y = CLEARED_MESSAGE_POSITION3_Y;

    updateTextScale(this.text1, 1100, 350);
    updateTextScale(this.text2, 1100, 350);
    updateTextScale(this.text3, 1100, 350);
    this.position.y = 0;
    this.zIndex = 60;
    this.startRetrigger();
    eventManager.once(EventTypes.HANDLE_SKIP_CLEARED_MESSAGE, this.skip.bind(this));
    AudioApi.play({ type: ISongs.SE_ExtraMessage });
  }

  private startRetrigger(): void {
    this.addChild(this.text1, this.text2, this.text3);

    this.animation = new AnimationChain();
    const delay = Tween.createDelayAnimation(CLEARED_MESSAGE_DELAY_DURATION);

    this.animation.appendAnimation(delay);

    const fadeOut = new Tween({
      object: this,
      target: 0,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      duration: CLEARED_MESSAGE_FADEOUT_DURATION,
    });

    this.animation.appendAnimation(fadeOut);

    this.animation.addOnComplete(() => {
      this.handleDestroy();
    });

    this.animation.addOnSkip(() => {
      this.handleDestroy();
    });

    this.animation.start();
  }

  private skip() {
    this.animation?.skip();
  }

  private handleDestroy(): void {
    eventManager.removeAllListeners(EventTypes.HANDLE_SKIP_CLEARED_MESSAGE);
    SlotMachine.getInstance().gameView.removeChild(this);
    this.destroy({ children: true });
  }
}

export default ExtraChallengeMessage;
