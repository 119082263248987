import { CroonRoundMode } from '../../global';
import { ResourceTypes } from '../../resources.d';

export const BACKGROUND_SIZE_WIDTH = 1920;
export const BACKGROUND_SIZE_HEIGHT = 1080;

export const HEIGHT_BASE = 850;
export const ADD_CROON_HIGHT = 600;

export const CROON_GAME_WIDTH = 1600;

export enum CroonStateName {
  croon1 = 'croon1',
  croon2 = 'croon2',
  croon3 = 'croon3',
  croon4 = 'croon4',
  croon5 = 'croon5',
}

export type BgSkin = 'default';

export type CroonSkin = 'croon1' | 'croon2' | 'croon3' | 'croon4' | 'croon5';

export const croonStateType: CroonStateName[] = [
  CroonStateName.croon1,
  CroonStateName.croon1,
  CroonStateName.croon2,
  CroonStateName.croon2,
  CroonStateName.croon3,
  CroonStateName.croon3,
  CroonStateName.croon4,
  CroonStateName.croon4,
  CroonStateName.croon5,
  CroonStateName.croon5,
  CroonStateName.croon5,
  CroonStateName.croon5,
];

export const CroonTexturesList: Record<CroonSkin, ResourceTypes> = {
  croon1: ResourceTypes.bonusgameBase,
  croon2: ResourceTypes.bonusgameBase2,
  croon3: ResourceTypes.bonusgameBase3,
  croon4: ResourceTypes.bonusgameBase4,
  croon5: ResourceTypes.bonusgameBase5,
};

export type AddStageNumType = Record<CroonRoundMode, { addStage: number }>;
export const addStageNum: AddStageNumType = {
  SP1: {
    addStage: 1,
  },
  SP3: {
    addStage: 3,
  },
  SP5: {
    addStage: 5,
  },
  NM3: {
    addStage: 10,
  },
  NM5: {
    addStage: 10,
  },
  NM10: {
    addStage: 10,
  },
};
