import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { getAnimationSelectionTableLot } from '../anticipation';
import { AnimationType, baseGamePhoenix } from '../anticipation/table';
import { ISongs, SlotId, mappedAudioSprites, red } from '../config';
import {
  BetBonusReward,
  BetReward,
  CroonRoundMode,
  EventTypes,
  GameMode,
  ISettledBet,
  MessageCroonBonusBannerProps,
  PachiCroonRounds,
  ReelAnticipation,
  UserBonus,
  reelSets,
} from '../global.d';
import {
  SetAvatarStatusControl,
  SetAvatarTension,
  SetCroonMode,
  setAnticipationLine,
  setAnticipationSlotId,
  setBrokenGame,
  setBrokenGameBgm,
  setBrokenGameReelSet,
  setCoinAmount,
  setCoinValue,
  setCroonBonusRounds,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setCurrentStage,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsCroonStateOpening,
  setIsDuringBigWinLoop,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setNextResult,
  setPage,
  setPrevReelsPosition,
  setReelAnticipation,
  setReelSetId,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { ReelSetType, isStoppedGql } from '../gql/query';
import {
  countCoins,
  debugDisplay,
  formatNumber,
  getCroonMode,
  getCroonRoundModeToRoundNum,
  getGameModeByBonusId,
  getSpinResult3x1,
  getStopReel,
  isBaseGameMode,
  isCroonBonusMode,
  normalizeCoins,
  setAvatarTension,
  showCurrency,
} from '../utils';

import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import AvatarMotion from './avatarMotion/avatarMotion';
import { StateType, croonState } from './avatarMotion/config';
import { StatusControlFlg } from './avatarTalk/config';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import bgmControl from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  BONUS_SYMBOLS_ID,
  FREE_SPINS_FADE_IN_DURATION,
  FREE_SPINS_FADE_OUT_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  REELS_AMOUNT,
  SlotMachineState,
  eventManager,
} from './config';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsStopDisplayContainer } from './winAnimations/slotsStopDisplayContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public nextResultFlg = true;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  private endingDisplay = false;

  private reelPositions: number[] = [0, 0, 0];

  private reelSetId: string | null = null;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public slotsStopDisplayContainer: SlotsStopDisplayContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  private phoenix: Phoenix;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  private avatarMotion: AvatarMotion;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    let startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    let reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;

    if (startPosition.length === 0) {
      startPosition = [0, 0, 0];
      reelSet = slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;
    }
    if (setBrokenGame()) {
      startPosition = setPrevReelsPosition();
      reelSet = slotConfig.reels.find((reelSet) => reelSet.id === setBrokenGameReelSet())!;
    }
    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));

    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    const spinResult = getSpinResult3x1({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });
    debugDisplay('spinResult', spinResult);

    this.slotsStopDisplayContainer = new SlotsStopDisplayContainer(spinResult);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, true);

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.application.stage.addChild(this.background);
    this.application.stage.sortableChildren = true;

    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      slotStopDisplayContainer: this.slotsStopDisplayContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);

    // TODO
    this.initBuyFeature(slotConfig.lines, this.gameView);
    this.avatarMotion = new AvatarMotion();
    this.application.stage.addChild(this.avatarMotion);
    this.application.stage.addChild(this.bottom);
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);

    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);

    this.phoenix = new Phoenix();
    this.application.stage.addChild(this.phoenix);

    if (setBrokenGame()) {
      this.onBrokenGame();
    }
  }

  private initBuyFeature(_lines: number[][], view: GameView): void {
    view.addChild(new Backdrop());
  }

  private onBrokenGame(): void {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonus.id);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    this.setState(SlotMachineState.IDLE);

    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);

      eventManager.emit(EventTypes.CROON_TOP_VISIBLE, false);

      debugDisplay('SetCroonMode()', SetCroonMode());

      eventManager.emit(EventTypes.SET_CROON_STATE, getCroonRoundModeToRoundNum(SetCroonMode()));

      eventManager.emit(EventTypes.MINI_MAP_VISIBILITY, SetCroonMode(), true);

      setCurrentStage(setPage());

      if (
        (SetCroonMode() === CroonRoundMode.NM3 && setPage() > 2) ||
        (SetCroonMode() === CroonRoundMode.NM5 && setPage() > 4)
      ) {
        eventManager.emit(EventTypes.CROON_BONUS_END);
        eventManager.emit(EventTypes.MINIMAP_EXTRA_STAGE);
      }

      eventManager.emit(EventTypes.CROON_SET_STAGE, setPage());
      eventManager.emit(EventTypes.MINIMAP_CURRENT_SET, setPage() + 1);
      eventManager.emit(EventTypes.MINI_MAP_SET_CURRENT, setPage() + 1);

      eventManager.emit(EventTypes.SET_IDLE_CLEARED);
      eventManager.emit(EventTypes.CROON_START, setPage());
      this.spin(false);
      eventManager.emit(EventTypes.CROON_STAGE_SPIN);
      this.clearNextResult();
    }
    setBrokenGameBgm(true);
    setBrokenGame(false);
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.CROON_DROP, this.onCroonDrop.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.SE_Win_Loop });
        AudioApi.play({ type: ISongs.SE_BigWin_Loop });
      }
    });
    eventManager.addListener(EventTypes.SET_ENDING_DISPLAY, this.setEndingDisplay.bind(this));

    eventManager.addListener(EventTypes.SET_SLOT_BUSY_DISABLE, () => {
      this.isSlotBusyCallback();
    });

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));

    eventManager.addListener(EventTypes.CREATE_END_MESSAGE, this.createEndMessage.bind(this));

    eventManager.addListener(EventTypes.FREE_SPIN, this.spin.bind(this));

    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));
  }

  private createEndMessage(): void {
    debugDisplay('CREATE_END_MESSAGE 1');
    this.endFreeSpins();
  }

  private createFreeSpinsMessage(_props: MessageCroonBonusBannerProps): void {
    // this.application.stage.addChild(new MessageCroonBonusBanner(props).init());
    // AudioApi.play({ type: ISongs.BB_Banner }); // TODO
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
    this.nextResultFlg = true;
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      debugDisplay('----- settings.mode', settings.mode);
      setGameMode(settings.mode);

      if (settings.mode === GameMode.REGULAR) {
        bgmControl.StopBonusBgmAll();
        setCurrentFreeSpinsTotalWin(0);
        this.isSpinInProgressCallback();

        eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      }
    }
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isCroonBonusMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');

      setCurrentBonus({ ...bonus });
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      eventManager.emit(EventTypes.CROON_TOP_VISIBLE, false);
      eventManager.emit(EventTypes.SET_CROON_STATE, getCroonRoundModeToRoundNum(SetCroonMode()));

      eventManager.emit(EventTypes.MINI_MAP_VISIBILITY, SetCroonMode(), true);
      eventManager.emit(EventTypes.MINIMAP_CURRENT_SET, setPage() - 1);

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t('croonBonus'),
          description1: i18n.t('croonBonusText1'),
          description2: i18n.t('croonBonusText2'),
          btnText: i18n.t('startText'),
          round: setCurrentBonus().data.pachiCroonRounds.length,
          callback: () => {
            eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
            eventManager.emit(EventTypes.CROON_STATE_START_SCROLL);
            setCurrentStage(0);
            setCurrentBonus({
              ...bonus,
              isActive: true,
            });
          },
        });
      } else {
        setCurrentBonus({
          ...bonus,
          isActive: true,
        });
        this.setState(SlotMachineState.IDLE);

        eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
        eventManager.emit(EventTypes.CROON_STATE_START_SCROLL);
        setCurrentStage(0);
        setCurrentBonus({
          ...bonus,
          isActive: true,
        });
      }
    }
  }

  private startFreeSpins(): void {
    setIsCroonStateOpening(true);
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.CROON_BONUS,
      reelPositions: [1, 1, 1],
      reelSetId: reelSets[GameMode.CROON_BONUS]!,
      fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
      fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
    });
    setTimeout(() => {
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      } else {
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      }
    }, FREE_SPINS_FADE_IN_DURATION);
  }

  private async endFreeSpins(): Promise<void> {
    // const res = await client.query<{
    //   userBonuses: UserBonus[];
    // }>({
    //   query: getUserBonuses,
    //   variables: { input: { id: setCurrentBonus().id } },
    //   fetchPolicy: 'network-only',
    // });
    // const { betId } = res.data.userBonuses[0];
    // const bet = await client.query<ISettledBet>({
    //   query: slotBetGql,
    //   variables: { input: { id: betId } },
    //   fetchPolicy: 'network-only',
    // });

    // const { reelPositions, reelSetId } = {
    //   reelPositions: bet.data.bet.result.reelPositions,
    //   reelSetId: bet.data.bet.reelSetId,
    // };
    const reelPositions: number[] = [7, 7, 7];
    const reelSetId = '';

    // AudioApi.play({ type: ISongs.TotalWinBanner, stopPrev: true });

    debugDisplay('setCurrentFreeSpinsTotalWin()', setCurrentFreeSpinsTotalWin());
    debugDisplay('setFreeSpinsTotalWin()', setFreeSpinsTotalWin());

    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    debugDisplay('========================1');
    this.setEndingDisplay(reelPositions, reelSetId);
    setBrokenGameBgm(false);
    setBrokenGame(false);
  }

  private setEndingDisplayCallback(): void {
    if (this.endingDisplay && this.nextResultFlg) {
      this.endingDisplay = false;
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId: this.reelSetId!,
        fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
        fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
        reelPositions: this.reelPositions,
      });
      this.reelSetId = null;
      this.reelPositions = [0, 0, 0];
      const stage = croonState[SetCroonMode()]!.state[setCurrentStage()];

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency())),
        );

        const amount = normalizeCoins(countCoins({ totalAmount: setCoinAmount() }));

        const totalWin = normalizeCoins(setFreeSpinsTotalWin()) / Number(amount);

        const winAmount = normalizeCoins(totalWin);

        if (stage === StateType.END) {
          if (setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position === 0) {
            debugDisplay('ボーナスで最高配当ゲットだぜ');
            SetAvatarStatusControl(StatusControlFlg.GET);
          }
          if (setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position != 0) {
            debugDisplay('終盤後でドボンしてテンション下げ');
            SetAvatarStatusControl(StatusControlFlg.ZANNEN);
          }
        } else {
          if (winAmount >= 50) {
            debugDisplay('ボーナスで高配当ゲット');
            SetAvatarStatusControl(StatusControlFlg.GET);
          }
        }

        // 7.2.2 テンション変化条件
        if (
          winAmount >= 10000 ||
          (SetCroonMode() === CroonRoundMode.NM3 && winAmount >= 100) ||
          (SetCroonMode() === CroonRoundMode.NM5 && winAmount >= 1000)
        ) {
          // 最高配当（10000倍, もしくはNM3の100倍, NM5の1000倍）を獲得した +2
          debugDisplay('1.アバターテンション更新前', SetAvatarTension());
          setAvatarTension(2);
          debugDisplay('1.アバターテンション更新後', SetAvatarTension());
        } else if (stage === StateType.END) {
          // 終盤ステージで終了 -2
          debugDisplay('2.アバターテンション更新前', SetAvatarTension());
          setAvatarTension(-2);
          debugDisplay('2.アバターテンション更新後', SetAvatarTension());
        } else if (winAmount >= 20) {
          // Betの20倍以上獲得した +2
          debugDisplay('3.アバターテンション更新前', SetAvatarTension());
          setAvatarTension(2);
          debugDisplay('3.アバターテンション更新後', SetAvatarTension());
        } else if (winAmount >= 10) {
          // Betの10倍以上獲得した +1
          debugDisplay('4.アバターテンション更新前', SetAvatarTension());
          setAvatarTension(1);
          debugDisplay('4.アバターテンション更新後', SetAvatarTension());
        } else {
          // 上記該当せず 変化無し
        }
      }, FREE_SPINS_FADE_IN_DURATION);
    }
  }

  private setEndingDisplay(reelPositions: number[], reelSetId: string): void {
    this.reelPositions = reelPositions;
    this.reelSetId = reelSetId;

    const callback = () => {
      this.endingDisplay = true;
      this.setEndingDisplayCallback();
    };
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    eventManager.emit(EventTypes.SET_SLOT_BUSY_DISABLE);
    eventManager.emit(EventTypes.BONUS_END);
    debugDisplay(
      '看板 setFreeSpinsTotalWin',
      setFreeSpinsTotalWin(),
      'normalizeCoins(setFreeSpinsTotalWin())',
      normalizeCoins(setFreeSpinsTotalWin()),
      'setCoinAmount()',
      setCoinAmount(),
    );

    const totalWin = setFreeSpinsTotalWin() / setCoinAmount() / setCoinValue();
    if (totalWin >= 10000) {
      AudioApi.play({ type: ISongs.SE_Fanfare2 });
    } else {
      AudioApi.play({ type: ISongs.SE_Fanfare1 });
    }
    bgmControl.fadeOutAll(100);
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('TotalWinTitle'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency()))}`,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('TotalWinTitle'),
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isNumber(arg: any): arg is number {
    return typeof arg === 'number';
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;

    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          if (isCroonBonusMode(setGameMode())) {
            //
          } else {
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            );
          }
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      if (isCroonBonusMode(setGameMode())) {
        const bonus = setCurrentBonus();
        setCurrentBonus(bonus);
      } else {
        debugDisplay('spin this.nextResult2', this.nextResult);

        eventManager.emit(EventTypes.START_SPIN_ANIMATION);
        this.skipAnimations();
        eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
        const spinAnimation = this.getSpinAnimation(!isCroonBonusMode(setGameMode()) && !!isTurboSpin);

        spinAnimation.start();
      }
      this.setState(SlotMachineState.SPIN);
    }

    if (this.state === SlotMachineState.CROON_WINNING) {
      this.skipAnimations();
    }

    if (this.state === SlotMachineState.STOP) {
      debugDisplay(red + ' == spin setNextResult()!.bet.result.spinResult', setNextResult()!.bet.result.spinResult);
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            if (isCroonBonusMode(setGameMode())) {
              // this.updateFreeSpinsAmount(setCurrentBonus().currentRound);
            }
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  public getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'PRE_LOADED';
    })?.userBonus;
  }

  public getReSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'SPECIAL_ROUND';
    })?.userBonus;
  }

  private onCountUpEnd(): void {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    if (freeSpinsBonus && !isCroonBonusMode(mode)) {
      debugDisplay('ボーナス揃い', this.nextResult?.bet.result.winCoinAmount);

      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
      });
      this.startFreeSpins();
      this.setState(SlotMachineState.IDLE);
    } else if (!freeSpinsBonus && isCroonBonusMode(mode)) {
      setCurrentFreeSpinsTotalWin(
        setCurrentFreeSpinsTotalWin() +
          setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.coinReward * setCoinAmount() * setCoinValue(),
      );
      debugDisplay(
        'ボーナス中 setCurrentFreeSpinsTotalWin()',
        setCurrentFreeSpinsTotalWin(),
        setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.coinReward,
        setCoinAmount(),
        setSlotConfig().lineSet.coinAmountMultiplier,
      );
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }

      // debugDisplay('--onCountUpEnd2', performance.now());
      this.setState(SlotMachineState.IDLE);
    } else {
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
      if (isCroonBonusMode(mode)) {
        debugDisplay(
          '====== setCurrentFreeSpinsTotalWin()',
          setCurrentFreeSpinsTotalWin(),
          'this.nextResult!.bet.result.winCoinAmount',
          this.nextResult!.bet.result.winCoinAmount,
        );
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      this.setState(SlotMachineState.IDLE);
    }
  }

  private dynamicReelSetChange(): void {
    // TODO
    // debugDisplay('reelSets', reelSets, 'setGameMode()', setGameMode());
    // debugDisplay(
    //   'setReelSetId() ',
    //   setReelSetId(),
    //   'reelSets[setGameMode()])',
    //   reelSets[setGameMode()],
    // );
    // debugDisplay('setSlotConfig().reels', setSlotConfig().reels);
    if (setReelSetId() !== reelSets[setGameMode()]) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelSets[setGameMode()])!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSets[setGameMode()]);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationStartReelId(spinResult: Icon[]): number {
    setReelAnticipation(ReelAnticipation.NON);
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;

    const lineResult = this.slotConfig.lines.map((line) => line.map((v) => spinResult[v]));

    if (lineResult.length === 0) {
      return 0;
    }

    const anticipationLine: number[] = [];
    const anticipationSlotId: SlotId[] = [];

    ANTICIPATION_SYMBOLS_ID.forEach((symbolId, i) => {
      const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i]!;

      lineResult.some((line, index) => {
        if (line[0]!.id === symbolId && line[1]!.id === symbolId) {
          setReelAnticipation(ReelAnticipation.BONUS);
          minReelId = Math.min(minReelId, count);
          anticipationLine.push(index);
          anticipationSlotId.push(symbolId);
        }

        if (line[0]!.id === symbolId && line[1]!.id === symbolId && line[2]!.id === symbolId) {
          debugDisplay('setCurrentBonus().data.stage', setCurrentBonus());
          debugDisplay('------', this.getFreeSpinBonus()?.data.stage);

          let croonBonusMode;
          const stage = this.getFreeSpinBonus()?.data.stage;
          if (stage != undefined) {
            croonBonusMode = getCroonMode(stage);
          }

          SetCroonMode(croonBonusMode);
          let lotTblIndex = 0;
          switch (croonBonusMode) {
            case CroonRoundMode.SP1:
              lotTblIndex = 0;
              break;
            case CroonRoundMode.SP3:
              lotTblIndex = 1;
              break;
            case CroonRoundMode.SP5:
              lotTblIndex = 2;
              break;
            case CroonRoundMode.NM3:
              lotTblIndex = 3;
              break;
            case CroonRoundMode.NM5:
              lotTblIndex = 4;
              break;
            case CroonRoundMode.NM10:
              lotTblIndex = 5;
              break;
            default:
              break;
          }

          debugDisplay('setNextResult()!.bet.id', setNextResult()!.bet.id, 'lotTblIndex', lotTblIndex);
          const AnimationPtn = getAnimationSelectionTableLot(setNextResult()!.bet.id, baseGamePhoenix[lotTblIndex]!);
          if (AnimationPtn === AnimationType.PHOENIX) {
            eventManager.emit(EventTypes.PHOENIX_START);
          }
        }
      });
    });

    setAnticipationLine(anticipationLine);
    setAnticipationSlotId(anticipationSlotId);

    return minReelId;
  }

  private getStopSoundSymbolCount(spinResult: Icon[]): number[] {
    let reelStopSound = [0, 0, 0];
    if (isBaseGameMode(setGameMode())) {
      reelStopSound = this.calcStopSoundSymbolCount(spinResult, 1, BONUS_SYMBOLS_ID);
    }
    return reelStopSound;
  }

  private calcStopSoundSymbolCount(spinResult: Icon[], cal: number, symbol: SlotId[][]): number[] {
    const iSoundCnt = [0, 0, 0];

    debugDisplay('calcStopSoundSymbolCount', spinResult);
    if (spinResult.length === 0) {
      return iSoundCnt;
    }

    this.slotConfig.lines.forEach((line) => {
      symbol.forEach((slot) => {
        for (let x = 0; x < slot.length; x++) {
          const index = getStopReel(x);
          if (spinResult[line[index]!]!.id === slot[index]) {
            if (index === 0) {
              iSoundCnt[index] = cal;
            } else if (index === 1) {
              if (slot[index]![0] === SlotId.A) {
                iSoundCnt[index] = iSoundCnt[0]! + 1;
              }
            } else {
              iSoundCnt[index] = iSoundCnt[1]! + 1;
            }
          } else {
            break;
          }
        }
      });
    });
    return iSoundCnt;
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE || this.state === SlotMachineState.CROON_WINNING) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    this.nextResultFlg = true;
    if (isCroonBonusMode(setGameMode())) {
      debugDisplay('=============== クルーンSPIN　＝＝＝＝＝＝＝＝＝＝setCurrentStage()', setCurrentStage());
      this.nextResult = result;
      eventManager.emit(EventTypes.CROON_RESULT, setCurrentStage());
      this.setEndingDisplayCallback();
    } else {
      const spinResult = getSpinResult3x1({
        reelPositions: result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
        reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
        icons: setSlotConfig().icons,
      });
      result.bet.result.spinResult = spinResult;
      setPrevReelsPosition(result.bet.result.reelPositions.slice(0, REELS_AMOUNT));
      this.nextResult = result;
      setNextResult(result);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);

      const userBonusId = this.getCroonBonusRounds(result);
      if (userBonusId != undefined) {
        setCroonBonusRounds(userBonusId);
      }
    }
  }

  public clearNextResult(): void {
    this.nextResultFlg = false;
  }

  private getCroonBonusRounds(result: ISettledBet): PachiCroonRounds[] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return (
      result.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus?.data!.pachiCroonRounds!;
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    this.setState(SlotMachineState.JINGLE);
  }

  public onCroonDrop(): void {
    this.isSpinInProgressCallback();
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    debugDisplay(red + 'STOP.StopSpin', SlotMachine.getInstance().state);
    const state = SlotMachine.getInstance().state;
    if (state === SlotMachineState.SPIN || state === SlotMachineState.STOP) this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isCroonBonusMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  public hasWin() {
    if (this.nextResult === null) {
      return false;
    }
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();

      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isCroonBonusMode(setGameMode())) {
        if (setBrokenGame() && SlotMachine.getInstance() != undefined) {
          // eventManager.emit(EventTypes.CROON_STAGE_SPIN);
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus()) {
        const jingleWait = Tween.createDelayAnimation(1000);
        jingleWait.addOnComplete(() => {
          const jingleDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SE_BonusTrigger]!.duration);
          jingleDelay.addOnStart(() => {
            AudioApi.play({ type: ISongs.SE_BonusTrigger, stopPrev: true });
          });
          jingleDelay.addOnComplete(() => {
            this.setState(SlotMachineState.WINNING);
          });
          jingleDelay.start();
        });
        jingleWait.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      eventManager.emit(EventTypes.SPIN_END);
      if (this.hasWin() || this.getFreeSpinBonus()) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
      } else {
        if (!isCroonBonusMode(setGameMode())) {
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
        }
        this.onCountUpEnd();
      }
    }
    if (state === SlotMachineState.CROON_WINNING) {
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });

      // クルーンステージ中のコイン払い出し部分
      debugDisplay('★★★☆setCurrentBonus()', setCurrentBonus());
      debugDisplay('setCurrentBonus()', setCurrentBonus());

      bgmControl.stopBgm();

      const win = setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.coinReward * setCoinAmount();
      eventManager.emit(EventTypes.HANDLE_SKIP_CLEARED_MESSAGE);
      eventManager.emit(EventTypes.START_WIN_ANIMATION, win, false);
    }
  }
}

export default SlotMachine;
