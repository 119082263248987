import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BGM_Base_Loop = '014_BGM_Base_Loop',
  BGM_Bonus1_Loop = '014_BGM_Bonus1_Loop',
  BGM_Bonus2_Loop = '014_BGM_Bonus2_Loop',
  BGM_Bonus3_Loop = '014_BGM_Bonus3_Loop',
  BGM_Bonus4_Loop = '014_BGM_Bonus4_Loop',
  BGM_TotalWinBanner = '014_BGM_TotalWinBanner',
  SE_BallDrop1 = '014_SE_BallDrop1',
  SE_BallDrop2 = '014_SE_BallDrop2',
  SE_BallDrop3 = '014_SE_BallDrop3',
  SE_BallSpin1_Loop = '014_SE_BallSpin1_Loop',
  SE_BallSpin2_Loop = '014_SE_BallSpin2_Loop',
  SE_BallSpin3_Loop = '014_SE_BallSpin3_Loop',
  SE_BigWin_End = '014_SE_BigWin_End',
  SE_BigWin_Loop = '014_SE_BigWin_Loop',
  SE_BonusStop1 = '014_SE_BonusStop1',
  SE_BonusStop2 = '014_SE_BonusStop2',
  SE_BonusStop3 = '014_SE_BonusStop3',
  SE_BonusTrigger = '014_SE_BonusTrigger',
  SE_ExtraMessage = '014_SE_ExtraMessage',
  SE_ExtraShutter = '014_SE_ExtraShutter',
  SE_ExtraWarning = '014_SE_ExtraWarning',
  SE_Fanfare1 = '014_SE_Fanfare1',
  SE_Fanfare2 = '014_SE_Fanfare2',
  SE_StageMessage = '014_SE_StageMessage',
  SE_Win_End = '014_SE_Win_End',
  SE_Win_Loop = '014_SE_Win_Loop',
  SE_Yocho_Phoenix = 'SE_Yocho_Phoenix',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  vo10000 = 'vo10000',
  vo10101 = 'vo10101',
  vo10102 = 'vo10102',
  vo10103 = 'vo10103',
  vo10104 = 'vo10104',
  vo10105 = 'vo10105',
  vo10106 = 'vo10106',
  vo10107 = 'vo10107',
  vo10108 = 'vo10108',
  vo10109 = 'vo10109',
  vo10110 = 'vo10110',
  vo10111 = 'vo10111',
  vo10112 = 'vo10112',
  vo10113 = 'vo10113',
  vo10201 = 'vo10201',
  vo10202 = 'vo10202',
  vo10203 = 'vo10203',
  vo10204 = 'vo10204',
  vo10205 = 'vo10205',
  vo10206 = 'vo10206',
  vo10207 = 'vo10207',
  vo10208 = 'vo10208',
  vo10209 = 'vo10209',
  vo10210 = 'vo10210',
  vo10211 = 'vo10211',
  vo10212 = 'vo10212',
  vo10213 = 'vo10213',
  vo10214 = 'vo10214',
  vo10215 = 'vo10215',
  vo10216 = 'vo10216',
  vo10301 = 'vo10301',
  vo10302 = 'vo10302',
  vo10303 = 'vo10303',
  vo10304 = 'vo10304',
  vo10305 = 'vo10305',
  vo10306 = 'vo10306',
  vo10307 = 'vo10307',
  vo10308 = 'vo10308',
  vo10309 = 'vo10309',
  vo10310 = 'vo10310',
  vo10311 = 'vo10311',
  vo10312 = 'vo10312',
  vo10313 = 'vo10313',
  vo10401 = 'vo10401',
  vo10402 = 'vo10402',
  vo10403 = 'vo10403',
  vo10404 = 'vo10404',
  vo10405 = 'vo10405',
  vo10406 = 'vo10406',
  vo10407 = 'vo10407',
  vo10408 = 'vo10408',
  vo10409 = 'vo10409',
  vo10410 = 'vo10410',
  vo20101 = 'vo20101',
  vo20102 = 'vo20102',
  vo20103 = 'vo20103',
  vo20104 = 'vo20104',
  vo20105 = 'vo20105',
  vo20106 = 'vo20106',
  vo20201 = 'vo20201',
  vo20202 = 'vo20202',
  vo20203 = 'vo20203',
  vo20301 = 'vo20301',
  vo20302 = 'vo20302',
  vo20303 = 'vo20303',
  vo20304 = 'vo20304',
  vo20305 = 'vo20305',
  vo20306 = 'vo20306',
  vo20401 = 'vo20401',
  vo20402 = 'vo20402',
  vo20403 = 'vo20403',
  vo20404 = 'vo20404',
  vo20405 = 'vo20405',
  vo20406 = 'vo20406',
  vo20501 = 'vo20501',
  vo20502 = 'vo20502',
  vo20503 = 'vo20503',
  vo20504 = 'vo20504',
  vo20505 = 'vo20505',
  vo20601 = 'vo20601',
  vo20602 = 'vo20602',
  vo20603 = 'vo20603',
  vo20604 = 'vo20604',
  vo20605 = 'vo20605',
  vo20606 = 'vo20606',
  vo20701 = 'vo20701',
  vo20702 = 'vo20702',
  vo20703 = 'vo20703',
  vo20704 = 'vo20704',
  vo20705 = 'vo20705',
  vo30101 = 'vo30101',
  vo30102 = 'vo30102',
  vo30103 = 'vo30103',
  vo30104 = 'vo30104',
  vo30201 = 'vo30201',
  vo30202 = 'vo30202',
  vo30203 = 'vo30203',
  vo30204 = 'vo30204',
  vo30301 = 'vo30301',
  vo30302 = 'vo30302',
  vo30303 = 'vo30303',
  vo30304 = 'vo30304',
  vo30401 = 'vo30401',
  vo30402 = 'vo30402',
  vo30403 = 'vo30403',
  vo30404 = 'vo30404',
  vo30501 = 'vo30501',
  vo30502 = 'vo30502',
  vo30503 = 'vo30503',
  vo30504 = 'vo30504',
  vo30601 = 'vo30601',
  vo30602 = 'vo30602',
  vo30603 = 'vo30603',
  vo30604 = 'vo30604',
  vo30701 = 'vo30701',
  vo30702 = 'vo30702',
  vo30703 = 'vo30703',
  vo30704 = 'vo30704',
}

export enum ISongsVoice {
  vo10000 = 'vo10000',
  vo10101 = 'vo10101',
  vo10102 = 'vo10102',
  vo10103 = 'vo10103',
  vo10104 = 'vo10104',
  vo10105 = 'vo10105',
  vo10106 = 'vo10106',
  vo10107 = 'vo10107',
  // vo10108 = 'vo10108',
  // vo10109 = 'vo10109',
  // vo10110 = 'vo10110',
  // vo10111 = 'vo10111',
  // vo10112 = 'vo10112',
  // vo10113 = 'vo10113',
  vo10201 = 'vo10201',
  vo10202 = 'vo10202',
  vo10203 = 'vo10203',
  vo10204 = 'vo10204',
  vo10205 = 'vo10205',
  vo10206 = 'vo10206',
  vo10207 = 'vo10207',
  // vo10208 = 'vo10208',
  // vo10209 = 'vo10209',
  // vo10210 = 'vo10210',
  // vo10211 = 'vo10211',
  // vo10212 = 'vo10212',
  // vo10213 = 'vo10213',
  // vo10214 = 'vo10214',
  // vo10215 = 'vo10215',
  // vo10216 = 'vo10216',
  vo10301 = 'vo10301',
  vo10302 = 'vo10302',
  vo10303 = 'vo10303',
  vo10304 = 'vo10304',
  vo10305 = 'vo10305',
  vo10306 = 'vo10306',
  vo10307 = 'vo10307',
  // vo10308 = 'vo10308',
  // vo10309 = 'vo10309',
  // vo10310 = 'vo10310',
  // vo10311 = 'vo10311',
  // vo10312 = 'vo10312',
  // vo10313 = 'vo10313',
  // vo10401 = 'vo10401',
  // vo10402 = 'vo10402',
  // vo10403 = 'vo10403',
  // vo10404 = 'vo10404',
  // vo10405 = 'vo10405',
  // vo10406 = 'vo10406',
  // vo10407 = 'vo10407',
  // vo10408 = 'vo10408',
  // vo10409 = 'vo10409',
  // vo10410 = 'vo10410',
  // vo20101 = 'vo20101',
  // vo20102 = 'vo20102',
  // vo20103 = 'vo20103',
  // vo20104 = 'vo20104',
  // vo20105 = 'vo20105',
  // vo20106 = 'vo20106',
  // vo20201 = 'vo20201',
  // vo20202 = 'vo20202',
  // vo20203 = 'vo20203',
  // vo20301 = 'vo20301',
  // vo20302 = 'vo20302',
  // vo20303 = 'vo20303',
  // vo20304 = 'vo20304',
  // vo20305 = 'vo20305',
  // vo20306 = 'vo20306',
  // vo20401 = 'vo20401',
  // vo20402 = 'vo20402',
  // vo20403 = 'vo20403',
  // vo20404 = 'vo20404',
  // vo20405 = 'vo20405',
  // vo20406 = 'vo20406',
  // vo20501 = 'vo20501',
  // vo20502 = 'vo20502',
  // vo20503 = 'vo20503',
  // vo20504 = 'vo20504',
  // vo20505 = 'vo20505',
  // vo20601 = 'vo20601',
  // vo20602 = 'vo20602',
  // vo20603 = 'vo20603',
  // vo20604 = 'vo20604',
  // vo20605 = 'vo20605',
  // vo20606 = 'vo20606',
  // vo20701 = 'vo20701',
  // vo20702 = 'vo20702',
  // vo20703 = 'vo20703',
  // vo20704 = 'vo20704',
  // vo20705 = 'vo20705',
  // vo30101 = 'vo30101',
  // vo30102 = 'vo30102',
  // vo30103 = 'vo30103',
  // vo30104 = 'vo30104',
  // vo30201 = 'vo30201',
  // vo30202 = 'vo30202',
  // vo30203 = 'vo30203',
  // vo30204 = 'vo30204',
  // vo30301 = 'vo30301',
  // vo30302 = 'vo30302',
  // vo30303 = 'vo30303',
  // vo30304 = 'vo30304',
  // vo30401 = 'vo30401',
  // vo30402 = 'vo30402',
  // vo30403 = 'vo30403',
  // vo30404 = 'vo30404',
  // vo30501 = 'vo30501',
  // vo30502 = 'vo30502',
  // vo30503 = 'vo30503',
  // vo30504 = 'vo30504',
  // vo30601 = 'vo30601',
  // vo30602 = 'vo30602',
  // vo30603 = 'vo30603',
  // vo30604 = 'vo30604',
  // vo30701 = 'vo30701',
  // vo30702 = 'vo30702',
  // vo30703 = 'vo30703',
  // vo30704 = 'vo30704',
}

export const audioSprite: AudioSprite = {
  [ISongs.BGM_Base_Loop]: [0, 49870.13605442177, true],
  [ISongs.BGM_Bonus1_Loop]: [51000, 28444.44444444444, true],
  [ISongs.BGM_Bonus2_Loop]: [81000, 42666.712018140584, true],
  [ISongs.BGM_Bonus3_Loop]: [125000, 39183.718820861686, true],
  [ISongs.BGM_Bonus4_Loop]: [166000, 46545.46485260772, true],
  [ISongs.BGM_TotalWinBanner]: [214000, 8727.256235827666],
  [ISongs.SE_BallDrop1]: [224000, 1578.4807256235922],
  [ISongs.SE_BallDrop2]: [227000, 1591.836734693885],
  [ISongs.SE_BallDrop3]: [230000, 1761.9274376417309],
  [ISongs.SE_BallSpin1_Loop]: [233000, 3566.666666666663, true],
  [ISongs.SE_BallSpin2_Loop]: [238000, 1066.6666666666629, true],
  [ISongs.SE_BallSpin3_Loop]: [241000, 766.6666666666799, true],
  [ISongs.SE_BigWin_End]: [243000, 3396.145124716554],
  [ISongs.SE_BigWin_Loop]: [248000, 20645.170068027197, true],
  [ISongs.SE_BonusStop1]: [270000, 175.71428571426395],
  [ISongs.SE_BonusStop2]: [272000, 175.71428571426395],
  [ISongs.SE_BonusStop3]: [274000, 175.71428571426395],
  [ISongs.SE_BonusTrigger]: [276000, 357.278911564606],
  [ISongs.SE_ExtraMessage]: [278000, 4331.519274376433],
  [ISongs.SE_ExtraShutter]: [284000, 1885.7142857143003],
  [ISongs.SE_ExtraWarning]: [287000, 2522.1541950113533],
  [ISongs.SE_Fanfare1]: [291000, 4150.226757369637],
  [ISongs.SE_Fanfare2]: [297000, 7075.9863945578445],
  [ISongs.SE_StageMessage]: [306000, 4247.845804988685],
  [ISongs.SE_Win_End]: [312000, 933.4693877551103],
  [ISongs.SE_Win_Loop]: [314000, 399.7505668934309, true],
  [ISongs.SE_Yocho_Phoenix]: [316000, 4033.33333333336],
  [ISongs.SFX_SM_CountUp_End]: [324000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [326000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [328000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [330000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [332000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [334000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [336000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [338000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [340000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [342000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [344000, 151.02040816327644],
  [ISongs.vo10000]: [346000, 101.33786848069803],
  [ISongs.vo10101]: [348000, 2612.471655328818],
  [ISongs.vo10102]: [352000, 3055.668934240373],
  [ISongs.vo10103]: [357000, 1671.0430839002015],
  [ISongs.vo10104]: [360000, 3181.2698412698523],
  [ISongs.vo10105]: [365000, 2748.0498866212884],
  [ISongs.vo10106]: [369000, 2917.460317460325],
  [ISongs.vo10107]: [373000, 2519.138321995456],
  [ISongs.vo10108]: [377000, 2639.7959183673265],
  [ISongs.vo10109]: [381000, 2132.811791383233],
  [ISongs.vo10110]: [385000, 2515.8049886621257],
  [ISongs.vo10111]: [389000, 2383.469387755099],
  [ISongs.vo10112]: [393000, 3837.460317460341],
  [ISongs.vo10113]: [398000, 2344.8752834466973],
  [ISongs.vo10201]: [402000, 2646.3038548752706],
  [ISongs.vo10202]: [406000, 1721.269841269816],
  [ISongs.vo10203]: [409000, 1127.4603174603044],
  [ISongs.vo10204]: [412000, 1824.6258503401123],
  [ISongs.vo10205]: [415000, 2217.6870748299393],
  [ISongs.vo10206]: [419000, 2138.639455782311],
  [ISongs.vo10207]: [423000, 2901.836734693859],
  [ISongs.vo10208]: [427000, 2538.5487528344584],
  [ISongs.vo10209]: [431000, 2184.3764172335796],
  [ISongs.vo10210]: [435000, 3671.2698412698614],
  [ISongs.vo10211]: [440000, 1749.3197278911339],
  [ISongs.vo10212]: [443000, 3201.269841269834],
  [ISongs.vo10213]: [448000, 1970.3401360544035],
  [ISongs.vo10214]: [451000, 2023.333333333312],
  [ISongs.vo10215]: [455000, 1615.2154195011121],
  [ISongs.vo10216]: [458000, 2102.87981859409],
  [ISongs.vo10301]: [462000, 1252.5623582766343],
  [ISongs.vo10302]: [465000, 1925.6689342403774],
  [ISongs.vo10303]: [468000, 2088.2993197278665],
  [ISongs.vo10304]: [472000, 2717.9365079364857],
  [ISongs.vo10305]: [476000, 3112.471655328818],
  [ISongs.vo10306]: [481000, 2316.4172335601165],
  [ISongs.vo10307]: [485000, 983.6281179138382],
  [ISongs.vo10308]: [487000, 1205.6009070294635],
  [ISongs.vo10309]: [490000, 1892.0181405895846],
  [ISongs.vo10310]: [493000, 1727.9365079365334],
  [ISongs.vo10311]: [496000, 2701.383219954664],
  [ISongs.vo10312]: [500000, 1413.7188208616749],
  [ISongs.vo10313]: [503000, 1878.9342403628098],
  [ISongs.vo10401]: [506000, 4125.351473922876],
  [ISongs.vo10402]: [512000, 2561.088435374131],
  [ISongs.vo10403]: [516000, 2452.47165532885],
  [ISongs.vo10404]: [520000, 3916.6666666666288],
  [ISongs.vo10405]: [525000, 4317.460317460359],
  [ISongs.vo10406]: [531000, 2497.460317460309],
  [ISongs.vo10407]: [535000, 3234.1950113378743],
  [ISongs.vo10408]: [540000, 3161.76870748302],
  [ISongs.vo10409]: [545000, 1884.6258503401714],
  [ISongs.vo10410]: [548000, 4183.015873015847],
  [ISongs.vo20101]: [554000, 1663.8775510203914],
  [ISongs.vo20102]: [557000, 1067.9365079364516],
  [ISongs.vo20103]: [560000, 1583.7188208616908],
  [ISongs.vo20104]: [563000, 2037.0521541950666],
  [ISongs.vo20105]: [567000, 1140.5442176870793],
  [ISongs.vo20106]: [570000, 1580.7936507936802],
  [ISongs.vo20201]: [573000, 1508.5034013605991],
  [ISongs.vo20202]: [576000, 1861.2925170068593],
  [ISongs.vo20203]: [579000, 2724.353741496543],
  [ISongs.vo20301]: [583000, 1121.7687074829428],
  [ISongs.vo20302]: [586000, 1646.9614512471935],
  [ISongs.vo20303]: [589000, 1281.5192743763646],
  [ISongs.vo20304]: [592000, 1524.9206349205906],
  [ISongs.vo20305]: [595000, 3814.1043083900286],
  [ISongs.vo20306]: [600000, 2248.2993197278347],
  [ISongs.vo20401]: [604000, 1663.628117913845],
  [ISongs.vo20402]: [607000, 1579.0476190476284],
  [ISongs.vo20403]: [610000, 1380.294784580542],
  [ISongs.vo20404]: [613000, 1022.4716553287863],
  [ISongs.vo20405]: [616000, 2756.2131519274544],
  [ISongs.vo20406]: [620000, 1937.9591836734562],
  [ISongs.vo20501]: [623000, 1028.299319727921],
  [ISongs.vo20502]: [626000, 1158.2086167800298],
  [ISongs.vo20503]: [629000, 1362.018140589612],
  [ISongs.vo20504]: [632000, 1105.9637188208171],
  [ISongs.vo20505]: [635000, 1554.1723356009243],
  [ISongs.vo20601]: [638000, 1864.5124716553028],
  [ISongs.vo20602]: [641000, 1878.75283446715],
  [ISongs.vo20603]: [644000, 1273.4693877550853],
  [ISongs.vo20604]: [647000, 1783.1292517006432],
  [ISongs.vo20605]: [650000, 2325.147392290205],
  [ISongs.vo20606]: [654000, 1331.0204081632264],
  [ISongs.vo20701]: [657000, 2017.278911564631],
  [ISongs.vo20702]: [661000, 1400.77097505673],
  [ISongs.vo20703]: [664000, 2828.435374149649],
  [ISongs.vo20704]: [668000, 1692.4716553287453],
  [ISongs.vo20705]: [671000, 2346.4625850340326],
  [ISongs.vo30101]: [675000, 2483.3333333333485],
  [ISongs.vo30102]: [679000, 2297.437641723377],
  [ISongs.vo30103]: [683000, 1580.090702947814],
  [ISongs.vo30104]: [686000, 2174.965986394568],
  [ISongs.vo30201]: [690000, 1176.8934240362796],
  [ISongs.vo30202]: [693000, 1862.5850340135912],
  [ISongs.vo30203]: [696000, 1347.392290249445],
  [ISongs.vo30204]: [699000, 970.2947845804601],
  [ISongs.vo30301]: [701000, 1371.0204081633037],
  [ISongs.vo30302]: [704000, 1549.070294784542],
  [ISongs.vo30303]: [707000, 1443.5600907029311],
  [ISongs.vo30304]: [710000, 1429.1383219954241],
  [ISongs.vo30401]: [713000, 972.9251700680379],
  [ISongs.vo30402]: [715000, 691.0204081632401],
  [ISongs.vo30403]: [717000, 804.9659863945635],
  [ISongs.vo30404]: [719000, 1024.9659863945908],
  [ISongs.vo30501]: [722000, 703.9455782312416],
  [ISongs.vo30502]: [724000, 869.5918367346849],
  [ISongs.vo30503]: [726000, 1445.1700680272097],
  [ISongs.vo30504]: [729000, 813.8775510203686],
  [ISongs.vo30601]: [731000, 987.5963718820913],
  [ISongs.vo30602]: [733000, 1596.5532879818056],
  [ISongs.vo30603]: [736000, 1357.4603174603226],
  [ISongs.vo30604]: [739000, 975.3514739229558],
  [ISongs.vo30701]: [741000, 863.2199546485708],
  [ISongs.vo30702]: [743000, 1736.893424036225],
  [ISongs.vo30703]: [746000, 1344.7619047618673],
  [ISongs.vo30704]: [749000, 1497.7097505668553],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_Base_Loop]: 0.3,
  [ISongs.BGM_Bonus1_Loop]: 0.2,
  [ISongs.BGM_Bonus2_Loop]: 0.2,
  [ISongs.BGM_Bonus3_Loop]: 0.2,
  [ISongs.BGM_Bonus4_Loop]: 0.2,
  [ISongs.BGM_TotalWinBanner]: 0.3,
  [ISongs.SE_BallDrop1]: 0.3,
  [ISongs.SE_BallDrop2]: 0.3,
  [ISongs.SE_BallDrop3]: 0.3,
  [ISongs.SE_BallSpin1_Loop]: 0.3,
  [ISongs.SE_BallSpin2_Loop]: 0.3,
  [ISongs.SE_BallSpin3_Loop]: 0.3,
  [ISongs.SE_BigWin_End]: 0.3,
  [ISongs.SE_BigWin_Loop]: 0.3,
  [ISongs.SE_BonusStop1]: 0.3,
  [ISongs.SE_BonusStop2]: 0.3,
  [ISongs.SE_BonusStop3]: 0.3,
  [ISongs.SE_BonusTrigger]: 0.3,
  [ISongs.SE_ExtraMessage]: 0.3,
  [ISongs.SE_ExtraShutter]: 0.3,
  [ISongs.SE_ExtraWarning]: 0.3,
  [ISongs.SE_Fanfare1]: 0.3,
  [ISongs.SE_Fanfare2]: 0.3,
  [ISongs.SE_StageMessage]: 0.3,
  [ISongs.SE_Win_End]: 0.3,
  [ISongs.SE_Win_Loop]: 0.3,
  [ISongs.SE_Yocho_Phoenix]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.3,
  [ISongs.SFX_UI_Close]: 0.3,
  [ISongs.SFX_UI_General]: 0.3,
  [ISongs.SFX_UI_Hover]: 0.3,
  [ISongs.SFX_UI_MaxBet]: 0.3,
  [ISongs.SFX_UI_MenuOpen]: 0.3,
  [ISongs.SFX_UI_SpinStart]: 0.3,
  [ISongs.SFX_UI_SpinStop]: 0.3,
  [ISongs.vo10000]: 0.9,
  [ISongs.vo10101]: 0.9,
  [ISongs.vo10102]: 0.9,
  [ISongs.vo10103]: 0.9,
  [ISongs.vo10104]: 0.9,
  [ISongs.vo10105]: 0.9,
  [ISongs.vo10106]: 0.9,
  [ISongs.vo10107]: 0.9,
  [ISongs.vo10108]: 0.9,
  [ISongs.vo10109]: 0.9,
  [ISongs.vo10110]: 0.9,
  [ISongs.vo10111]: 0.9,
  [ISongs.vo10112]: 0.9,
  [ISongs.vo10113]: 0.9,
  [ISongs.vo10201]: 0.9,
  [ISongs.vo10202]: 0.9,
  [ISongs.vo10203]: 0.9,
  [ISongs.vo10204]: 0.9,
  [ISongs.vo10205]: 0.9,
  [ISongs.vo10206]: 0.9,
  [ISongs.vo10207]: 0.9,
  [ISongs.vo10208]: 0.9,
  [ISongs.vo10209]: 0.9,
  [ISongs.vo10210]: 0.9,
  [ISongs.vo10211]: 0.9,
  [ISongs.vo10212]: 0.9,
  [ISongs.vo10213]: 0.9,
  [ISongs.vo10214]: 0.9,
  [ISongs.vo10215]: 0.9,
  [ISongs.vo10216]: 0.9,
  [ISongs.vo10301]: 0.9,
  [ISongs.vo10302]: 0.9,
  [ISongs.vo10303]: 0.9,
  [ISongs.vo10304]: 0.9,
  [ISongs.vo10305]: 0.9,
  [ISongs.vo10306]: 0.9,
  [ISongs.vo10307]: 0.9,
  [ISongs.vo10308]: 0.9,
  [ISongs.vo10309]: 0.9,
  [ISongs.vo10310]: 0.9,
  [ISongs.vo10311]: 0.9,
  [ISongs.vo10312]: 0.9,
  [ISongs.vo10313]: 0.9,
  [ISongs.vo10401]: 0.9,
  [ISongs.vo10402]: 0.9,
  [ISongs.vo10403]: 0.9,
  [ISongs.vo10404]: 0.9,
  [ISongs.vo10405]: 0.9,
  [ISongs.vo10406]: 0.9,
  [ISongs.vo10407]: 0.9,
  [ISongs.vo10408]: 0.9,
  [ISongs.vo10409]: 0.9,
  [ISongs.vo10410]: 0.9,
  [ISongs.vo20101]: 0.9,
  [ISongs.vo20102]: 0.9,
  [ISongs.vo20103]: 0.9,
  [ISongs.vo20104]: 0.9,
  [ISongs.vo20105]: 0.9,
  [ISongs.vo20106]: 0.9,
  [ISongs.vo20201]: 0.9,
  [ISongs.vo20202]: 0.9,
  [ISongs.vo20203]: 0.9,
  [ISongs.vo20301]: 0.9,
  [ISongs.vo20302]: 0.9,
  [ISongs.vo20303]: 0.9,
  [ISongs.vo20304]: 0.9,
  [ISongs.vo20305]: 0.9,
  [ISongs.vo20306]: 0.9,
  [ISongs.vo20401]: 0.9,
  [ISongs.vo20402]: 0.9,
  [ISongs.vo20403]: 0.9,
  [ISongs.vo20404]: 0.9,
  [ISongs.vo20405]: 0.9,
  [ISongs.vo20406]: 0.9,
  [ISongs.vo20501]: 0.9,
  [ISongs.vo20502]: 0.9,
  [ISongs.vo20503]: 0.9,
  [ISongs.vo20504]: 0.9,
  [ISongs.vo20505]: 0.9,
  [ISongs.vo20601]: 0.9,
  [ISongs.vo20602]: 0.9,
  [ISongs.vo20603]: 0.9,
  [ISongs.vo20604]: 0.9,
  [ISongs.vo20605]: 0.9,
  [ISongs.vo20606]: 0.9,
  [ISongs.vo20701]: 0.9,
  [ISongs.vo20702]: 0.9,
  [ISongs.vo20703]: 0.9,
  [ISongs.vo20704]: 0.9,
  [ISongs.vo20705]: 0.9,
  [ISongs.vo30101]: 0.9,
  [ISongs.vo30102]: 0.9,
  [ISongs.vo30103]: 0.9,
  [ISongs.vo30104]: 0.9,
  [ISongs.vo30201]: 0.9,
  [ISongs.vo30202]: 0.9,
  [ISongs.vo30203]: 0.9,
  [ISongs.vo30204]: 0.9,
  [ISongs.vo30301]: 0.9,
  [ISongs.vo30302]: 0.9,
  [ISongs.vo30303]: 0.9,
  [ISongs.vo30304]: 0.9,
  [ISongs.vo30401]: 0.9,
  [ISongs.vo30402]: 0.9,
  [ISongs.vo30403]: 0.9,
  [ISongs.vo30404]: 0.9,
  [ISongs.vo30501]: 0.9,
  [ISongs.vo30502]: 0.9,
  [ISongs.vo30503]: 0.9,
  [ISongs.vo30504]: 0.9,
  [ISongs.vo30601]: 0.9,
  [ISongs.vo30602]: 0.9,
  [ISongs.vo30603]: 0.9,
  [ISongs.vo30604]: 0.9,
  [ISongs.vo30701]: 0.9,
  [ISongs.vo30702]: 0.9,
  [ISongs.vo30703]: 0.9,
  [ISongs.vo30704]: 0.9,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
