import { CroonRoundMode } from '../global.d';
import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  specialPayTable: [
    {
      slug: ResourceTypes.symbolABonus,
      awardText: 'infoABonus',
    },
  ],
};

export enum SlotId {
  A = 'A', // 7
  B = 'B', // Non
}

export enum SlotIdH {
  H_1 = 'H_1',
  H_2 = 'H_2',
  H_3 = 'H_3',
  H_4 = 'H_4',
}

export enum SlotIdI {
  I_1 = 'I_1',
  I_2 = 'I_2',
  I_3 = 'I_3',
  I_4 = 'I_4',
}
export enum ReelStopSndType {
  Normal,
  BonusStop1,
  BonusStop2,
  BattleBonusStop,
  HyperBattleBonusStop,
}

export enum CroonStageTypeInfo {
  begin,
  middle,
  end,
  non,
}

export enum EyeControl {
  IDLE = 'idle',
  PLAY = 'play',
}

export type CroonStageTypeInfoType = Record<CroonRoundMode, { stateType: CroonStageTypeInfo[] }>;

// TODO
// export const CroonStageType: CroonStageTypeInfoType = {
//   SP1: {
//     stateType: [
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//     ],
//   },
//   SP3: {
//     stateType: [
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//     ],
//   },
//   SP5: {
//     stateType: [
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//       CroonStageTypeInfo.non,
//     ],
//   },
//   NM3: {
//     stateType: [
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//     ],
//   },
//   NM5: {
//     stateType: [
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//     ],
//   },
//   NM10: {
//     stateType: [
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.begin,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.middle,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//       CroonStageTypeInfo.end,
//     ],
//   },
// };

export const SC_REELS = [0, 2, 4];
export const ANTICIPATION_START_REELID = 3;
export const ANTICIPATION_START_SCCNT = 2;

export const BB_BGM_2 = 6;

export const black = '\u001b[30m';
export const red = '\u001b[31m';
export const green = '\u001b[32m';
export const yellow = '\u001b[33m';
export const blue = '\u001b[34m';
export const magenta = '\u001b[35m';
export const cyan = '\u001b[36m';
export const white = '\u001b[37m';

export const reset = '\u001b[0m';
