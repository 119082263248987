import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, audioSpriteVolume, mappedAudioSprites } from '../../config';
import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import {
  SetCroonMode,
  SetIsExtraOpen,
  setBrokenGame,
  setBrokenGameBgm,
  setCurrentStage,
  setGameMode,
  setIsCroonStateOpening,
  setIsOpenedMessageBanner,
  setIsSuspended,
} from '../../gql/cache';
import { BGM_FADE_OUT_VOLUME, SlotMachineState, eventManager } from '../../slotMachine/config';
import { isCroonBonusMode } from '../../utils';
import Animation from '../animations/animation';
import Tween from '../animations/tween';
import { StateType, croonState } from '../avatarMotion/config';
import { BgSkin } from '../background/config';

type BgmType = Record<BgmSoundTypes, { intro: ISongs | undefined; base: ISongs }>;
export const bgmList: BgmType = {
  regular: { intro: undefined, base: ISongs.BGM_Base_Loop },
  bbStartBefore: { intro: undefined, base: ISongs.BGM_Bonus1_Loop },
  bb1: { intro: undefined, base: ISongs.BGM_Bonus2_Loop },
  bb2: { intro: undefined, base: ISongs.BGM_Bonus3_Loop },
  bb3: { intro: undefined, base: ISongs.BGM_Bonus4_Loop },
};

class BgmControl {
  private bgmListIndex: BgmSoundTypes | undefined;

  private timer: number | undefined;

  private introBgmDelay: Animation | null = null;

  private isOpening: boolean;

  private isFadeOut: boolean;

  constructor() {
    this.bgmListIndex = undefined;
    this.timer = undefined;
    this.isOpening = false;
    this.isFadeOut = false;

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
    eventManager.on(EventTypes.CROON_START, this.croonStart.bind(this));
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  public setIsFadeOut(status: boolean) {
    this.isFadeOut = status;
  }

  private onModeChange(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode } = settings;
    let bgmTitle: BgmSoundTypes | undefined;

    if (isCroonBonusMode(mode)) {
      if (setIsCroonStateOpening()) {
        bgmTitle = BgmSoundTypes.BB_Start_Before;
        this.isOpening = true;
      } else {
        bgmTitle = this.getBonusState(setCurrentStage());
      }
    } else {
      bgmTitle = BgmSoundTypes.BASE;
      this.isFadeOut = false;
    }

    if (bgmTitle === undefined) {
      this.stopBgm();
    } else if (this.bgmListIndex != bgmTitle) {
      this.stopBgm();
      this.playBgm(bgmTitle);
    }
  }

  public setBaseBgm(): void {
    this.bgmListIndex = BgmSoundTypes.BASE;
  }

  private croonStart(): void {
    setIsCroonStateOpening(false);
    this.playBgm();
  }

  private getBonusState(round: number): BgmSoundTypes {
    let rtn: BgmSoundTypes = BgmSoundTypes.BASE;
    switch (croonState[SetCroonMode()]!.state[round]) {
      case StateType.BEGIN:
        rtn = BgmSoundTypes.BB1;
        break;
      case StateType.MIDDLE:
        rtn = BgmSoundTypes.BB2;
        break;
      case StateType.END:
        rtn = BgmSoundTypes.BB3;
        break;
      case StateType.NON:
        rtn = BgmSoundTypes.BASE;
        break;
    }
    return rtn;
  }

  private setBgmIndex(): BgmSoundTypes {
    let bgm: BgmSoundTypes;
    if (isCroonBonusMode(setGameMode())) {
      if (setIsCroonStateOpening()) {
        bgm = BgmSoundTypes.BB_Start_Before;
        this.isOpening = true;
      } else {
        if (this.isOpening) {
          bgm = this.getBonusState(setCurrentStage());
          this.isOpening = false;
        } else {
          if (setBrokenGameBgm()) {
            bgm = this.getBonusState(setCurrentStage());
            setBrokenGameBgm(false);
          } else {
            bgm = this.getBonusState(setCurrentStage() + 1);
          }
        }
      }
    } else {
      bgm = BgmSoundTypes.BASE;
    }
    return bgm;
  }

  public playBgm(bgmListIndex?: BgmSoundTypes): void {
    if (AudioApi.isRestricted) {
      return;
    }
    if (bgmListIndex === this.bgmListIndex && bgmListIndex != undefined) {
      return;
    }
    if (setIsOpenedMessageBanner()) {
      return;
    }

    if (SlotMachine.getInstance() != undefined) {
      const state = SlotMachine.getInstance().state;
      if (
        state === SlotMachineState.JINGLE ||
        state === SlotMachineState.WINNING ||
        state === SlotMachineState.CROON_WINNING
      ) {
        return;
      }
    }
    if (SetIsExtraOpen()) {
      return;
    }

    if (bgmListIndex === undefined) {
      const bgmIndex = this.setBgmIndex();
      if (this.bgmListIndex === bgmIndex) {
        return;
      } else {
        this.stopBgm();
        this.bgmListIndex = bgmIndex;
      }
    } else {
      this.stopBgm();
      this.bgmListIndex = bgmListIndex;
    }
    if (this.bgmListIndex != undefined) {
      if (bgmList[this.bgmListIndex]!.intro === undefined) {
        AudioApi.play({ type: bgmList[this.bgmListIndex]!.base });
        if (this.isFadeOut) {
          this.fadeOutVolume(0, BGM_FADE_OUT_VOLUME);
        }
      } else {
        this.introBgmDelay = Tween.createDelayAnimation(
          mappedAudioSprites[bgmList[this.bgmListIndex]!.intro!]!.duration,
        );
        this.introBgmDelay.addOnComplete(() => {
          if (this.bgmListIndex != undefined && this.bgmListIndex != BgmSoundTypes.BB2) {
            AudioApi.play({
              type: bgmList[this.bgmListIndex]!.base,
              stopPrev: true,
            });
          }
        });
        this.introBgmDelay.addOnSkip(() => {
          if (this.bgmListIndex != undefined) {
            AudioApi.stop({ type: bgmList[this.bgmListIndex]!.base });
          }
        });

        AudioApi.play({
          type: bgmList[this.bgmListIndex]!.intro!,
          stopPrev: true,
        });
        this.introBgmDelay.start();
      }
    }
  }

  public stopBgm(): void {
    if (this.bgmListIndex != undefined) {
      AudioApi.stop({ type: bgmList[this.bgmListIndex]!.base });
    }
  }

  public StopBonusBgmAll(): void {
    const bonusBgmBuf = [BgmSoundTypes.BB_Start_Before, BgmSoundTypes.BB1, BgmSoundTypes.BB2, BgmSoundTypes.BB3];

    bonusBgmBuf.forEach((bgm) => {
      AudioApi.stop({ type: bgmList[bgm].base });
    });
  }

  public fadeInBase(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex]!.base, audioSpriteVolume[bgmList[this.bgmListIndex]!.base]);
    }
  }

  public fadeOutAll(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex]!.base);
    }
  }

  public fadeInVolume(fadeTime: number): void {
    if (this.bgmListIndex != undefined) {
      this.isFadeOut = false;
      this.fadeOutVolume(0, 0);
      AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex]!.base, audioSpriteVolume[bgmList[this.bgmListIndex]!.base]);
    }
  }

  public fadeOutVolume(fadeTime: number, volume: number): void {
    if (this.bgmListIndex != undefined) {
      this.isFadeOut = true;
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex]!.base, volume);
    }
  }

  public handleChangeRestriction(isSound = false): void {
    if (isSound) {
      setBrokenGameBgm(true);
    }
    setIsSuspended(false);
    AudioApi.unSuspend();
    if (setBrokenGame() || isCroonBonusMode(setGameMode())) {
      AudioApi.changeRestriction(false, []);
      eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
      this.playBgm();
    } else {
      this.playBgm();
    }
  }
}

export default new BgmControl();
