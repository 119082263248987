import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();

  const specialIcon = config.specialPayTable;

  return (
    <section>
      <div className={styles['paytable-list']}>
        {specialIcon.map(({ slug, awardText }) => (
          <div key={slug} className={styles['paytable-list__item']}>
            <div className={styles['img']}>
              <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
              <div className={styles['paytable-list__title']}>
                <p
                  className={`${styles['p']} ${styles['symbol-name']}`}
                  dangerouslySetInnerHTML={{
                    __html: t(`infoPayTable_${slug}`),
                  }}
                />
              </div>
            </div>
            <div className={styles['content']}>
              <p
                className={styles['p_1']}
                dangerouslySetInnerHTML={{
                  __html: t(awardText),
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
