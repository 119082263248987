import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isCroonBonusMode } from '../../utils';
import ViewContainer from '../components/container';
import { GAME_TITLE_LOGO_X_POSITION, GAME_TITLE_LOGO_Y_POSITION, Z_INDEX_MINI_MAP, eventManager } from '../config';

class GameTitle extends ViewContainer {
  private titleLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));

  constructor() {
    super();

    this.initGameLogo();

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    this.zIndex = Z_INDEX_MINI_MAP;
  }

  private initGameLogo(): void {
    this.titleLogo.anchor.set(0);
    this.titleLogo.x = GAME_TITLE_LOGO_X_POSITION;
    this.titleLogo.y = GAME_TITLE_LOGO_Y_POSITION;
    this.addChild(this.titleLogo);
  }

  private onModeChange(settings: { mode: GameMode }) {
    if (isCroonBonusMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default GameTitle;
