import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { Z_INDEX_GAME_TITLE, eventManager } from '../config';

class CroonTop extends ViewContainer {
  private croonTop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.cicus));

  constructor() {
    super();

    this.initCroonTop();

    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.on(EventTypes.CROON_TOP_VISIBLE, (visible: boolean) => (this.visible = visible));
    this.zIndex = Z_INDEX_GAME_TITLE;
  }

  private initCroonTop(): void {
    this.croonTop.anchor.set(0, 1);
    this.croonTop.x = -256;
    this.croonTop.y = 1710;

    this.addChild(this.croonTop);
  }

  private onModeChange(_settings: { mode: GameMode }) {}
}

export default CroonTop;
