import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });
  const featuresFreeSpins: string[] = t('FreeSpinDesc', {
    returnObjects: true,
  });

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('FreeSpinTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {featuresFreeSpins.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles['gameRules']}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
