import * as PIXI from 'pixi.js';

import { SLOTS_CONTAINER_WIDTH } from '../config';

export const CLEARED_MESSAGE_POSITION_X = SLOTS_CONTAINER_WIDTH / 2;
export const CLEARED_MESSAGE_POSITION1_Y = 100;
export const CLEARED_MESSAGE_POSITION2_Y = 220;
export const CLEARED_MESSAGE_POSITION3_Y = 340;

export const CLEARED_MESSAGE_DELAY_DURATION = 3000;
export const CLEARED_MESSAGE_FADEOUT_DURATION = 500;

export const clearedMessageStyle = new PIXI.TextStyle({
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: ['#b3ffff', '#2078ff', '#407dd4', '#60ffff', '#2d28ff', '#064fff', '#4053ff', '#00ffff'],
  fillGradientStops: [0.25, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bolder',
  fontSize: 128,
  stroke: '#ffffff',
  strokeThickness: 10,
  lineJoin: 'round',
});
