import type TaggedText from 'pixi-tagged-text';

type TextStyleSet = (typeof TaggedText)['defaultStyles'];

export const introStylesInfo: TextStyleSet = {
  default: {
    strokeThickness: 4,
    dropShadow: true,
    dropShadowAlpha: 0.3,
    dropShadowBlur: 0,
    dropShadowColor: 0x333333,
    fill: 0xffffff,
    fontSize: 25,
    lineHeight: 25,
    wordWrap: true,
    fontWeight: '700',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    align: 'center',
  },
  span: {
    fill: 0xffff19,
  },
};
