import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global.d';
import { setIsMobile } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { MESSAGE_BANNER_MOBILE_SCALE, Z_INDEX_BANNER } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { MESSAGE_WIN_BANNER_TITLE_Y, MESSAGE_WIN_BANNER_WIN_AMOUNT_Y } from './config';
import { totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  private title?: PIXI.Text;

  private totalWin?: PIXI.Text;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    this.zIndex = Z_INDEX_BANNER;

    this.position.x = 600;
    this.position.y = -160;

    if (setIsMobile()) {
      this.scale.set(MESSAGE_BANNER_MOBILE_SCALE);
    }
  }

  public override init(): MessageWinBanner {
    super.init(ResourceTypes.totalwin);

    this.title = this.initTitle(this.props.title);
    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.addChild(this.title, this.totalWin);

    return this;
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.resolution = 1;
    winTitle.anchor.set(0.5, 0);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    updateTextScale(winTitle, this.banner!.width - 150, 250);

    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.resolution = 1;
    winTotal.anchor.set(0.5, 0);
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }

  override updateTransform(): void {
    super.updateTransform();
  }
}
