import { ResourceTypes } from '../resources.d';

export const introContents = [
  {
    img: ResourceTypes.intro01,
    txtKeyBottom: 'introTitle.page1.Bottom',
  },
  {
    img: ResourceTypes.intro02,
    txtKeyBottom: 'introTitle.page2.Bottom',
  },
];
