import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Toast } from '@phoenix7dev/shared-components';

import { EventTypes } from '../../global.d';
import {
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSoundOn,
  setIsSpinInProgress,
  setIsSuspended,
  setProgress,
  setSkipIntroScreen,
  setSlotConfig,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { eventManager } from '../../slotMachine/config';
import { wrap } from '../../utils/helper';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import Clock from '../Clock';
import EventListener from '../EventListener';
import IntroScreen from '../IntroScreen/introScreen';
import Menu from '../MenuButton';
import Spin from '../SpinButton';
import TitleName from '../TitleName/index';

import styles from './gameScreen.module.scss';
import { preTextureTransfer } from './preTextureTransfer';

window.PIXI = PIXI;

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};
const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn, isShowSuspendedToast } = data!;
  const [, setShowContent] = useState(false);
  const isLoading = useReactiveVar(setIsSoundLoading);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    const application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
    });

    preTextureTransfer(application);
    application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    new Animator(application);
    pixiContainerRef.current?.appendChild(application.view);

    const handleResize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      application.renderer.resize(width, height);
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    const resize = (): void => {
      const userAgent = navigator.userAgent;
      // resize fix for Chrome browsers on Ios devices
      if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
        setTimeout(() => {
          handleResize();
        }, 50);
      } else {
        handleResize();
      }
    };

    if (!setSkipIntroScreen()) {
      IntroScreen.initIntroScreen(application);
      eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        SlotMachine.initSlotMachine(
          application,
          setSlotConfig(),
          wrap(setIsSpinInProgress, false),
          wrap(setIsSlotBusy, false),
        );
        handleResize();
      });
    } else {
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      handleResize();
    }
    handleResize();

    window.addEventListener(EventTypes.RESIZE, resize);

    document.addEventListener('visibilitychange', function () {
      eventManager.emit(EventTypes.WINDOW_NOT_ACTIVE);
    });

    return () => window.removeEventListener(EventTypes.RESIZE, resize);
  }, []);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <>
      <div ref={pixiContainerRef} className={`${styles['canvas']} ${progress.wasLoaded ? '' : styles['intro']}`} />
      <EventListener />
      {progress.wasLoaded && (
        <>
          <TitleName />
          <Clock />
        </>
      )}
      <AutoPlaySettingsMenu />
      <Menu />
      <BetSettingsMenu />
      <Spin />
      {progress.wasLoaded && (
        <>
          {AudioApi.isRestricted &&
            !AudioApi.restrictionChangedOnIntroScreen &&
            !AudioApi.hideRestrictionModal &&
            !isShowSuspendedToast &&
            setIsSoundOn() && (
              <Toast
                title={t<string>('soundWarningTitle')}
                text={t<string>('soundWarningText')}
                btnText={t<string>('soundWarningBtn')}
                isLoading={isLoading}
                handleClick={() => {
                  if (!AudioApi.isInitialized) {
                    eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
                  }
                  AudioApi.changeRestriction(
                    false,
                    [],
                    () => setIsSoundLoading(true),
                    () => {
                      setIsShowSoundToast(false);
                      handleChangeRestriction();
                    },
                  );
                }}
              />
            )}
          {isShowSuspendedToast && setIsSoundOn() && (
            <Toast
              title={t<string>('suspendedWarningTitle')}
              text={t<string>('suspendedWarningText')}
              btnText={t<string>('suspendedWarningBtn')}
              handleClick={() => {
                setIsSuspended(false);
                AudioApi.unSuspend();
                handleChangeRestriction();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default GameScreen;
