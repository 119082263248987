import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { CroonRoundMode, EventTypes, GameMode } from '../../global.d';
import {
  SetCroonMode,
  SetIsCroonStageMove,
  SetIsExtraOpen,
  setBrokenGame,
  setCurrentStage,
  setGameMode,
  setPage,
} from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { CheckFinalStage, debugDisplay, isBaseGameMode, isCroonBonusMode } from '../../utils';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  SCROLL_CROON3_DELAY,
  SCROLL_CROON5_DELAY,
  SCROLL_CROON7_DELAY,
  SCROLL_CROON10_DELAY,
  SCROLL_CROON_DOWN_SPEED,
  SCROLL_EXTRA_OPEN_DELAY,
  SCROLL_SPEED,
  Z_INDEX_CROON_ORNAMENT,
  Z_INDEX_CROON_STATE_BG,
  Z_INDEX_CROON_STATE_MAX,
  eventManager,
} from '../config';
import CroonState from '../croonState/croonState';

import {
  ADD_CROON_HIGHT,
  BgSkin,
  CROON_GAME_WIDTH,
  CroonSkin,
  HEIGHT_BASE,
  addStageNum,
  croonStateType,
} from './config';

class Background extends ViewContainer {
  private bgTextures: Record<BgSkin, PIXI.Texture> = {
    default: PIXI.Texture.from(ResourceTypes.backgroundBase),
  };

  private CroonTextures: Record<CroonSkin, PIXI.Texture> = {
    croon1: PIXI.Texture.from(ResourceTypes.bonusgameBase),
    croon2: PIXI.Texture.from(ResourceTypes.bonusgameBase2),
    croon3: PIXI.Texture.from(ResourceTypes.bonusgameBase3),
    croon4: PIXI.Texture.from(ResourceTypes.bonusgameBase4),
    croon5: PIXI.Texture.from(ResourceTypes.bonusgameBase5),
  };

  private croonStatePivot: number[] = [
    HEIGHT_BASE,
    HEIGHT_BASE + ADD_CROON_HIGHT,
    HEIGHT_BASE + ADD_CROON_HIGHT * 2,
    HEIGHT_BASE + ADD_CROON_HIGHT * 3,
    HEIGHT_BASE + ADD_CROON_HIGHT * 4,
    HEIGHT_BASE + ADD_CROON_HIGHT * 5,
    HEIGHT_BASE + ADD_CROON_HIGHT * 6,
    HEIGHT_BASE + ADD_CROON_HIGHT * 7,
    HEIGHT_BASE + ADD_CROON_HIGHT * 8,
    HEIGHT_BASE + ADD_CROON_HIGHT * 9,
  ];

  private bgSprite = new PIXI.Sprite();

  private currentSkin?: BgSkin | CroonSkin;

  private bgSpriteBuf: PIXI.Sprite[] = [];

  private bgOrnamentBuf: PIXI.Sprite[] = [];

  private croonState: CroonState[] = [];

  private index: number;

  private pageEnd: number;

  private shutter: SpineAnimation[] = [];

  private shutterAnim: string | undefined;

  private backgroundScale: number;

  private gameScale: number;

  private windowWidth: number;

  private windowHeight: number;

  private croonScrollAnim?: Tween;

  private rectUpper: PIXI.Graphics;

  private rectLower: PIXI.Graphics;

  constructor(skin: BgSkin = 'default') {
    super();

    this.index = 0;
    this.pageEnd = 0;
    this.backgroundScale = 1;
    this.gameScale = 1;
    this.windowWidth = 1920;
    this.windowHeight = 1040;
    this.bgSprite.anchor.set(0.5);
    this.sortableChildren = true;
    this.rectUpper = new PIXI.Graphics();
    this.rectUpper.beginFill(0x24c1d2);
    this.rectUpper.drawRect(0, 0, 2000, -2000);
    this.rectUpper.position.set(-1000, 0);
    this.rectLower = new PIXI.Graphics();
    this.rectLower.beginFill(0x24c1d2);
    this.rectLower.drawRect(0, 0, 2000, 2000);
    this.rectLower.position.set(-1000, 7500);
    this.addChild(this.rectUpper, this.rectLower, this.bgSprite);

    for (let i = 0; i < 5; i++) {
      this.shutter[i] = this.initShutter();
      this.shutter[i]!.getSpine().zIndex = Z_INDEX_CROON_STATE_MAX - i;
      this.addChild(this.shutter[i]!.spine);
    }

    this.setSkin(skin);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SET_CROON_STATE, this.addCroonState.bind(this));

    eventManager.addListener(EventTypes.SET_BACKGROUND_PIVOT_Y, this.setPivotY.bind(this));
    eventManager.addListener(EventTypes.CROON_STATE_START_SCROLL, this.croonStateStartScroll.bind(this));

    eventManager.addListener(EventTypes.CROON_STATE_NEXT, this.croonNextStateScroll.bind(this));

    eventManager.addListener(EventTypes.CROON_END, this.croonStageDelete.bind(this));

    eventManager.addListener(EventTypes.CROON_EXTRA_OPEN, this.croonExtraOpen.bind(this));

    eventManager.addListener(EventTypes.CROON_BONUS_END, this.shutterIdle.bind(this));

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));

    eventManager.addListener(EventTypes.CROON_SET_STAGE, this.croonSetStage.bind(this));

    this.pageEnd = this.bgSprite.texture.height;

    for (let i = 0; i < 10; i++) {
      this.croonState[i] = new CroonState(0, i);
      this.croonState[this.index]!.visible = false;
    }
  }

  private initShutter(): SpineAnimation {
    const croonShutter = new SpineAnimation({}, PIXI.Loader.shared.resources['shutter']!.spineData!);
    croonShutter.getSpine().pivot.set(0.5, 0.5);
    croonShutter.getSpine().position.set(0, 0);
    croonShutter.setAnimation('idel', false);
    this.shutterAnim = 'idel';
    croonShutter.getSpine().visible = false;
    return croonShutter;
  }

  private shutterIdle(): void {
    this.shutter.forEach((shutter) => {
      shutter.setAnimation('idel', false);
      this.shutterAnim = 'idel';
      shutter.getSpine().visible = false;
    });
  }

  private croonExtraOpen(): void {
    SetIsExtraOpen(true);
    const extraOpenDelay = Tween.createDelayAnimation(500);
    extraOpenDelay.addOnComplete(() => {
      this.shutter.forEach((shutter) => {
        shutter.setAnimation('open', false);
        AudioApi.play({ type: ISongs.SE_ExtraShutter });
        this.shutterAnim = 'open';
        shutter!.addOnComplete(() => {
          if (this.shutterAnim != 'open') {
            return;
          }
          this.shutterAnim = 'idel';
          this.shutterIdle();
        });
      });
    });
    extraOpenDelay.start();

    const extraOpenDummyScrollDelay = Tween.createDelayAnimation(1500);
    extraOpenDummyScrollDelay.addOnComplete(() => {
      this.extraOpenDummyScroll();
    });
    extraOpenDummyScrollDelay.start();
  }

  public setSkin(skinName: BgSkin) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;
    this.bgSprite.texture = this.bgTextures[skinName];
  }

  private croonStageDelete(): void {
    for (let index = 0; index < this.index; index++) {
      this.croonState[index]!.visible = false;
      this.removeChild(this.bgSpriteBuf[index]!);
      this.removeChild(this.bgOrnamentBuf[index]!);
    }
    this.index = 0;
    this.pivot.y = 0;
    setPage(0);
  }

  private onChangeMode(_settings: { mode: GameMode; background?: BgSkin }) {
    this.setSkin('default');

    this.setScale();
  }

  private resize(width: number, height: number): void {
    debugDisplay('resize width', width, 'height', height);

    this.windowWidth = width;
    this.windowHeight = height;

    this.x = width / 2;
    this.y = height / 2;

    const tex = this.bgSprite.texture;

    const bgAspectRatio = tex.width / tex.height;
    const aspectRatio = width / height;

    debugDisplay('bgAspectRatio > aspectRatio', bgAspectRatio > aspectRatio, 'this.index', this.index);
    let scale = 0;

    if (bgAspectRatio > aspectRatio) {
      scale = height / tex.height;
      if (this.index > 0) {
        for (let i = 0; i < this.index; i++) {
          this.scale.set(scale);
        }
      }
    } else {
      scale = width / tex.width;
      if (this.index > 0) {
        for (let i = 0; i < this.index; i++) {
          this.scale.set(scale);
        }
      } else if (this.index === 0) {
        this.pageEnd = this.bgSprite.height;
      }
    }
    if (isBaseGameMode(setGameMode())) {
      this.scale.set(scale);
    }

    this.backgroundScale = scale;
    eventManager.emit(EventTypes.RESIZE_BACK_GROUND, scale);
  }

  private addCroonState(addState: number): void {
    // debugDisplay('scale', SlotMachine.getInstance().gameView.scale.x);

    debugDisplay('SetCroonMode()', SetCroonMode(), 'addStageNum', addStageNum[SetCroonMode()]);

    debugDisplay('追加するステージ　addState', addState);

    for (let index = 0; index < addStageNum[SetCroonMode()]!.addStage; index++) {
      this.addState(croonStateType[index]!);
    }

    if (!setBrokenGame()) {
      setPage(addState + 1);
    }
    debugDisplay(
      '追加するステージ　setPage',
      setPage(),
      'this.bgSpriteBuf[0]!.texture.height',
      this.bgSpriteBuf[0]!.texture.height,
      'this.bgSprite.scale.x',
      this.bgSprite.scale.x,
    );

    this.pivot.y = ((setPage() - 2) * this.bgSpriteBuf[0]!.texture.height + HEIGHT_BASE) * this.bgSprite.scale.x;

    debugDisplay('スクロール開始前　this.pivot.y', this.pivot.y);

    // TODO shutter
    debugDisplay('----SetCroonMode()', SetCroonMode());

    const mode = SetCroonMode();
    if (mode == CroonRoundMode.NM3 || mode == CroonRoundMode.NM5) {
      this.shutter.forEach((shutter) => {
        shutter.getSpine().visible = true;
      });
      if (mode == CroonRoundMode.NM3) {
        this.shutter.forEach((shutter, i) => {
          shutter.getSpine().position.y = 2700 + 270 + i * 600;
        });
      } else if (mode == CroonRoundMode.NM5) {
        this.shutter.forEach((shutter, i) => {
          shutter.getSpine().position.y = 3902 + 270 + i * 600;
        });
      }
    }
  }

  private setPivotY(pivotY: number): void {
    this.croonScrollAnim?.skip();
    this.pivot.y = pivotY;
  }

  private getCroonScrollAnimation(
    duration: number,
    begin: number,
    target: number,
    easing: (x: number) => number,
  ): Tween {
    const animation = new Tween({
      object: this.pivot,
      duration: duration,
      property: TweenProperties.Y,
      propertyBeginValue: begin,
      target: target,
      easing: easing,
    });
    return animation;
  }

  private easeInOutSine(x: number): number {
    return -(Math.cos(Math.PI * x) - 1) / 2;
  }

  private easeOutSine(x: number): number {
    return Math.sin((x * Math.PI) / 2);
  }

  private extraOpenDummyScroll(): void {
    const mode = SetCroonMode();

    if (mode === CroonRoundMode.NM3) {
      if (setCurrentStage()) {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON5_DELAY + SCROLL_EXTRA_OPEN_DELAY,
          this.croonStatePivot[2]!,
          this.croonStatePivot[9]!,
          this.easeInOutSine,
        );
      } else {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON5_DELAY + SCROLL_EXTRA_OPEN_DELAY,
          this.croonStatePivot[3]!,
          this.croonStatePivot[9]!,
          this.easeInOutSine,
        );
      }
      this.croonScrollAnim.start();
    } else if (mode === CroonRoundMode.NM5) {
      if (setCurrentStage()) {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON5_DELAY + SCROLL_EXTRA_OPEN_DELAY,
          this.croonStatePivot[4]!,
          this.croonStatePivot[9]!,
          this.easeInOutSine,
        );
      } else {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON5_DELAY + SCROLL_EXTRA_OPEN_DELAY,
          this.croonStatePivot[5]!,
          this.croonStatePivot[9]!,
          this.easeInOutSine,
        );
      }
      this.croonScrollAnim.start();
    }

    if (mode === CroonRoundMode.NM3 || mode === CroonRoundMode.NM5) {
      this.croonScrollAnim!.addOnSkip(() => {
        this.pivot.y = this.croonStatePivot[9]!;
      });
      this.croonScrollAnim!.start();
    }
  }

  private croonStateStartScroll(): void {
    SetIsCroonStageMove(true);
    const mode = SetCroonMode();
    let miniCroonNumber = 0;

    if (mode === CroonRoundMode.NM3 || mode === CroonRoundMode.SP3) {
      if (setCurrentStage()) {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON7_DELAY,
          this.pivot.y,
          this.croonStatePivot[3]!,
          this.easeInOutSine,
        );
        miniCroonNumber = 9;
      } else {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON3_DELAY,
          this.croonStatePivot[2]!,
          HEIGHT_BASE,
          this.easeInOutSine,
        );
        miniCroonNumber = 2;
      }
    } else if (mode === CroonRoundMode.NM5 || mode === CroonRoundMode.SP5) {
      if (setCurrentStage()) {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON5_DELAY,
          this.pivot.y,
          this.croonStatePivot[5]!,
          this.easeInOutSine,
        );
        miniCroonNumber = 9;
      } else {
        this.croonScrollAnim?.skip();
        this.croonScrollAnim = this.getCroonScrollAnimation(
          SCROLL_CROON5_DELAY,
          this.croonStatePivot[4]!,
          HEIGHT_BASE,
          this.easeInOutSine,
        );
        miniCroonNumber = 4;
      }
    } else if (mode === CroonRoundMode.NM10) {
      this.croonScrollAnim?.skip();
      this.croonScrollAnim = this.getCroonScrollAnimation(
        SCROLL_CROON10_DELAY,
        this.croonStatePivot[9]!,
        HEIGHT_BASE,
        this.easeInOutSine,
      );
      miniCroonNumber = 9;
    } else {
      this.croonScrollAnim?.skip();
      this.croonScrollAnim = this.getCroonScrollAnimation(0, HEIGHT_BASE, HEIGHT_BASE, this.easeInOutSine);
      miniCroonNumber = 0;
    }
    this.croonScrollAnim.start();

    const delay = Tween.createDelayAnimation(1);
    delay.addOnComplete(() => {
      if (this.pivot.y <= this.croonStatePivot[miniCroonNumber]!) {
        eventManager.emit(EventTypes.MINIMAP_CURRENT_SET, miniCroonNumber + 1);
        miniCroonNumber -= 1;
      }
      if (this.pivot.y === this.pageEnd) {
        setTimeout(() => {
          this.pivot.y -= SCROLL_SPEED;
          this.croonStateStartScroll();
        }, 2000);
      } else if (this.pivot.y != HEIGHT_BASE && this.pivot.y === this.croonStatePivot[setCurrentStage()]) {
        eventManager.emit(EventTypes.CROON_EXTRA_CHALLENGE);
        eventManager.emit(EventTypes.CROON_START, setCurrentStage());
        eventManager.emit(EventTypes.CROON_STAGE_SPIN);
        SetIsCroonStageMove(false);
        delay.skip();
      } else if (this.pivot.y > HEIGHT_BASE * this.bgSprite.scale.x) {
        delay.start();
      } else {
        this.pageEnd = HEIGHT_BASE * this.bgSprite.scale.x;
        eventManager.emit(EventTypes.CROON_START, 0);
        eventManager.emit(EventTypes.CROON_STAGE_SPIN);
        SetIsCroonStageMove(false);
        this.bgSprite.anchor.set(0.5);
        delay.skip();
      }
    });
    delay.start();
  }

  private croonNextStateScroll(nextStage: number): void {
    const targetPivotY = HEIGHT_BASE * this.bgSprite.scale.x + 600 * this.bgSprite.scale.x * (nextStage + 1);

    this.croonScrollAnim?.skip();
    this.croonScrollAnim = this.getCroonScrollAnimation(
      SCROLL_CROON_DOWN_SPEED,
      this.pivot.y,
      targetPivotY,
      this.easeOutSine,
    );
    this.croonScrollAnim.addOnStart(() => {
      SetIsCroonStageMove(true);
    });
    this.croonScrollAnim.addOnComplete(() => {
      SetIsCroonStageMove(false);
      eventManager.emit(EventTypes.MINI_MAP_SET_CURRENT, nextStage + 1 + 1);
      setCurrentStage(nextStage + 1);
    });
    this.croonScrollAnim.start();
  }

  private croonSetStage(stage: number): void {
    // debugDisplay('croonSetStage stage ', stage);
    this.pivot.y = HEIGHT_BASE * this.bgSprite.scale.x + 600 * this.bgSprite.scale.x * stage;
  }

  private addState(skinName: CroonSkin) {
    // debugDisplay(
    //   'this.bgSprite.scale.x',
    //   this.bgSprite.scale.x,
    //   'this.index',
    //   this.index,
    // );
    this.bgSpriteBuf[this.index] = new PIXI.Sprite();
    this.bgOrnamentBuf[this.index] = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bonusgameOrnament));

    this.currentSkin = skinName;

    this.bgSpriteBuf[this.index]!.texture = this.CroonTextures[skinName];
    this.bgSpriteBuf[this.index]!.anchor.set(0.5, 0);
    this.bgOrnamentBuf[this.index]!.scale.set(this.bgSprite.scale.x);
    this.bgOrnamentBuf[this.index]!.anchor.set(0.5, 0);

    if (this.index === 0) {
      this.pageEnd = this.bgSprite.height / 2;
    }

    this.bgSpriteBuf[this.index]!.position.y = this.pageEnd;
    this.bgOrnamentBuf[this.index]!.position.y = this.pageEnd - 50;
    this.pageEnd = this.pageEnd + this.bgSpriteBuf[this.index]!.texture.height * this.bgSpriteBuf[this.index]!.scale.x;

    this.croonState[this.index]!.visible = true;
    this.croonState[this.index]!.setPositionY(
      this.bgSpriteBuf[this.index]!.position.y -
        440 * this.bgSpriteBuf[this.index]!.scale.x +
        600 * this.bgSpriteBuf[this.index]!.scale.x,
    );
    this.croonState[this.index]!.setOdds(this.index);

    this.croonState[this.index]!.scale.set(this.bgSprite.scale.x);

    this.addChild(this.bgSpriteBuf[this.index]!, this.bgOrnamentBuf[this.index]!, this.croonState[this.index]!);
    this.bgSpriteBuf[this.index]!.zIndex = Z_INDEX_CROON_STATE_BG - this.index;
    this.bgOrnamentBuf[this.index]!.zIndex = Z_INDEX_CROON_ORNAMENT - this.index;
    this.croonState[this.index]!.zIndex = Z_INDEX_CROON_STATE_MAX - this.index;

    this.index += 1;
    if (CheckFinalStage(this.index)) {
      for (let i = 0; i < 2; i++) {
        this.bgSpriteBuf[this.index] = new PIXI.Sprite();
        this.bgSpriteBuf[this.index]!.texture = this.CroonTextures[skinName];
        this.bgSpriteBuf[this.index]!.scale.set(this.bgSprite.scale.x);
        this.bgSpriteBuf[this.index]!.anchor.set(0.5, 0);
        this.bgSpriteBuf[this.index]!.position.y = this.pageEnd;
        this.pageEnd =
          this.pageEnd + this.bgSpriteBuf[this.index]!.texture.height * this.bgSpriteBuf[this.index]!.scale.x;
        this.addChild(this.bgSpriteBuf[this.index]!);
      }
    }
  }

  private resizeGameContainer(width: number, _height: number, _x: number, _y: number, scale: number): void {
    this.gameScale = scale;

    debugDisplay('width', width, 'scale', scale);

    this.gameScale = width / CROON_GAME_WIDTH;

    this.setScale();
  }

  private setScale(): void {
    if (isCroonBonusMode(setGameMode()) && this.windowWidth < this.windowHeight) {
      this.scale.set(this.gameScale);
    } else {
      this.scale.set(this.backgroundScale);
    }
  }
}

export default Background;
