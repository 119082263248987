import { SlotId } from '../config';
import { CroonRoundMode, GameMode, bonusIds, reelSets } from '../global.d';
import {
  SetAvatarStatusControl,
  SetAvatarTension,
  SetCroonMode,
  setBetAmount,
  setCoinAmount,
  setCurrentBonus,
  setCurrentStage,
  setFreeSpinsTotalWin,
  setSlotConfig,
} from '../gql/cache';
import SpineAnimation from '../slotMachine/animations/spine';
import { StateType, croonState } from '../slotMachine/avatarMotion/config';
import { StatusControlFlg } from '../slotMachine/avatarTalk/config';
import { Icon } from '../slotMachine/d';

import { debugDisplay, nextTick } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  const { lineSet } = setSlotConfig();
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (lineSet.coinAmountMultiplier || 1)) / 100;
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  throw error;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const destroySpine = (spine: SpineAnimation): void => {
  nextTick(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy({ children: true });
    }
  });
};

export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};

export const isCroonBonusMode = (mode: GameMode): boolean => {
  return mode === GameMode.CROON_BONUS;
};

export const isScatter = (_slotId: SlotId): boolean => {
  return false;
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }

  return GameMode.REGULAR;
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(bonusIds)) {
    if (id === bonusId) {
      return Number(gameMode) as GameMode;
    }
  }

  return GameMode.REGULAR;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const getWinCoin = (): number => {
  return (
    setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.coinReward *
    setCoinAmount() *
    setSlotConfig().lineSet.coinAmountMultiplier
  );
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
  isCroonStageMove,
  isCountUp,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
  isCroonStageMove: boolean;
  isCountUp: boolean;
}): boolean => {
  if (isInTransition) {
    return false;
  }

  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }

  if (isCountUp) {
    return true;
  }

  if (isCroonBonusMode(gameMode) && !isSlotBusy) {
    if (isOpenedMessageBanner) {
      return true;
    }
    return false;
  }

  if (isCroonBonusMode(gameMode) && isSlotBusy) {
    if (isOpenedMessageBanner) {
      return true;
    }
  }

  if (isCroonStageMove) {
    return false;
  }

  if (isCroonBonusMode(gameMode)) {
    if (isSlotStopped) {
      return false;
    }
  } else {
    if (isSpinInProgress && isSlotStopped) {
      return false;
    }
  }

  return true;
};

export const chkEndStage = (mode: CroonRoundMode, stage: number): boolean => {
  let rtn = false;
  if (mode === CroonRoundMode.SP1 && stage === 0) {
    rtn = true;
  } else if (mode === CroonRoundMode.SP3 && stage === 2) {
    rtn = true;
  } else if (mode === CroonRoundMode.SP5 && stage === 4) {
    rtn = true;
  } else if (mode === CroonRoundMode.NM3 && stage === 2) {
    rtn = true;
  } else if (mode === CroonRoundMode.NM3 && stage === 9) {
    rtn = true;
  } else if (mode === CroonRoundMode.NM5 && stage === 4) {
    rtn = true;
  } else if (mode === CroonRoundMode.NM5 && stage === 9) {
    rtn = true;
  } else if (mode === CroonRoundMode.NM10 && stage === 9) {
    rtn = true;
  }
  return rtn;
};

export const setAvatarTension = (_tension: number): void => {
  const currentTension = SetAvatarTension();
  let nextTension: number;

  // Can't fix it in slotMachine/index.ts, so we'll deal with it here
  const tension = getAvatarTension();

  debugDisplay('helper.アバターテンション更新前 currentTension', currentTension, 'tension', tension);

  if (currentTension + tension > 3) {
    nextTension = 3;
  } else if (currentTension + tension < 1) {
    nextTension = 1;
  } else {
    nextTension = currentTension + tension;
  }
  debugDisplay('helper.アバターテンション更新前', SetAvatarTension());
  SetAvatarTension(nextTension);
  debugDisplay('helper.アバターテンション更新後', SetAvatarTension());
};

export const getAvatarTension = (): number => {
  const winAmount = setFreeSpinsTotalWin() / setBetAmount();
  const stage = croonState[SetCroonMode()]!.state[setCurrentStage()];

  SetAvatarStatusControl(StatusControlFlg.NORMAL);

  if (stage === StateType.END) {
    if (setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position === 0) {
      debugDisplay('ボーナスで最高配当ゲットだぜ');
      SetAvatarStatusControl(StatusControlFlg.GET);
    }
    if (setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.position != 0) {
      debugDisplay('終盤後でドボンしてテンション下げ');
      SetAvatarStatusControl(StatusControlFlg.ZANNEN);
    }
  } else {
    if (winAmount >= 50) {
      debugDisplay('ボーナスで高配当ゲット');
      SetAvatarStatusControl(StatusControlFlg.GET);
    }
  }

  // 7.2.2 テンション変化条件
  let tension = 0;
  if (
    winAmount >= 10000 ||
    (SetCroonMode() === CroonRoundMode.NM3 && winAmount >= 100) ||
    (SetCroonMode() === CroonRoundMode.NM5 && winAmount >= 1000)
  ) {
    // 最高配当（10000倍, もしくはNM3の100倍, NM5の1000倍）を獲得した +2
    debugDisplay('1.アバターテンション更新前', SetAvatarTension());
    tension = 2;
    debugDisplay('1.アバターテンション更新後', SetAvatarTension());
  } else if (stage === StateType.END) {
    // 終盤ステージで終了 -2
    debugDisplay('2.アバターテンション更新前', SetAvatarTension());
    tension = -2;
    debugDisplay('2.アバターテンション更新後', SetAvatarTension());
  } else if (winAmount >= 20) {
    // Betの20倍以上獲得した +2
    debugDisplay('3.アバターテンション更新前', SetAvatarTension());
    tension = 2;
    debugDisplay('3.アバターテンション更新後', SetAvatarTension());
  } else if (winAmount >= 10) {
    // Betの10倍以上獲得した +1
    debugDisplay('4.アバターテンション更新前', SetAvatarTension());
    tension = 1;
    debugDisplay('4.アバターテンション更新後', SetAvatarTension());
  } else {
    // 上記該当せず 変化無し
  }
  return tension;
};

export const urlSearchParams = new URLSearchParams(window.location.search);

export const isDemo = urlSearchParams.has('isDemo');
