import * as PIXI from 'pixi.js';
import { ITextStyle } from 'pixi.js';

const winMessage = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 120,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 8,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 100,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 8,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

export const miniPayTableNumberStyle: Partial<ITextStyle> = {
  fontSize: 26,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xffffff',
  trim: true,
};

export const miniPayTableTextStyle: Partial<ITextStyle> = {
  fontSize: 26,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xf6e87e',
  trim: true,
};

export const bottomContainerTextStyle: Partial<ITextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#f6e87e',
  fontWeight: 'bold',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
};
// BuyFeature Button Text
export const FEATURE_BTN_TEXT_LANDSCAPE_FIT_TO_WIDTH = 120;
export const FEATURE_BTN_TEXT_PORTRAIT_FIT_TO_WIDTH = 250;
export const FEATURE_BTN_TEXT_X_OFFSET = -172;
export const FEATURE_BTN_TEXT_Y_OFFSET = 248;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#000000',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  stroke: '#000000',
  strokeThickness: 4,
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#505050',
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 31,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  stroke: '#dddddd',
  strokeThickness: 2,
});
export const buyFeatureClickTextStyle = buyFeatureTextStyle;
export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 1,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 40,
  lineJoin: 'round',
  fill: ['#101010', '#383838', '#727272', '#383838', '#101010'],
  stroke: '#000000',
  strokeThickness: 2,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };

export const bigWinStyles = new PIXI.TextStyle(winMessage);
export const megaWinStyles = new PIXI.TextStyle(winMessage);
export const greatWinStyles = new PIXI.TextStyle(winMessage);
export const epicWinStyles = new PIXI.TextStyle(winMessage);
export const winValueStyles = new PIXI.TextStyle(winCounterStyle);
