import * as PIXI from 'pixi.js';

import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import { SetIsExtraOpen, setCurrentStage, setIsInTransition } from '../../gql/cache';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { isCroonBonusMode, setAvatarTension, updateCoinValueAfterBonuses } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { FREE_SPINS_FADE_IN_DURATION, FREE_SPINS_FADE_OUT_DURATION, eventManager } from '../config';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  constructor() {
    super();
    this.interactive = false;
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.startModeChangeFade.bind(this));

    eventManager.addListener(EventTypes.CROON_EXTRA_OPENED, this.extraOpenedFade.bind(this));
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
  }): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, settings.fadeOutDuration, 0);
    fadeOut.addOnComplete(() => {
      if (!isCroonBonusMode(settings.mode)) {
        eventManager.emit(EventTypes.CROON_END);
        eventManager.emit(EventTypes.CROON_TOP_VISIBLE, true);
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
        eventManager.emit(EventTypes.CROON_BONUS_END);

        if (settings.mode === GameMode.REGULAR) {
          setAvatarTension(0);
          updateCoinValueAfterBonuses();
        }

        setCurrentStage(0);
      }
      setIsInTransition(false);
    });
    const fadeIn = this.getFadeAnimation(0, settings.fadeInDuration, 1);
    fadeIn.addOnStart(() => {
      eventManager.emit(EventTypes.CHANGE_MODE, settings);
    });
    fadeIn.addOnComplete(() => {
      this.interactive = false;
    });
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }

  private extraOpenedFade(): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, FREE_SPINS_FADE_OUT_DURATION, 0);
    fadeOut.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_BACKGROUND_PIVOT_Y, 6050);
      eventManager.emit(EventTypes.MINIMAP_EXTRA_STAGE);
      eventManager.emit(EventTypes.MINIMAP_CURRENT_SET, 10);
      setIsInTransition(false);
    });
    const fadeIn = this.getFadeAnimation(0, FREE_SPINS_FADE_IN_DURATION, 1);
    fadeIn.addOnComplete(() => {
      this.interactive = false;
      SetIsExtraOpen(false);
      eventManager.emit(EventTypes.CROON_STATE_START_SCROLL);
      BgmControl.playBgm(BgmSoundTypes.BB_Start_Before);
    });
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }

  private getFadeAnimation(alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}

export default FadeArea;
