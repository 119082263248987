// MotionName
export enum MotionName {
  TAIKI1 = 101, // 待機1
  TAIKI2 = 102, // 待機2
  TAIKI3 = 103, // 待機3
  TAIKI4 = 104, // 待機4
  TAIKI5 = 105, // 待機5
  MIMAMORI1 = 201, // 見守り1
  MIMAMORI2 = 202, // 見守り2
  MIMAMORI3 = 203, // 見守り3
  MIMAMORI4 = 204, // 見守り4
  MIMAMORI5 = 205, // 見守り5
  MIMAMORI6 = 206, // 見守り6
  MIMAMORI7 = 207, // 見守り7
  REACTION1 = 301, // リアクション1
  REACTION2 = 302, // リアクション2
  REACTION3 = 303, // リアクション3
  REACTION4 = 304, // リアクション4
  REACTION5 = 305, // リアクション5
  REACTION6 = 306, // リアクション6
  REACTION7 = 307, // リアクション7
}

export const MotionTaiki: { [key: number]: MotionName } = [
  MotionName.TAIKI1,
  MotionName.TAIKI2,
  MotionName.TAIKI3,
  MotionName.TAIKI4,
  MotionName.TAIKI5,
];

export const MotionMimamori: { [key: number]: MotionName } = [
  MotionName.MIMAMORI1,
  MotionName.MIMAMORI2,
  MotionName.MIMAMORI3,
  MotionName.MIMAMORI4,
  MotionName.MIMAMORI5,
  MotionName.MIMAMORI6,
  MotionName.MIMAMORI7,
];

export const MotionReaction: { [key: number]: MotionName } = [
  MotionName.REACTION1,
  MotionName.REACTION2,
  MotionName.REACTION3,
  MotionName.REACTION4,
  MotionName.REACTION5,
  MotionName.REACTION6,
  MotionName.REACTION7,
];

// Eye Control
export enum EyeControl {
  NON = 'non',
  FREE = 'free',
  REEL = 'reel',
}

type TypeMotionList = Record<number, { motion: string[]; isLoop: boolean; eye: EyeControl }>;

//7.5.1 モーションリスト
export const motionList: TypeMotionList = {
  101: {
    motion: ['idle_01_a', 'idle_01_b', 'idle_01_a', 'idle_01_b'],
    isLoop: true,
    eye: EyeControl.FREE,
  },
  102: {
    motion: ['idle_02_a', 'idle_02_b', 'idle_02_a', 'idle_02_b'],
    isLoop: true,
    eye: EyeControl.FREE,
  },
  103: {
    motion: ['idle_03_a', 'idle_03_b', 'idle_03_a', 'idle_03_b'],
    isLoop: true,
    eye: EyeControl.FREE,
  },
  104: {
    motion: ['idle_04_a', 'idle_04_b', 'idle_04_a', 'idle_04_b'],
    isLoop: true,
    eye: EyeControl.FREE,
  },
  105: {
    motion: ['idle_01_c'],
    isLoop: true,
    eye: EyeControl.FREE,
  },
  201: {
    motion: ['watching_01_a', 'watching_01_b', 'watching_01_a', 'watching_01_b'],
    isLoop: true,
    eye: EyeControl.FREE,
  },
  202: {
    motion: ['watching_02_a', 'watching_02_b', 'watching_02_a', 'watching_02_b'],
    isLoop: true,
    eye: EyeControl.REEL,
  },
  203: {
    motion: ['watching_03_a', 'watching_03_b', 'watching_03_a', 'watching_03_b'],
    isLoop: true,
    eye: EyeControl.REEL,
  },
  204: {
    motion: ['watching_04_a', 'watching_04_b', 'watching_04_a', 'watching_04_b'],
    isLoop: true,
    eye: EyeControl.REEL,
  },
  205: {
    motion: ['watching_05_a', 'watching_05_b', 'watching_05_a', 'watching_05_b'],
    isLoop: true,
    eye: EyeControl.REEL,
  },
  206: {
    motion: ['watching_06_a', 'watching_06_b', 'watching_06_a', 'watching_06_b'],
    isLoop: true,
    eye: EyeControl.REEL,
  },
  207: {
    motion: ['watching_07_a', 'watching_07_b', 'watching_07_a', 'watching_07_b'],
    isLoop: true,
    eye: EyeControl.REEL,
  },
  301: {
    motion: ['reaction_01', 'reaction_01'],
    isLoop: false,
    eye: EyeControl.NON,
  },
  302: {
    motion: ['reaction_02', 'reaction_02', 'reaction_02'],
    isLoop: false,
    eye: EyeControl.NON,
  },
  303: {
    motion: ['reaction_03', 'reaction_03', 'reaction_03'],
    isLoop: false,
    eye: EyeControl.NON,
  },
  304: {
    motion: ['reaction_04', 'reaction_04', 'reaction_04'],
    isLoop: false,
    eye: EyeControl.NON,
  },
  305: {
    motion: ['reaction_05', 'reaction_05', 'reaction_05'],
    isLoop: false,
    eye: EyeControl.NON,
  },
  306: {
    motion: ['reaction_06', 'reaction_06', 'reaction_06'],
    isLoop: false,
    eye: EyeControl.NON,
  },
  307: {
    motion: ['reaction_07', 'reaction_07', 'reaction_07'],
    isLoop: false,
    eye: EyeControl.NON,
  },
};

// 序盤、中盤、終盤
export enum StateType {
  BEGIN = 'Begin',
  MIDDLE = 'Middle',
  END = 'End',
  NON = 'Non',
}

// CROON STATE TYPE
export enum CroonStateType {
  SP1 = 'SP1',
  SP3 = 'SP3',
  SP5 = 'SP5',
  NM10 = 'NM10',
  NM3 = 'NM3',
  NM5 = 'NM5',
}

type TypeCroonState = Record<CroonStateType, { state: StateType[] }>;

// 7.2 クルーンボーナス中の序盤・中盤・終盤の定義
export const croonState: TypeCroonState = {
  SP1: {
    state: [
      StateType.END,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
    ],
  },
  SP3: {
    state: [
      StateType.END,
      StateType.END,
      StateType.END,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
    ],
  },
  SP5: {
    state: [
      StateType.MIDDLE,
      StateType.MIDDLE,
      StateType.END,
      StateType.END,
      StateType.END,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
      StateType.NON,
    ],
  },
  NM10: {
    state: [
      StateType.BEGIN,
      StateType.BEGIN,
      StateType.BEGIN,
      StateType.MIDDLE,
      StateType.MIDDLE,
      StateType.MIDDLE,
      StateType.MIDDLE,
      StateType.END,
      StateType.END,
      StateType.END,
    ],
  },
  NM3: {
    state: [
      StateType.BEGIN,
      StateType.MIDDLE,
      StateType.END,
      StateType.BEGIN,
      StateType.BEGIN,
      StateType.MIDDLE,
      StateType.MIDDLE,
      StateType.END,
      StateType.END,
      StateType.END,
    ],
  },
  NM5: {
    state: [
      StateType.BEGIN,
      StateType.BEGIN,
      StateType.MIDDLE,
      StateType.END,
      StateType.END,
      StateType.MIDDLE,
      StateType.MIDDLE,
      StateType.END,
      StateType.END,
      StateType.END,
    ],
  },
};

type TypeCroonFinalStage = Record<CroonStateType, { finalStage: number }>;

// クルーンボーナスの最終ステージ定義
export const croonFinalStage: TypeCroonFinalStage = {
  SP1: {
    finalStage: 0,
  },
  SP3: {
    finalStage: 2,
  },
  SP5: {
    finalStage: 4,
  },
  NM3: {
    finalStage: 2,
  },
  NM5: {
    finalStage: 4,
  },
  NM10: {
    finalStage: 9,
  },
};

export enum EyePosition {
  Center = 'Center',
  Reel = 'Reel',
  Random = 'Random',
  UiRight = 'UiRight',
  UiRightUpper = 'UiRightUpper',
  UiLeft = 'UiLeft',
  UiLeftUpper = 'UiLeftUpper',
}

export interface PositionInfo {
  x: number;
  y: number;
}

type EyePositionTypeType = Record<EyePosition, { pos: PositionInfo[] }>;

export const EyePositionInfo: EyePositionTypeType = {
  Center: {
    pos: [{ x: 0, y: 1143 }],
  },
  Reel: { pos: [{ x: 4000, y: 1200 }] },
  Random: {
    pos: [
      { x: 4000, y: 4000 },
      { x: 4000, y: 1200 },
      { x: 4000, y: -750 },
      { x: 0, y: 4000 },
      { x: 0, y: 1200 },
      { x: 0, y: -750 },
      { x: -3000, y: 4000 },
      { x: -3000, y: 1200 },
      { x: -3000, y: -750 },
    ],
  },
  UiRight: { pos: [{ x: 4000, y: 912 }] },
  UiRightUpper: { pos: [{ x: 4000, y: 2912 }] },
  UiLeft: { pos: [{ x: -1000, y: 912 }] },
  UiLeftUpper: { pos: [{ x: -1000, y: 2912 }] },
};

export const gazeMoveDelay: { [key: number]: number } = [15, 20, 25, 30];
