import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes, IAuthInput } from '../../global.d';
import {
  setCurrentBonus,
  setIsTimeoutErrorMessage,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setSkipIntroScreen,
  setStressful,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { parseQuery, queryParams } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const progress = useReactiveVar(setProgress);
  const { i18n } = useTranslation();

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  const handleIdle = useCallback(
    (state: SlotMachineState, isEnd = false) => {
      if (state !== SlotMachineState.IDLE) return;

      if (isEnd) {
        setIsTimeoutErrorMessage(true);
        setStressful({
          show: true,
          type: 'none',
          message: i18n.t('replayBetMessage'),
        });
      }
    },
    [i18n],
  );

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setSkipIntroScreen(true);
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    eventManager.on(EventTypes.POST_RENDER, () => {
      if (setReplayBet()) {
        const replayFreeSpins = setReplayFreeSpinBets();
        if (replayFreeSpins.length) {
          const triggerSpin = replayFreeSpins.shift();
          setReplayBet(triggerSpin);
        }

        eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
        eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

        if (!setCurrentBonus().isActive) {
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
            handleIdle(state);
          });
        }
        if (setReplayFreeSpinBets().length === 0) {
          eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
            handleIdle(state, true);
          });
        }
        eventManager.on(EventTypes.CREATE_WIN_MESSAGE_BANNER, () => {
          handleIdle(SlotMachineState.IDLE, true);
        });
      }
    });
  }, [i18n, handleIdle]);

  return (
    <>
      <LoadScreen />
      {data?.isAuthorized && progress?.status >= 100 && <GameScreen />}
    </>
  );
};

export default App;
