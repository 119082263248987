import confetti from 'canvas-confetti';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setIsMobile } from '../../gql/cache';
import { Z_INDEX_CONFETTI, eventManager } from '../config';

class Confetti extends PIXI.Container {
  private confettiTimeout: NodeJS.Timeout[] = [];

  // private canvas: HTMLCanvasElement | null;

  // private context: CanvasRenderingContext2D | null;

  // private myConfetti: confetti.CreateTypes | null;

  constructor() {
    // const canvas = document.createElement('canvas');
    // document.body.insertBefore(canvas, null);
    // const texture = PIXI.Texture.from(canvas);

    // super(texture);
    super();
    this.zIndex = Z_INDEX_CONFETTI;
    eventManager.addListener(EventTypes.CONFETTI_START, this.createConfetti.bind(this));
    eventManager.addListener(EventTypes.CONFETTI_STOP, this.removeConfetti.bind(this));
    // this.canvas = canvas;
    // this.myConfetti = confetti.create(this.canvas!, {
    //   resize: true,
    //   useWorker: false,
    // });
  }

  private createConfettiInfo(): void {
    let particleCount = 40;
    let scalar = 1.4;
    if (setIsMobile()) {
      particleCount = 10;
      scalar = 1;
    }
    confetti({
      angle: 90,
      disableForReducedMotion: false,
      origin: {
        x: Math.random(),
        y: 0,
      },
      particleCount: particleCount,
      scalar: scalar,
      ticks: 500,
      shapes: ['square'],
      colors: ['#ffffff', '#ff2a5c', '#e4ff2a', '#a62aff', '#3df2da', '#ff2a9c', '#2aff9f'],
      zIndex: Z_INDEX_CONFETTI,
    });
  }

  private createConfetti(): void {
    for (let x = 0; x < 10000; x++) {
      this.confettiTimeout[x] = setTimeout(() => this.createConfettiInfo(), x * 200);
    }
  }

  private removeConfetti(): void {
    for (let x = 0; x < 10000; x++) {
      clearTimeout(this.confettiTimeout[x]);
    }
  }
}

export default Confetti;
