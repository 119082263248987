import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_STOP_ANIMATIONS } from '../../config';
import { EventTypes } from '../../global.d';
import { setAnticipationLine, setIsAnticipation, setNextResult, setSlotConfig } from '../../gql/cache';
import { destroySpine } from '../../utils';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REELS_AMOUNT,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  WIN_ANIM_X1_ADJUST,
  WIN_ANIM_X2_ADJUST,
  WIN_ANIM_X3_ADJUST,
  WIN_ANIM_Y_ADJUST,
  Z_INDEX_SLOTS_STOP_DISPLAY,
  eventManager,
} from '../config';
import { Icon } from '../d';

export class SlotsStopDisplayContainer extends ViewContainer {
  private stopSymbolAnimations: Animation[] = [];

  private slotSprites: PIXI.Sprite[] = [];

  constructor(spinResult: Icon[]) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    if (spinResult.length != 0) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        for (let j = 0; j < REELS_AMOUNT; j++) {
          const sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + j]!.id]));

          sprite.width = SLOT_WIDTH * SLOT_SCALE;
          sprite.height = SLOT_HEIGHT * SLOT_SCALE;
          sprite.anchor.set(0.5, 0.5);
          if (j === 0) {
            sprite.x = 193;
          } else if (j === 1) {
            sprite.x = 600;
          } else if (j === 2) {
            sprite.x = 1007;
          }
          // sprite.x = REEL_WIDTH * j + REEL_WIDTH / 2;
          sprite.y = 240;
          // sprite.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
          sprite.scale.set(SLOT_SCALE);
          this.addChild(sprite);
          this.slotSprites.push(sprite);
        }
      }
    }
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.skipStopSymbolAnimations.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showSlotStops.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.JINGLE_START, this.skipScatterAnnounce.bind(this));
    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.handleSetSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.SLOT_STOP_DISPLAY_HIDE, this.hideSlot.bind(this));
    this.zIndex = Z_INDEX_SLOTS_STOP_DISPLAY;
  }

  private skipScatterAnnounce(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
  }

  private onAnticipationAnimationStarts(): void {
    this.slotSprites.forEach((slot, index) => {
      const anticipationLines: number[] = [];

      setAnticipationLine().forEach((anticipationLine) => {
        setSlotConfig().lines[anticipationLine]!.forEach((icon) => {
          anticipationLines.push(icon);
        });
      });

      if (anticipationLines.some((id) => id === index)) {
        slot.zIndex = 3;
      } else {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
      slot.scale.set(SLOT_SCALE);
    });
  }

  private resetSlotsTint(): void {
    this.slotSprites.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private onReelStopped(reelId: number): void {
    if (setNextResult()!.bet.result.spinResult.length === 0) {
      return;
    }

    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, setNextResult()!.bet.result.spinResult, false, reelId);
    this.startOnSymbolsStopAnimations(reelId);

    if (reelId === 1) {
      setIsAnticipation(false);
    }
  }

  private startOnSymbolsStopAnimations(reelId: number): void {
    // TODO: Refactor
    if (reelId === 0) this.stopSymbolAnimations = [];

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slotId = setNextResult()?.bet.result.spinResult[i * REELS_AMOUNT + reelId]!.id;

      if (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId]) {
        const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId];
        if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src]!.spineData);
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnStart(() => {
          animation.spine.y = SLOT_HEIGHT - WIN_ANIM_Y_ADJUST;
          if (reelId === 0) {
            animation.spine.x = WIN_ANIM_X1_ADJUST;
          } else if (reelId === 1) {
            animation.spine.x = WIN_ANIM_X2_ADJUST;
          } else if (reelId === 2) {
            animation.spine.x = WIN_ANIM_X3_ADJUST;
          }
          this.addChild(animation.getSpine());
          animation.setAnimation(animationData.animation!, false);
          this.slotSprites[i * REELS_AMOUNT + reelId]!.visible = false;
        });

        dummy.addOnComplete(() => {
          destroySpine(animation);
          if (reelId === 1) {
            setIsAnticipation(false);
          }
          this.removeChild(animation.spine);
          this.slotSprites[i * REELS_AMOUNT + reelId]!.visible = true;
        });

        dummy.addOnSkip(() => {
          destroySpine(animation);
          setIsAnticipation(false);
          this.removeChild(animation.spine);
          this.slotSprites[i * REELS_AMOUNT + reelId]!.visible = true;
        });

        dummy.addOnChange(() => {
          const anticipationLines: number[] = [];
          setAnticipationLine().forEach((anticipationLine) => {
            setSlotConfig().lines[anticipationLine]!.forEach((icon, index) => {
              if (index != 1) anticipationLines.push(icon);
            });
          });
          if (anticipationLines.some((id) => id === reelId + i * REELS_AMOUNT) || reelId === 1) {
            //
          } else {
            animation.spine.tint = ANTICIPATION_SLOTS_TINT;
          }

          if (!setIsAnticipation()) {
            animation.spine.tint = 0xffffff;
          }
        });
        this.stopSymbolAnimations.push(dummy);
        dummy.start();
      }
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private handleSetSlotsVisibility(slots: number[], visible: boolean): void {
    for (let i = 0; i < slots.length; i++) {
      this.slotSprites[slots[i]!]!.visible = visible;
    }
  }

  private showSlotStops(spinResult: Icon[], constructorFlg: boolean, reelId?: number): void {
    this.visible = true;

    if (constructorFlg === true) return;

    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        this.slotSprites[i * REELS_AMOUNT + reelId]!.texture = PIXI.Texture.from(
          MAPPED_SYMBOLS[spinResult[i * REELS_AMOUNT + reelId]!.id],
        );
        this.slotSprites[i * REELS_AMOUNT + reelId]!.visible = true;
        this.slotSprites[i * REELS_AMOUNT + reelId]!.scale.set(1);
      }
    } else {
      for (let i = 0; i < spinResult.length; i++) {
        this.slotSprites[i]!.texture = PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[i]!.id]);
        this.slotSprites[i]!.visible = true;
        this.slotSprites[i]!.scale.set(1);
      }
    }
  }

  private hideContainer(): void {
    this.visible = false;
    this.slotSprites.forEach((sprite) => (sprite.visible = false));
  }

  private hideSlot(slots: number): void {
    this.slotSprites[slots]!.visible = false;
    this.skipStopSymbolAnimations();
  }
}
