export enum ResourceTypes {
  messagebanner = 'messagebanner',
  totalwin = 'totalwin',
  cicus = 'cicus',
  logo = 'logo',
  reelBase = 'reelBase',
  reelBaseShadow = 'reelBaseShadow',
  reelframeBase = 'reelframeBase',
  symbolABonus = 'symbolABonus',
  symbolBBlank = 'symbolBBlank',
  titlelogo = 'titlelogo',
  backdrop = 'backdrop',
  backgroundBase = 'backgroundBase',
  bonusgameBase = 'bonusgameBase',
  bonusgameBase2 = 'bonusgameBase2',
  bonusgameBase3 = 'bonusgameBase3',
  bonusgameBase4 = 'bonusgameBase4',
  bonusgameBase5 = 'bonusgameBase5',
  bonusgameOrnament = 'bonusgameOrnament',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  infoPaylines2 = 'infoPaylines2',
  intro01 = 'intro01',
  intro02 = 'intro02',
  introBg = 'introBg',
  textLogo = 'textLogo',
}
