// MINIMAP
export const MINIMAP_LANDSCAPE_POS_X = 1350;
export const MINIMAP_LANDSCAPE_MOBILE_POS_X = 1100;
export const MINIMAP_LANDSCAPE_POS_Y = -300;
export const MINIMAP_PORTRAIT_POS_X = 900;
export const MINIMAP_PORTRAIT_POS_Y = -300;

export const ODD_POSITION_1_X = 52;
export const ODD_POSITION_1_Y = 105;

export const ODD_POSITION_3_X = 52;
export const ODD_POSITION_3_Y = 245;

export const ODD_POSITION_5_X = 52;
export const ODD_POSITION_5_Y = 385;

export const ODD_POSITION_10_X = 52;
export const ODD_POSITION_10_Y = 735;
