import SlotMachine from '..';
import { getStartupTensionLot } from '../../anticipation';
import { EyeControl, red } from '../../config';
import { EventTypes } from '../../global.d';
import { SetAvatarStatusControl, SetAvatarTension, SetEyeControl } from '../../gql/cache';
import { debugDisplay } from '../../utils';
import Tween from '../animations/tween';
import { StatusControlFlg } from '../avatarTalk/config';
import ViewContainer from '../components/container';
import { EYE_CONTROL_IDLE_TIME, eventManager } from '../config';

class AvatarMotion extends ViewContainer {
  private avatarTension: number;

  private afterBonusEnds: number;

  private avatarTalkStatusControl: StatusControlFlg; //8.4.2 状況管理フラグ

  constructor() {
    super();

    this.afterBonusEnds = 0;
    this.avatarTension = getStartupTensionLot();
    this.avatarTalkStatusControl = StatusControlFlg.NORMAL;

    SetAvatarStatusControl(this.avatarTalkStatusControl);
    debugDisplay('初期アバターテンション', this.avatarTension);
    SetAvatarTension(this.avatarTension);

    eventManager.addListener(EventTypes.TOGGLE_SPIN, this.spinStart.bind(this));
    eventManager.addListener(EventTypes.SPIN_END, this.spinEnd.bind(this));
    eventManager.addListener(EventTypes.BONUS_END, this.bonusEnd.bind(this));
  }

  private spinStart(): void {
    const date = new Date();
    debugDisplay('SetEyeControl(EyeControl.PLAY)', 'date', date.toLocaleString());
    SetEyeControl(EyeControl.PLAY);
  }

  private spinEnd(): void {
    if (SlotMachine.getInstance().getFreeSpinBonus() === undefined) {
      this.afterBonusEnds += 1;
    }

    this.avatarTension = SetAvatarTension();
    this.avatarTalkStatusControl = SetAvatarStatusControl();

    debugDisplay('BONUS 終了後', this.afterBonusEnds, 'テンション', this.avatarTension);

    if (this.afterBonusEnds === 10) {
      debugDisplay('BONUS 10ゲーム後');
      this.avatarTension = 2;
    } else if (this.afterBonusEnds === 20) {
      debugDisplay(red + 'BONUS 20ゲーム後');
      if (this.avatarTension >= 2) {
        this.avatarTension -= 1;
      }

      this.avatarTalkStatusControl = StatusControlFlg.HAMARI;
    }
    debugDisplay('spin後 テンション', this.avatarTension);
    SetAvatarTension(this.avatarTension);
    SetAvatarStatusControl(this.avatarTalkStatusControl);

    // 待機モーション時の視線制御抽せん　で使用する　プレイ中 or アイドル状態
    const delay = Tween.createDelayAnimation(EYE_CONTROL_IDLE_TIME);
    delay.addOnComplete(() => {
      const date = new Date();
      debugDisplay('SetEyeControl(EyeControl.IDLE)', 'date', date.toLocaleString());
      SetEyeControl(EyeControl.IDLE);
    });
    delay.start();
  }

  private bonusEnd(): void {
    this.afterBonusEnds = 0;
  }
}

export default AvatarMotion;
