import * as PIXI from 'pixi.js';

import { MessageCroonBonusBannerProps } from '../../global.d';
import { setIsMobile } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { MESSAGE_BANNER_MOBILE_SCALE, Z_INDEX_BANNER } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { btnTextStyle, descriptionTextStyle, titleTextStyle } from './textStyles';

export class MessageCroonBonusBanner extends BaseMessageBanner {
  protected override props: MessageCroonBonusBannerProps;

  private title?: PIXI.Text;

  private description1?: PIXI.Text;

  private description2?: PIXI.Text;

  private btn?: PIXI.Text;

  constructor(props: MessageCroonBonusBannerProps) {
    super(props);
    this.props = props;
    this.zIndex = Z_INDEX_BANNER;

    // TODO 位置調整必要
    this.position.x = 600;
    this.position.y = -160;
  }

  public override init(): MessageCroonBonusBanner {
    super.init(ResourceTypes.messagebanner);

    this.title = this.initTitle(this.props.title);
    this.description1 = this.initDescription(this.props.description1, 380);
    this.description2 = this.initDescription(this.props.description2, 450);
    this.btn = this.initBtn(this.props.btnText);
    this.addChild(this.title, this.description1, this.description2, this.btn);

    if (setIsMobile()) {
      this.scale.set(MESSAGE_BANNER_MOBILE_SCALE);
    }

    return this;
  }

  private initTitle(titleText: string): PIXI.Text {
    const title = new PIXI.Text(titleText, titleTextStyle);
    title.resolution = 1;
    title.anchor.set(0.5, 0);
    title.y = 150;
    updateTextScale(title, this.banner!.width - 200, 250);

    return title;
  }

  private initDescription(descriptionText: string, yPos: number): PIXI.Text {
    const description = new PIXI.Text(descriptionText, descriptionTextStyle);
    description.resolution = 1;
    description.anchor.set(0.5, 0);
    description.y = yPos;
    updateTextScale(description, this.banner!.width - 200, 250);

    return description;
  }

  private initBtn(btnText: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5, 0);
    btn.y = 600;
    updateTextScale(btn, this.banner!.width - 200, 250);
    return btn;
  }
}
