import { ReelSet } from '../global';
import { Icon } from '../slotMachine/d';

import { getIconById } from './helper';

export const getSpinResult3x1 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom = random - 1 < 0 ? reelSet.layout[index]!.length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index]![prevRandom]!);
    }),
  ];
  return spinResult;
};
