import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { CroonRoundMode, EventTypes } from '../../global.d';
import { SetCroonMode } from '../../gql/cache';
import { debugDisplay, getCroonRoundModeToRoundNum } from '../../utils';
import ViewContainer from '../components/container';
import {
  MINI_MAP_LINE_X,
  MINI_MAP_LINE_Y,
  MINI_MAP_LINE_Y_ADD,
  MINI_MAP_STATE_X,
  MINI_MAP_STATE_Y,
  MINI_MAP_STATE_Y_ADD,
  Z_INDEX_MINI_MAP,
  eventManager,
} from '../config';

import {
  MINIMAP_LANDSCAPE_MOBILE_POS_X,
  MINIMAP_LANDSCAPE_POS_X,
  MINIMAP_LANDSCAPE_POS_Y,
  MINIMAP_PORTRAIT_POS_X,
  MINIMAP_PORTRAIT_POS_Y,
  ODD_POSITION_1_X,
  ODD_POSITION_1_Y,
  ODD_POSITION_3_X,
  ODD_POSITION_3_Y,
  ODD_POSITION_5_X,
  ODD_POSITION_5_Y,
  ODD_POSITION_10_X,
  ODD_POSITION_10_Y,
} from './config';

class MiniMapContainer extends ViewContainer {
  private mapStageS: PIXI.Sprite[] = [];

  private mapStageD: PIXI.Sprite[] = [];

  private mapLine: PIXI.Sprite[] = [];

  private mapStageP: PIXI.Sprite;

  private mapGoal: PIXI.Sprite;

  private oddsText: PIXI.Sprite;

  private textSheet: PIXI.Spritesheet;

  constructor() {
    super();

    if (isMobile) {
      this.position.x = MINIMAP_LANDSCAPE_MOBILE_POS_X;
    } else {
      this.position.x = MINIMAP_LANDSCAPE_POS_X;
    }
    this.position.y = MINIMAP_LANDSCAPE_POS_Y;
    this.textSheet = PIXI.Loader.shared.resources['map']!.spritesheet!;

    for (let index = 0; index < 10; index++) {
      this.mapStageS[index] = new PIXI.Sprite(this.textSheet.textures['map_stage_s.png']);
      this.mapStageD[index] = new PIXI.Sprite(this.textSheet.textures['map_stage_d.png']);
      this.mapLine[index] = new PIXI.Sprite(this.textSheet.textures['map_line.png']);
    }
    for (let index = 0; index < 10; index++) {
      this.addChild(this.mapLine[index]!);
      this.mapLine[index]!.visible = false;
    }

    for (let index = 0; index < 10; index++) {
      this.addChild(this.mapStageS[index]!);
      this.mapStageS[index]!.visible = false;
    }

    for (let index = 0; index < 10; index++) {
      this.addChild(this.mapStageD[index]!);
      this.mapStageD[index]!.visible = false;
    }

    this.mapStageP = new PIXI.Sprite(this.textSheet.textures['map_stage_p.png']);
    this.mapGoal = new PIXI.Sprite(this.textSheet.textures['map_goal.png']);
    this.oddsText = new PIXI.Sprite(this.textSheet.textures['x100.png']);
    this.oddsText.anchor.set(0.5);
    this.oddsText.visible = false;

    this.addChild(this.mapGoal, this.mapStageP, this.oddsText);

    this.zIndex = Z_INDEX_MINI_MAP;
    this.visible = false;

    eventManager.addListener(EventTypes.MINI_MAP_VISIBILITY, this.miniMapVisibility.bind(this));

    eventManager.addListener(EventTypes.MINI_MAP_SET_CURRENT, this.miniMapSetCurrent.bind(this));

    eventManager.addListener(EventTypes.CROON_END, this.croonStageDelete.bind(this));

    eventManager.addListener(EventTypes.MINIMAP_CURRENT_SET, this.minimapCurrentSet.bind(this));

    eventManager.addListener(EventTypes.MINIMAP_EXTRA_STAGE, this.setMiniMapExtraStage.bind(this));

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private setOdds(isExtra: boolean): void {
    debugDisplay('SetCroonMode()', SetCroonMode());
    const mode = SetCroonMode();
    if (mode === CroonRoundMode.SP1) {
      this.oddsText.texture = this.textSheet.textures['map_x10000.png']!;
      this.oddsText.position.set(ODD_POSITION_1_X, ODD_POSITION_1_Y);
      this.oddsText.visible = true;
    } else if (mode === CroonRoundMode.SP3) {
      this.oddsText.texture = this.textSheet.textures['map_x10000.png']!;
      this.oddsText.position.set(ODD_POSITION_3_X, ODD_POSITION_3_Y);
      this.oddsText.visible = true;
    } else if (mode === CroonRoundMode.SP5) {
      this.oddsText.texture = this.textSheet.textures['map_x10000.png']!;
      this.oddsText.position.set(ODD_POSITION_5_X, ODD_POSITION_5_Y);
      this.oddsText.visible = true;
    } else if (mode === CroonRoundMode.NM3) {
      if (isExtra) {
        this.oddsText.texture = this.textSheet.textures['map_x10000.png']!;
        this.oddsText.position.set(ODD_POSITION_10_X, ODD_POSITION_10_Y);
        this.oddsText.visible = true;
      } else {
        this.oddsText.texture = this.textSheet.textures['map_x100.png']!;
        this.oddsText.position.set(ODD_POSITION_3_X, ODD_POSITION_3_Y);
        this.oddsText.visible = true;
      }
    } else if (mode === CroonRoundMode.NM5) {
      if (isExtra) {
        this.oddsText.texture = this.textSheet.textures['map_x10000.png']!;
        this.oddsText.position.set(ODD_POSITION_10_X, ODD_POSITION_10_Y);
        this.oddsText.visible = true;
      } else {
        this.oddsText.texture = this.textSheet.textures['map_x1000.png']!;
        this.oddsText.position.set(ODD_POSITION_5_X, ODD_POSITION_5_Y);
        this.oddsText.visible = true;
      }
    } else if (mode === CroonRoundMode.NM10) {
      this.oddsText.texture = this.textSheet.textures['map_x10000.png']!;
      this.oddsText.position.set(ODD_POSITION_10_X, ODD_POSITION_10_Y);
      this.oddsText.visible = true;
    }
  }

  private miniMapVisibility(croonRound: CroonRoundMode, visible: boolean): void {
    this.visible = visible;
    if (!visible) {
      return;
    }

    const round = getCroonRoundModeToRoundNum(croonRound);

    for (let index = 0; index < round - 1; index++) {
      this.mapStageS[index]!.position.x = MINI_MAP_STATE_X;
      this.mapStageS[index]!.position.y = MINI_MAP_STATE_Y + MINI_MAP_STATE_Y_ADD * index;
      this.mapStageS[index]!.visible = true;

      this.mapLine[index]!.position.x = MINI_MAP_LINE_X;
      this.mapLine[index]!.position.y = MINI_MAP_LINE_Y + MINI_MAP_LINE_Y_ADD * index;
      this.mapLine[index]!.visible = true;
    }

    this.mapGoal.position.x = MINI_MAP_STATE_X;
    this.mapGoal.position.y = MINI_MAP_STATE_Y + MINI_MAP_LINE_Y_ADD * (round - 1);
    this.mapGoal.visible = true;
    this.mapStageP.visible = true;
    this.visible = true;

    this.setOdds(false);
  }

  private miniMapSetCurrent(currentPos: number): void {
    this.mapStageP.position.x = MINI_MAP_STATE_X;
    this.mapStageP.position.y = MINI_MAP_STATE_Y + MINI_MAP_STATE_Y_ADD * (currentPos - 1);

    for (let index = 0; index < currentPos - 1; index++) {
      this.mapStageD[index]!.position.x = MINI_MAP_STATE_X;
      this.mapStageD[index]!.position.y = MINI_MAP_STATE_Y + MINI_MAP_STATE_Y_ADD * index;
      this.mapStageD[index]!.visible = true;
    }
  }

  private croonStageDelete(): void {
    this.visible = false;
    this.pivot.y = 0;
    for (let index = 0; index < 10; index++) {
      this.mapStageS[index]!.visible = false;
      this.mapLine[index]!.visible = false;
      this.mapStageD[index]!.visible = false;
    }
    this.mapStageP.visible = false;
    this.mapGoal.visible = false;
  }

  private minimapCurrentSet(currentPos: number): void {
    this.mapStageP.position.y = MINI_MAP_STATE_Y + MINI_MAP_STATE_Y_ADD * (currentPos - 1);
  }

  private setMiniMapExtraStage(): void {
    let startIndex = 9;
    if (SetCroonMode() == CroonRoundMode.NM3) {
      startIndex = 3;
    } else if (SetCroonMode() == CroonRoundMode.NM5) {
      startIndex = 5;
    }

    this.mapStageD[startIndex - 1]!.visible = true;
    this.mapStageD[startIndex - 1]!.position.y = MINI_MAP_STATE_Y + MINI_MAP_STATE_Y_ADD * (startIndex - 1);

    for (let index = startIndex; index < 10; index++) {
      this.mapStageS[index]!.position.x = MINI_MAP_STATE_X;
      this.mapStageS[index]!.position.y = MINI_MAP_STATE_Y + MINI_MAP_STATE_Y_ADD * index;
      this.mapLine[index]!.position.x = MINI_MAP_LINE_X;
      this.mapLine[index]!.position.y = MINI_MAP_LINE_Y + MINI_MAP_LINE_Y_ADD * (index - 1);
      this.mapGoal.position.y = MINI_MAP_STATE_Y + MINI_MAP_LINE_Y_ADD * 9;
    }
    for (let index = startIndex; index < 10; index++) {
      this.mapStageS[index]!.visible = true;
      this.mapLine[index]!.visible = true;
    }
    this.setOdds(true);
  }

  private resize(width: number, height: number): void {
    if (width > height) {
      if (isMobile) {
        this.position.x = MINIMAP_LANDSCAPE_MOBILE_POS_X;
      } else {
        this.position.x = MINIMAP_LANDSCAPE_POS_X;
      }
      this.position.y = MINIMAP_LANDSCAPE_POS_Y;
    } else {
      this.position.x = MINIMAP_PORTRAIT_POS_X;
      this.position.y = MINIMAP_PORTRAIT_POS_Y;
    }
  }
}

export default MiniMapContainer;
