import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes } from '../../global.d';
import { setCurrentStage } from '../../gql/cache';
import { debugDisplay } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { Z_INDEX_CROON_STATE_MAX, eventManager } from '../config';

class Warning extends ViewContainer {
  private warning: SpineAnimation;

  constructor() {
    super();

    this.sortableChildren = true;

    this.warning = this.initWarning();
    this.warning.getSpine().zIndex = Z_INDEX_CROON_STATE_MAX + 2;

    this.position.set(0, 0);

    this.addChild(this.warning.spine);

    eventManager.addListener(EventTypes.CROON_EXTRA_OPEN, this.croonExtraOpen.bind(this));
  }

  private initWarning(): SpineAnimation {
    const croonWarning = new SpineAnimation({}, PIXI.Loader.shared.resources['warninng']!.spineData!);
    croonWarning.getSpine().position.set(600, 200);
    croonWarning.getSpine().visible = false;

    croonWarning.addOnComplete(() => {
      croonWarning.getSpine().visible = false;
      setCurrentStage(setCurrentStage() + 1);
      eventManager.emit(EventTypes.CROON_EXTRA_OPENED);
    });

    return croonWarning;
  }

  private croonExtraOpen(): void {
    debugDisplay('croonExtraOpen');
    this.warning.setAnimation('warninng', false);
    this.warning.getSpine().visible = true;
    AudioApi.play({ type: ISongs.SE_ExtraWarning });

    const extraWarningDuration = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SE_ExtraWarning]!.duration);
    extraWarningDuration.addOnComplete(() => {
      AudioApi.play({ type: ISongs.SE_ExtraWarning });
    });
    extraWarningDuration.start();
  }
}

export default Warning;
