import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { Hole1DropPattern, Hole2DropPattern, Hole3DropPattern } from '../../anticipation/table';
import { getResultFromTable } from '../../anticipation/util';
import { ISongs, green, mappedAudioSprites } from '../../config';
import { CroonRoundMode, EventTypes } from '../../global.d';
import {
  SetCroonMode,
  SetIsCroonStageMove,
  setBrokenGame,
  setCurrentBonus,
  setCurrentStage,
  setReplayBet,
  setReplayFreeSpinBets,
} from '../../gql/cache';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { CheckFinalStage, chkEndStage, debugDisplay, getCroonRoundModeToRoundNum, isDropAnimation } from '../../utils';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { StateType, croonState } from '../avatarMotion/config';
import ViewContainer from '../components/container';
import { BGM_FADE_OUT_TIME, BGM_FADE_OUT_VOLUME, SlotMachineState, eventManager } from '../config';

import {
  CroonAnimData,
  CroonAnimationActiveData,
  CroonAnimationClearData,
  CroonAnimationClearedData,
  CroonAnimationIdleData,
  CroonStageOdds,
} from './config';

class CroonState extends ViewContainer {
  private croonAnim: SpineAnimation | undefined;

  private croonFrameAnim: SpineAnimation;

  private croonWinAnim: SpineAnimation | undefined;

  private id: number;

  private isActive: boolean;

  private croonAnimString: string;

  private animCount: number;

  private nextState: boolean | undefined;

  private textSheet: PIXI.Spritesheet;

  private text1: PIXI.Sprite;

  private text2: PIXI.Sprite;

  private text3: PIXI.Sprite;

  private isResult: boolean;

  private inHole: number;

  constructor(posY: number, id: number) {
    super();
    this.position.y = posY;
    this.id = id;
    this.isResult = false;
    this.inHole = 2;
    debugDisplay('-----constructor------ this.isResult', this.isResult);

    this.isActive = false;
    this.croonAnimString = '';
    this.animCount = 0;
    this.nextState = undefined;
    this.textSheet = PIXI.Loader.shared.resources['magnification']!.spritesheet!;

    this.text1 = new PIXI.Sprite(this.textSheet.textures[CroonStageOdds[SetCroonMode()]!.oddsList[0]![0]!]);

    this.text2 = new PIXI.Sprite(this.textSheet.textures[CroonStageOdds[SetCroonMode()]!.oddsList[0]![1]!]);
    this.text3 = new PIXI.Sprite(this.textSheet.textures[CroonStageOdds[SetCroonMode()]!.oddsList[0]![2]!]);

    this.croonAnim = this.initCroonAnim();
    this.croonFrameAnim = this.initCroonFrame();

    this.addChild(this.croonAnim.spine, this.croonFrameAnim.spine);

    eventManager.addListener(EventTypes.CROON_START, this.croonStart.bind(this));

    eventManager.addListener(EventTypes.CROON_IN, this.ballDropSound.bind(this));

    eventManager.addListener(EventTypes.CROON_STATE_NEXT, this.croonStartFrame.bind(this));

    eventManager.addListener(EventTypes.COUNT_UP_END, this.chkCountUpEnd.bind(this));

    eventManager.addListener(EventTypes.SET_IDLE_CLEARED, this.setIdleCleared.bind(this));

    eventManager.addListener(EventTypes.MINIMAP_EXTRA_STAGE, this.croonMiniMapExtraStage.bind(this));

    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, this.croonChangeRestriction.bind(this));

    eventManager.addListener(EventTypes.SET_IS_SLOTS_STOPPED, this.croonSlotsStopped.bind(this));

    eventManager.addListener(EventTypes.CROON_RESULT, this.croonStageResult.bind(this));

    eventManager.addListener(EventTypes.MINIMAP_EXTRA_STAGE, this.croonStateStartScroll.bind(this));
  }

  public setPositionY(posY: number): void {
    this.position.y = posY;
  }

  public setOdds(id: number): void {
    this.text1.texture = this.textSheet.textures[CroonStageOdds[SetCroonMode()]!.oddsList[id]![0]!]!;
    this.text2.texture = this.textSheet.textures[CroonStageOdds[SetCroonMode()]!.oddsList[id]![1]!]!;
    this.text3.texture = this.textSheet.textures[CroonStageOdds[SetCroonMode()]!.oddsList[id]![2]!]!;

    this.removeChild(this.croonAnim!.spine, this.croonFrameAnim!.spine);
    this.croonAnim = this.initCroonAnim();
    this.croonFrameAnim = this.initCroonFrame();
    this.addChild(this.croonAnim!.spine, this.croonFrameAnim!.spine);
    this.nextState = undefined;
  }

  private croonMiniMapExtraStage(): void {
    if (this.id === setCurrentStage() - 1) {
      this.croonFrameAnim!.setAnimation(CroonAnimationClearedData[this.id]!.animationName, false);
    }
  }

  private croonChangeRestriction(): void {
    if (setBrokenGame() && this.isActive) {
      if (this.croonAnimString === '2_start' && !AudioApi.isPlaying(ISongs.SE_BallSpin1_Loop)) {
        AudioApi.play({ type: ISongs.SE_BallSpin1_Loop });
      } else if (this.croonAnimString === '4_out_in' && !AudioApi.isPlaying(ISongs.SE_BallSpin2_Loop)) {
        AudioApi.play({ type: ISongs.SE_BallSpin2_Loop });
      } else if (this.croonAnimString === '5_in' && !AudioApi.isPlaying(ISongs.SE_BallSpin3_Loop)) {
        AudioApi.play({ type: ISongs.SE_BallSpin3_Loop });
      }
    }
  }

  private croonSlotsStopped(isSlotStopped: boolean): void {
    if (setCurrentStage() != this.id) {
      return;
    }
    if (isSlotStopped && this.visible && this.isActive && !SetIsCroonStageMove()) {
      this.inHole = setCurrentBonus().data.pachiCroonRounds[this.id]!.position;
      AudioApi.stop({ type: ISongs.SE_BallSpin1_Loop });
      AudioApi.stop({ type: ISongs.SE_BallSpin2_Loop });
      AudioApi.stop({ type: ISongs.SE_BallSpin3_Loop });
      eventManager.emit(EventTypes.CROON_IN, this.id);
      if (!this.nextState || chkEndStage(SetCroonMode(), this.id)) {
        this.reward();
      } else {
        this.croonAnimString = '1_idle';
        this.croonAnim!.setAnimation(this.croonAnimString, true);
        if (this.nextState && this.id < 9) {
          eventManager.emit(EventTypes.CROON_START, this.id + 1);
        }

        if (isDropAnimation(this.croonAnimString) && (!this.nextState || chkEndStage(SetCroonMode(), this.id))) {
          this.reward();
        } else if (isDropAnimation(this.croonAnimString) && this.nextState === true) {
          eventManager.emit(EventTypes.CROON_MOVE, this.id);
          eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
        } else if (this.croonAnimString === '1_idle' && this.nextState === true) {
          // eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
        } else if (this.croonAnimString === 'clear' && this.nextState === true) {
          this.croonAnimString = 'idle_cleared';
          this.croonAnim!.setAnimation(this.croonAnimString, true);
        } else {
          //
        }
      }
    }
  }

  private croonStageResult(id: number): void {
    debugDisplay('\\\\\\ croonStageResult id ', id);
    if (this.id != id) {
      return;
    }
    this.isResult = true;
  }

  private setIdleCleared(): void {
    if (this.id < setCurrentStage()) {
      this.croonAnimString = 'idle_cleared';
      this.croonAnim!.setAnimation(this.croonAnimString, true);
    }
  }

  private croonStateStartScroll(): void {
    if (setCurrentStage() <= this.id) {
      this.croonAnimString = '1_idle';
      this.croonAnim!.setAnimation(this.croonAnimString, true);
    }
  }

  private initCroonAnim(): SpineAnimation {
    const croonAnim = new SpineAnimation({}, PIXI.Loader.shared.resources['croon']!.spineData!);
    croonAnim!.getSpine().pivot.set(0.5, 0.5);
    croonAnim?.getSpine().position.set(0, 0);
    croonAnim!.setAnimation('1_idle', true);
    croonAnim!.getSpine().visible = true;

    croonAnim!.getSpine().hackTextureBySlotName('pocket1', this.text1.texture, this.text1.texture.frame);

    croonAnim!.getSpine().hackTextureBySlotName('collect_l', this.text2.texture, this.text2.texture.frame);

    croonAnim!.getSpine().hackTextureBySlotName('collect_r', this.text3.texture, this.text3.texture.frame);

    return croonAnim;
  }

  private initCroonFrame(): SpineAnimation {
    const croonFrame = new SpineAnimation({}, PIXI.Loader.shared.resources['croon_frame']!.spineData!);

    const stage = croonState[SetCroonMode()]!.state[this.id];

    let skinName = 'blue';
    switch (stage) {
      case StateType.BEGIN:
        skinName = 'blue';
        break;
      case StateType.MIDDLE:
        skinName = 'yellow';
        break;
      case StateType.END:
        skinName = 'red';
        break;
    }

    croonFrame.setSkin(skinName);
    croonFrame.getSpine().pivot.set(0.5, 0.5);
    croonFrame.getSpine().position.set(0, 0);
    croonFrame.setAnimation(CroonAnimationIdleData[this.id]!.animationName, true);
    croonFrame.getSpine().visible = true;
    return croonFrame;
  }

  private initCroonWin(_anim: string, inHole: number): SpineAnimation {
    const croonWin = new SpineAnimation({}, PIXI.Loader.shared.resources['croon_win']!.spineData!);
    croonWin.getSpine().pivot.set(0.5, 0.5);
    switch (inHole) {
      case 0:
        croonWin.getSpine().position.set(0, 90);
        break;
      case 1:
        croonWin.getSpine().position.set(-85, 0);
        break;
      case 2:
        croonWin.getSpine().position.set(85, 0);
        break;
    }

    return croonWin;
  }

  private croonStartFrame(stage: number): void {
    if (this.id === stage && this.visible) {
      this.croonAnimString = 'clear';
      this.croonAnim!.setAnimation(this.croonAnimString, false);

      // debugDisplay('croonStartFrame stage ', stage);
      this.croonFrameAnim!.setAnimation(CroonAnimationClearData[this.id]!.animationName, false);

      this.croonFrameAnim!.addOnComplete(() => {
        this.croonFrameAnim!.setAnimation(CroonAnimationClearedData[this.id]!.animationName, false);
      });
    }
  }

  private croonStart(stage: number): void {
    // debugDisplay(
    //   '----croonStart stage',
    //   stage,
    //   'this.id',
    //   this.id,
    //   'this.visible',
    //   this.visible,
    //   'setCurrentStage()',
    //   setCurrentStage(),
    // );
    if (this.id != stage && this.visible && this.id > setCurrentStage()) {
      this.croonAnimString = 'idle_preclear';
      this.croonAnim!.setAnimation(this.croonAnimString, false);
    }

    if (this.id === stage && this.visible) {
      debugDisplay(
        '----croonStart stage',
        stage,
        'this.id',
        this.id,
        'this.visible',
        this.visible,
        'setCurrentStage()',
        setCurrentStage(),
        'setCurrentBonus().data.pachiCroonRounds',
        setCurrentBonus().data.pachiCroonRounds,
      );

      this.inHole = setCurrentBonus().data.pachiCroonRounds[this.id]!.position;
      if (this.inHole === 0) {
        this.nextState = true;
      }
      this.isActive = true;
      this.croonFrameAnim.setAnimation(CroonAnimationActiveData[this.id]!.animationName, true);

      debugDisplay('id', this.id, 'stage', stage, 'visible', this.visible);
      // debugDisplay(
      //   'YES',
      //   this.croonAnim!.getSpine().state.hasAnimation('3_out'),
      // );
      this.croonAnimString = '2_start';
      this.croonAnim!.setAnimation(this.croonAnimString, false);
      if (!AudioApi.isPlaying('ISongs.SE_BallSpin1_Loop')) {
        AudioApi.play({ type: ISongs.SE_BallSpin1_Loop, stopPrev: true });
      }
      eventManager.emit(EventTypes.FIRST_ROTATING_WATCHDOG, this.id);

      this.croonAnim!.getSpine().state.addListener({
        event: function (_t, e) {
          if (e.data.name === 'croon_in') {
            debugDisplay('@@@@@@@@@@@ croon_in2 ======= ', 'stage', stage); // Spineのイベント処理
            eventManager.emit(EventTypes.CROON_IN, stage);
          }
        },
      });

      let croonAnim = 0;
      const rand = Math.floor(Math.random() * 100);

      if (this.inHole === 0) {
        debugDisplay('==== DropPattern rand', rand, 'Hole1DropPattern', getResultFromTable(Hole1DropPattern, rand));
        croonAnim = getResultFromTable(Hole1DropPattern, rand);
      } else if (this.inHole === 1) {
        debugDisplay('==== DropPattern rand', rand, 'Hole2DropPattern', getResultFromTable(Hole2DropPattern, rand));
        croonAnim = getResultFromTable(Hole2DropPattern, rand) + 8;
      } else {
        debugDisplay('==== DropPattern rand', rand, 'Hole3DropPattern', getResultFromTable(Hole3DropPattern, rand));
        croonAnim = getResultFromTable(Hole3DropPattern, rand) + 14;
      }

      this.croonAnim!.addOnComplete(() => {
        // if (this.id === 2) {
        //   debugDisplay(
        //     ' this.croonAnimString',
        //     this.croonAnimString,
        //     'this.nextState',
        //     this.nextState,
        //     'setCurrentStage()',
        //     setCurrentStage(),
        //   );
        // }

        // debugDisplay('loop', this.croonAnimString, 'visible', this.visible);
        if (this.croonAnimString === '2_start') {
          this.croonAnimString = '3_out';
          // TODO 暫定対応
          this.animCount = Math.floor(Math.random() * 3) + 1;
          debugDisplay(green + '3_out ', this.animCount);
          this.croonAnim!.setAnimation(this.croonAnimString, true);
          if (!AudioApi.isPlaying(ISongs.SE_BallSpin1_Loop)) {
            AudioApi.play({ type: ISongs.SE_BallSpin1_Loop });
          }
        } else if (this.croonAnimString === '3_out') {
          this.animCount -= 1;

          debugDisplay(
            '---- 1.this.animCount',
            this.animCount,
            'this.isResult',
            this.isResult,
            'this.animCount',
            this.animCount,
          );

          if (!this.isResult && this.animCount === 0) {
            this.animCount = 1;
          }

          debugDisplay('---- 2.this.animCount', this.animCount);
          if (!AudioApi.isPlaying(ISongs.SE_BallSpin1_Loop)) {
            AudioApi.play({ type: ISongs.SE_BallSpin1_Loop, stopPrev: true });
          }

          if (this.animCount <= 0) {
            this.croonAnimString = '4_out_in';
            this.croonAnim!.setAnimation(this.croonAnimString, false);
            AudioApi.stop({ type: ISongs.SE_BallSpin1_Loop });
            AudioApi.play({ type: ISongs.SE_BallSpin2_Loop, stopPrev: true });
          }
        } else if (this.croonAnimString === '4_out_in') {
          debugDisplay('4_out_in');
          this.croonAnimString = '5_in';
          // TODO 暫定対応
          this.animCount = Math.floor(Math.random() * 2) + 1;
          debugDisplay(green + '5_in ', this.animCount);
          this.croonAnim!.setAnimation(this.croonAnimString, true);
          AudioApi.stop({ type: ISongs.SE_BallSpin2_Loop });
          AudioApi.play({ type: ISongs.SE_BallSpin3_Loop, stopPrev: true });

          eventManager.emit(EventTypes.SECOND_ROTATING_WATCHDOG, CroonAnimData[croonAnim]!.animType);
        } else if (this.croonAnimString === '5_in') {
          debugDisplay('5_in this.animCount', this.animCount);
          this.animCount -= 1;
          if (this.animCount <= 0) {
            debugDisplay('=======croon Anim', croonAnim, (this.croonAnimString = CroonAnimData[croonAnim]!.animType));

            this.croonAnimString = CroonAnimData[croonAnim]!.animType;
            debugDisplay(
              'CroonAnimData[croonAnim]!.nextStage',
              CroonAnimData[croonAnim]!.nextStage,
              'croonAnim',
              croonAnim,
            );
            this.nextState = CroonAnimData[croonAnim]!.nextStage;
            this.croonAnim!.setAnimation(this.croonAnimString, false);
          }
        } else if (
          isDropAnimation(this.croonAnimString) &&
          (this.nextState === false || chkEndStage(SetCroonMode(), this.id))
        ) {
          debugDisplay('===@@@@==== OK1');
          this.reward();
        } else if (isDropAnimation(this.croonAnimString) && this.nextState === true) {
          debugDisplay('===@@@@==== OK2');
          AudioApi.stop({ type: ISongs.SE_BallSpin3_Loop });
          debugDisplay('Next');
          this.croonAnimString = '1_idle';
          this.croonAnim!.setAnimation(this.croonAnimString, true);
          debugDisplay('@@@@@@@@@@@@@@@@@@@@@@@@@@@ start1');
          eventManager.emit(EventTypes.CROON_START, this.id + 1);
          eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
          debugDisplay('CROON_STATE_NEXT1');
          // eventManager.emit(
          //   EventTypes.CROON_STATE_NEXT,
          //   this.id,
          //   getCroonRoundModeToRoundNum(SetCroonMode()),
          // );
          eventManager.emit(EventTypes.CROON_MOVE, this.id);
        } else if (this.croonAnimString === '1_idle' && this.nextState === true) {
          // debugDisplay('===@@@@==== OK3');
          // this.croonAnimString = 'idle_cleared';
          // this.croonAnim!.setAnimation(this.croonAnimString, true);
          // this.ballDrop(this.id);
        } else if (this.croonAnimString === 'clear' && this.nextState) {
          this.croonAnimString = 'idle_cleared';
          this.croonAnim!.setAnimation(this.croonAnimString, true);
        } else {
          if (setCurrentStage() < this.id)
            debugDisplay(
              '===@@@@==== NG? isDropAnimation(this.croonAnimString)',
              isDropAnimation(this.croonAnimString),
              'this.croonAnimString',
              this.croonAnimString,
              'this.nextState',
              this.nextState,
              'this.id',
              this.id,
            );
        }
      });
    } else if (this.croonAnimString === '1_idle' && setCurrentStage() > this.id) {
      this.croonAnimString = 'idle_cleared';
      this.croonAnim!.setAnimation(this.croonAnimString, true);
    }
  }

  private reward(): void {
    AudioApi.stop({ type: ISongs.SE_BallSpin3_Loop });
    debugDisplay('End');
    this.croonAnimString = '1_idle';
    this.croonAnim!.setAnimation(this.croonAnimString, true);
    eventManager.emit(EventTypes.SET_STATE, SlotMachineState.CROON_WINNING);

    const win = setCurrentBonus().data.pachiCroonRounds[this.id]!.coinReward;
    if (win === 10000) {
      eventManager.emit(EventTypes.CONFETTI_START);
    }
  }

  private ballDrop(id: number): void {
    if (this.id != id) {
      return;
    }
    debugDisplay('ballDrop this.id', this.id);
    eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
    AudioApi.stop({ type: ISongs.SE_BallSpin1_Loop });
    AudioApi.stop({ type: ISongs.SE_BallSpin2_Loop });
    AudioApi.stop({ type: ISongs.SE_BallSpin3_Loop });
    debugDisplay('Next');
    this.croonAnimString = '1_idle';
    this.croonAnim!.setAnimation(this.croonAnimString, false);
    // debugDisplay('@@@@@@@@@@@@@@@@@@@@@@@@@@@ start2');

    // eventManager.emit(EventTypes.CROON_START, this.id + 1);
    debugDisplay('CROON_STATE_NEXT1');
    eventManager.emit(EventTypes.CROON_STATE_NEXT, this.id, getCroonRoundModeToRoundNum(SetCroonMode()));
    eventManager.emit(EventTypes.CROON_MOVE, this.id);
  }

  private chkCountUpEnd(): void {
    eventManager.emit(EventTypes.CONFETTI_STOP);

    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    if (this.visible && this.id === setCurrentStage()) {
      if (
        this.inHole === 0 &&
        ((SetCroonMode() === CroonRoundMode.NM3 && setCurrentStage() === 2) ||
          (SetCroonMode() === CroonRoundMode.NM5 && setCurrentStage() === 4))
      ) {
        debugDisplay('SetCroonMode()', SetCroonMode(), 'setCurrentStage()', setCurrentStage());

        BgmControl.setIsFadeOut(false);
        eventManager.emit(EventTypes.CROON_EXTRA_OPEN);
      } else {
        debugDisplay('visible ', this.visible);
        this.endMessageDelay();
      }
    }
  }

  private endMessageDelay(): void {
    let messageDelay;
    if (AudioApi.isPlaying(ISongs.SE_BigWin_End)) {
      messageDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.SE_BigWin_End]!.duration);
    } else {
      messageDelay = Tween.createDelayAnimation(200);
    }
    messageDelay.addOnComplete(() => {
      eventManager.emit(EventTypes.CREATE_END_MESSAGE);
    });
    messageDelay.start();
  }

  private ballDropSound(stage: number): void {
    debugDisplay('☆☆ballDropSound stage ', stage);
    if (this.id != stage || !this.isActive) {
      return;
    }
    this.isActive = false;

    debugDisplay('ballDropSound id ', this.id, 'visible', this.visible, 'this.nextState', this.nextState);

    BgmControl.fadeOutVolume(BGM_FADE_OUT_TIME, BGM_FADE_OUT_VOLUME);

    let songs: ISongs;
    let croonWinAnim: string;
    const mode = SetCroonMode();
    if (!this.nextState) {
      songs = ISongs.SE_BallDrop1;
      croonWinAnim = 'win_blue';
      eventManager.emit(EventTypes.CROON_DROP);
    } else {
      if (CheckFinalStage(this.id + 1)) {
        songs = ISongs.SE_BallDrop3;
        croonWinAnim = 'win_gold';
      } else if (
        (mode === 'NM3' && this.id === 2 && this.inHole === 0) ||
        (mode === 'NM5' && this.id === 4 && this.inHole === 0)
      ) {
        songs = ISongs.SE_BallDrop3;
        croonWinAnim = 'win_gold';
      } else {
        songs = ISongs.SE_BallDrop2;
        croonWinAnim = 'win_orange';
        eventManager.emit(EventTypes.CROON_DROP);
        SetIsCroonStageMove(true);
      }
    }
    this.croonWinAnim = this.initCroonWin(croonWinAnim, this.inHole);

    this.croonWinAnim.addOnComplete(() => {
      // debugDisplay(
      //   '＝＝＝＝＝＝＝＝＝＝＝エフェクト終了',
      //   chkEndStage(SetCroonMode(), this.id),
      //   'this.id',
      //   this.id,
      //   'croonWinAnim',
      //   croonWinAnim,
      // );
      if (this.inHole === 0 && !chkEndStage(SetCroonMode(), this.id)) {
        eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
        if (!setReplayBet() || (setReplayBet() && setReplayFreeSpinBets().length != 0)) {
          eventManager.emit(EventTypes.CROON_STAGE_SPIN);
          setCurrentStage(setCurrentStage() + 1);
          debugDisplay('　ballDropSound　setCurrentStage後', setCurrentStage());
          eventManager.emit(EventTypes.CROON_STATE_NEXT, this.id, getCroonRoundModeToRoundNum(SetCroonMode()));
        }
      }
    });

    setTimeout(() => {
      this.addChild(this.croonWinAnim!.spine);
      this.croonWinAnim!.setAnimation(croonWinAnim, false);
    }, 1);

    AudioApi.play({ type: songs });
  }
}

export default CroonState;
