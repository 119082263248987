// eslint-disable-next-line
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode, MessageCroonBonusBannerProps, MessageWinBannerProps } from '../../global.d';
import { debugDisplay, isCroonBonusMode } from '../../utils';
import VAvatar from '../Avatar/vAvatar';
import Tween from '../animations/tween';
import bgmControl from '../bgmControl/bgmControl';
import ClearedMessage from '../clearedMessage/clearedMessage';
import ExtraChallengeMessage from '../clearedMessage/extraChallengeMessage';
import ViewContainer from '../components/container';
import Confetti from '../confetti/confetti';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LINES_NUMBER_CONTAINER_WIDTH,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  Z_INDEX_GAME_VIEW,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import { MessageCroonBonusBanner } from '../messageBanner/messageCroonBonusBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import MiniMapContainer from '../miniMap/miniMapContainer';
import ReelsFrame from '../reels/frame/reelsFrame';
import Warning from '../warning/warning';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import CroonTop from './croonTop';
import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public croonTop: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public miniMapContainer: PIXI.Container;

  public frame: ReelsFrame;

  private messageWinBanner: MessageWinBanner | undefined;

  private avatar: VAvatar;

  public warning: Warning;

  private confetti: Confetti;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.slotsContainer.zIndex = Z_INDEX_GAME_VIEW;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.croonTop = new CroonTop();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.miniMapContainer = new MiniMapContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(
        (SLOTS_CONTAINER_WIDTH - SLOTS_BACKGROUND_WIDTH) / 2,
        (SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT) / 2,
        SLOTS_BACKGROUND_WIDTH,
        SLOTS_BACKGROUND_HEIGHT,
      )
      .endFill();
    this.slotsContainer.addChild(this.slotsContainer.mask);
    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.slotsContainer);
    this.addChild(this.slotStopDisplayContainer);
    this.addChild(this.miniPayTableContainer);
    this.frame = new ReelsFrame();
    this.addChild(this.frame);
    this.avatar = new VAvatar();
    this.addChild(this.avatar);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.miniMapContainer);
    this.addChild(this.croonTop);

    this.warning = new Warning();
    this.addChild(this.warning);

    this.confetti = new Confetti();
    this.addChild(this.confetti);

    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage); // TODO

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));

    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));

    eventManager.addListener(EventTypes.CROON_STATE_NEXT, this.createClearedMessage.bind(this));
    eventManager.addListener(EventTypes.CROON_EXTRA_CHALLENGE, this.createExtraChallengeMessage.bind(this));
  }

  private createFreeSpinsMessage(props: MessageCroonBonusBannerProps): void {
    this.addChild(new MessageCroonBonusBanner(props).init());
    // TODO AudioApi.play({ type: ISongs.BB_Banner });
  }

  private createClearedMessage(stage: number, totalStage: number): void {
    debugDisplay('createClearedMessage');
    eventManager.emit(EventTypes.HANDLE_SKIP_CLEARED_MESSAGE);
    const clearedMessage = new ClearedMessage(stage, totalStage);
    this.addChild(clearedMessage);
  }

  private createExtraChallengeMessage(): void {
    debugDisplay('createExtraChallengeMessage');
    const clearedMessage = new ExtraChallengeMessage();
    this.addChild(clearedMessage);
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    debugDisplay('createWinMessage');
    const totalWinDelay = Tween.createDelayAnimation(
      // mappedAudioSprites[ISongs.TotalWinBanner]!.duration,
      1000,
    );

    totalWinDelay.start();
    bgmControl.StopBonusBgmAll();
    this.messageWinBanner = new MessageWinBanner({
      ...props,
      callback: () => {
        totalWinDelay.skip();
        if (props.callback) props.callback();
      },
    }).init();
    this.messageWinBanner.visible = false;
    this.addChild(this.messageWinBanner);
    this.messageWinBanner.updateTransform();
    this.messageWinBanner.visible = true;
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isCroonBonusMode(settings.mode)) {
      this.slotsContainer.visible = false;
      this.miniPayTableContainer.visible = false;
    } else {
      this.slotsContainer.visible = true;
      this.miniPayTableContainer.visible = true;
    }
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
