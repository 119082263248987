import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolABonus,
  [SlotId.B]: ResourceTypes.symbolBBlank,
};
export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolABonus,
  [SlotId.B]: ResourceTypes.symbolBBlank,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_a_bonus_announce',
  },
};

export type StopAnimationRen = {
  type: SymbolAnimationType;
  src?: string;
  animation?: string;
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'symbol_a_bonus_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.DEFAULT,
  },
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.totalwin,
  ResourceTypes.messagebanner,
  ResourceTypes.backgroundBase,
  ResourceTypes.bonusgameBase,
  ResourceTypes.bonusgameBase2,
  ResourceTypes.bonusgameBase3,
  ResourceTypes.bonusgameBase4,
  ResourceTypes.bonusgameBase5,
  ResourceTypes.bonusgameOrnament,
  ResourceTypes.introBg,
  ResourceTypes.intro01,
  ResourceTypes.intro02,
  ResourceTypes.buttonOk,
  ResourceTypes.backdrop,
  ResourceTypes.logo,
  ResourceTypes.symbolABonus,
  ResourceTypes.symbolBBlank,
  ResourceTypes.reelBase,
  ResourceTypes.reelBaseShadow,
  ResourceTypes.reelframeBase,
  ResourceTypes.titlelogo,
  ResourceTypes.cicus,
]!.map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = [
  {
    name: 'spine_atlas',
    url: '/animations/desktop/symbol/spine.atlas',
    loadType: LoaderResource.LOAD_TYPE.XHR,
    xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
  },
];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'croon',
      url: generateTexturePath('/animations', 'croon/croon.json'),
    },
    {
      name: 'symbol_all',
      url: generateTexturePath('/animations', 'symbol_all/symbol_all.json', isMobile),
    },
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
    {
      name: 'avatar_hinako',
      url: generateTexturePath('/animations', 'avatar_hinako/avatar_hinako.json'),
    },
    {
      name: 'croon_frame',
      url: generateTexturePath('/animations', 'croon_frame/croon_frame.json'),
    },
    {
      name: 'croon_win',
      url: generateTexturePath('/animations', 'croon_win/croon_win.json', isMobile),
    },
    {
      name: 'shutter',
      url: generateTexturePath('/animations', 'shutter/shutter.json', isMobile),
    },
    {
      name: 'warninng',
      url: generateTexturePath('/animations', 'warninng/warninng.json', isMobile),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'magnification',
    url: '/images/croon/magnification.json',
  },
  {
    name: 'map',
    url: '/images/map/map.json',
  },
  {
    name: 'buttonsSprite',
    url: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    url: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    url: '/images/loader/loader.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
